import React,{useEffect,useState,useRef} from 'react'
import { useDispatch, useSelector} from "react-redux";
import { useParams ,Link} from "react-router-dom";
import moment from 'moment';
import { Drawer,Form,Button,Row, Col,Divider,Typography ,DatePicker,Select,Input } from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
import useAxios from './../../CustomHooks/useAxios';
import ShowAuthorizationGroupTable from './ShowAuthorizationGroupTable';
import PageLink from './../PageLink/PageLink';

import AddAuthorizationGroup from "./AddAuthorizationGroup";
const { Text } = Typography;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

export default function AuthorizationGroup(){
    let { page } = useParams();
    const {axiosi}=useAxios();

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [data,setData]=useState();
    const [refreshSearch,setRefreshSearch]=useState(true);
    const [name,setName]=useState('');
    // const [name,setName]=useState("");

    const handleAdd=()=>{
        console.log("[handleAdd]:");
        setVisibleAdd(true);
    }
    const onCloseAdd=()=>{
        setVisibleAdd(false);
        
    }

    useEffect(()=>{
        doSearch();
    },[refreshSearch,page])
    
        const handleSearch=(value)=>{
            setName(value);
            setRefreshSearch(pre=>!pre);
        }

    const doSearch= async()=>{
        console.log("[search]");
        const kq=await axiosi.get('authorization_groups',{params:{name,pageSize:20}});
        console.log("[handleSearch]:kq.data=",kq.data);
        setData(kq.data);
    }
    return (<>
            <Drawer title="Add  Menu Item" placement="right" onClose={onCloseAdd} visible={visibleAdd}>             
                    <AddAuthorizationGroup setVisibleAdd={setVisibleAdd} setRefreshSearch={setRefreshSearch}  />        
            </Drawer>
        <section className='search'>
        <div className="container">
            <div className="row">
                <Typography.Title  level={3}>Search :
                <Button onClick={handleAdd}  className="addButton" icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button>
                </Typography.Title>
                
            </div>
            
            <div className="row">
            <Input.Group compact>      
                <Input.Search addonBefore="Sub-Menu Title: " enterButton 
                 onSearch={handleSearch} allowClear 
                 style={{ width: '70%' }} defaultValue="" />
            </Input.Group>
                {/* <div className="col">
                    <label>menu item name :</label>
                    <Input  onChange={({ target: { value } } )=>setName(value)}/>
                </div>
                <div className="col">
                    <label>Search:</label>
                    <Button onClick={handleSearch}>Search :</Button>
                </div> */}
                
            </div>
        </div>

    </section>
    <section className='searchResult'>
        <div className="row">
        <Typography.Title  level={3}>Result</Typography.Title>
        </div>
        <div className="row">
            {data?.rows && <ShowAuthorizationGroupTable data={data.rows} setRefreshSearch={setRefreshSearch} />}
        </div>
        <Row>
              <Col span={24}>
                {data?.pages>1?<PageLink pageSize={data.pageSize} searchpage={"authorization_group"} pages={data.pages} numrows={data.numrows} page={data.page} />:<h1>chi co' 1 page</h1>}
              </Col>
            </Row>

    </section>
    </>)
}