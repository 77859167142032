import {useState,useEffect} from 'react';
import {Button,Drawer,Input,Space,Form} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
import EditMenuSubItem from "./EditMenuSubItem";
import AddMenuSubItem from "./AddMenuSubItem";
import DeleteMenuSubItem from "./DeleteMenuSubItem";
export default function ShowMenuSubTable({data,setRefreshSearch}){
    const [visibleAdd, setVisibleAdd] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleRemove, setVisibleRemove] = useState(false);
    const [selectedItem,setSelectedItem]=useState(null);
    const [selectedDeleteItem,setSelectedDeleteItem]=useState(null);
    useEffect(()=>{
        console.log("[useEffect]:selectedItem=",selectedItem);
    },[])
    const handleAdd=()=>{
        console.log("[handleAdd]:");
        setVisibleAdd(true);
    }
    const handleEdit=(item)=>{
        console.log("[handleEdit]:item=",item);
        setSelectedItem(item);
        setVisibleEdit(true);
    }
    const handleRemove=(item)=>{
        console.log("[handleRemove]:item=",item);
        setVisibleRemove(true);
        setSelectedDeleteItem(item);
    }

    const onCloseAdd=()=>{
        setVisibleAdd(false);
        
    }
    const onCloseEdit=()=>{
        console.log("[onCloseEdit]")
        setSelectedItem(null);
        setVisibleEdit(false);
    }
    const onCloseRemove=()=>{
        setVisibleRemove(false);
        setSelectedDeleteItem(null);
    }
    const onSaveAdd=()=>{
        console.log("[onSaveAdd]:begin");
        setVisibleAdd(false)
        console.log("[onSaveAdd]:end");
    }

    // const onFinishEdit=(values)=>{
    //     console.log("[onFinishEdit]:values=",values)
    // }
    // const onFinishFailedEdit=(error)=>{
    //     console.log("[onFinishFailedEdit]:error=",error)
    // }
    return (<>
    {/* <h1>ShowMenuSubTable</h1> */}
    <Drawer title="Add Sub Menu" placement="right" onClose={onCloseAdd} visible={visibleAdd}
        
    >
        <AddMenuSubItem setRefreshSearch={setRefreshSearch} setVisibleAdd={setVisibleAdd}/>
        
      </Drawer>

      <Drawer title="Edit Sub Menu" placement="right" onClose={onCloseEdit} visible={visibleEdit}>
      {selectedItem && <EditMenuSubItem selectedItem={selectedItem} 
      setVisibleEdit={setVisibleEdit}
      setSelectedItem={setSelectedItem}
      setRefreshSearch={setRefreshSearch}/>}
        
      </Drawer>
      <Drawer title="Remove Sub Menu" placement="right" onClose={onCloseRemove} visible={visibleRemove}>
        {selectedDeleteItem && <DeleteMenuSubItem item={selectedDeleteItem} 
        setVisibleRemove={setVisibleRemove}
        setRefreshSearch={setRefreshSearch}/>}
        
      </Drawer>
    <table className='table1'>
        <thead>
        <tr>
            {/* <th>id</th> */}
            {/* <th>calldirection</th>
            <th>connected</th> */}
            <th>menukey</th>
            <th>icon</th>
            <th width="220px">title</th>
            
            <th width="220px">Action</th>
            

        </tr>
        </thead>
        <tbody>
    {data.map(item=>(
        <tr key={item.id}>
            {/* <td>{item.id}</td> */}
            {/* <td>{item.calldirection}</td>
            <td>{item.connected}</td> */}
            <td>{item.menukey}</td>
            
            <td>{item.icon}</td>
            <td>{item.title}</td>
            
            <td> 
                <div className="rowFlex">

                
               <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button>
               <Button onClick={()=>handleEdit(item)} icon={<EditFilled  style={{ color: 'hotpink' }}  />}>Edit</Button>
               <Button onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Remove</Button>
               {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
          Download
        </Button> */}
        </div>
            </td>
            
        </tr>
    ))}
    </tbody>
    </table>
    </>)
} 