import React,{useEffect,useState,useRef} from 'react'
import { useDispatch, useSelector} from "react-redux";
import { useParams ,Link} from "react-router-dom";
import moment from 'moment';
import { Button,Row, Col,Divider,Typography ,DatePicker,Select,Input } from 'antd';
import useAxios from './../../CustomHooks/useAxios';
import ShowMenuSubTable from './ShowMenuSubTable';
import PageLink from './../PageLink/PageLink';
const { Text } = Typography;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
export default function MenuSub(){
    let { page } = useParams();
  const {axiosi}=useAxios();
  const [data,setData]=useState()
  const [sTitle,setSTitle]=useState('');
  const [refreshSearch,setRefreshSearch]=useState(true);
  
  useEffect(()=>{
    doSearch();
},[refreshSearch,page])

    const handleSearch=(value)=>{
        setSTitle(value);
        setRefreshSearch(pre=>!pre);
    }
    const doSearch=async ()=>{
            console.log("[handleSearch]: Begin");
            console.log(sTitle);
            const kq=await axiosi.get("menu_sub",{params:{sTitle,page,pageSize:20}});
            const kqdata= await kq.data;
            console.log("[MenuSub-handleSearch]:",kqdata);
            if (kq.status!==204) {
                setData(kq.data)
                console.log("[getExportCallLogs]:",kqdata);
                } else {
                  console.log("[No Content] kq.data=",kq.data)
                }
            console.log("[handleSearch]: End");
    }
    const onChange=()=>{

    }
    const onSearch = value => console.log(value);
    return (<>
    <div className="search">
    

    {/* <Input.Group compact>
      <Input style={{ width: 'calc(100% - 200px)' }} addonBefore="Title: " defaultValue="https://ant.design" />
      <Button type="primary">Search</Button>
    </Input.Group>

        <Input addonBefore="Title: " addonAfter={<Button>Search </Button>} placeholder="Sub-Menu title" allowClear onChange={onChange} /> */}
        <div className="title">
            <Typography.Title level={3}>Search :</Typography.Title>
        </div>
        <div className="filter">
            {/* <div className="filterTitle"><lable>Title: </lable></div>
            <div><Input ref={sTitle}/></div>
            <div><Button onClick={handleSearch}>Search</Button></div> */}
            <Input.Group compact>      
                <Input.Search addonBefore="Sub-Menu Title: " enterButton 
                 onSearch={handleSearch} allowClear 
                 style={{ width: '70%' }} defaultValue="" />
            </Input.Group>
        </div>

    </div>
    <div className="searchResult">
        <div className="title">
            <Typography.Title level={3}>Result :</Typography.Title>
        </div>
        <div className="data">
        <Row>
              <Col span={24}>
                {data?<ShowMenuSubTable setRefreshSearch={setRefreshSearch} data={data.rows}/>:""}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {data?.pages>1?<PageLink pageSize={data.pageSize} searchpage={"menu_sub"} pages={data.pages} numrows={data.numrows} page={data.page} />:<h1>chi co' 1 page</h1>}
              </Col>
            </Row>
        </div>
    </div>
    
    </>)
}