import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Button, Input, Form, Select, DatePicker } from "antd";

import moment from "moment";

import useAxios from "./../../../CustomHooks/useAxios";
import DebounceSelect from "./../../DebounceSelect";
const { Option } = Select;

export default function Add({
  setRefressSearch,
  setVisibleAdd,
  ctel,
  uniqueid,
  tkid,
}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const [exten, setExten] = useState([]);
  const { axiosi } = useAxios();
  const user = useSelector((state) => state.loginReducer.user);
  const [companyid, setCompanyid] = React.useState({
    value: user?.agents[0].company_id,
    label: user?.agents[0].gmcomname,
  });
  const [dobStr, setDobStr] = useState();
  function onChangeDoB(date, dateString) {
    console.log("[onChangeDoB]:", date, dateString);
    setDobStr(dateString);
  }

  async function fetchExtenList(exten) {
    console.log("[fetchExtenList]:exten=", exten);
    const body = await axiosi.get("agents/getMyAgents", {
      params: {
        company_id: companyid.value,
        exten,
        pageSize: 100,
      },
    });
    console.log("[fetchExtenList]:body=", body);
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.label,
        value: user.value,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
  }
  const onFinishAdd = async (values) => {
    let adddata = {
      ...values,
      dob: dobStr,
      sourcedata: "addButton",
      assign_agentcode: exten.value,
      assign_agentname: exten.label,
    };
    console.log("[onFinishAdd]:values=", adddata);
    try {
      const kq = await axiosi.post("outbounddata", adddata);
      console.log("[onFinishAdd]:kq=", kq);
      setVisibleAdd(false);
      setRefressSearch((pre) => !pre);
      if (uniqueid) {
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error.response.data:", error.response.data);
        console.log("error.response.status:", error.response.status);
        console.log("error.response.headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("error.request :", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error error.message", error.message);
      }
      console.log("error.config:", error.config);
    }
    return;
  };
  const onFinishFailedAdd = (error) => {
    console.log("[onFinishFailedAdd]:error=", error);
  };

  const handleOnChangeIto = async (value) => {
    console.log("[handleOnChangeIto]:value=", value);
    setExten(value);
  };
  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        initialValues={{
          priority_level: 3,
          tel: ctel,
          fromtime: moment("00:00:00", "HH:mm:ss"),
          totime: moment("23:59:59", "HH:mm:ss"),
        }}
        onFinish={onFinishAdd}
        onFinishFailed={onFinishFailedAdd}
        autoComplete="off"
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input a Name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Tel"
              name="tel"
              rules={[{ required: true, message: "Please input a tel!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Company"
              name="ccomname"
              rules={[{ required: false, message: "Please input a Name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="business_sectors"
              name="business_sectors"
              rules={[{ required: false, message: "Please input a Name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Birthday"
              name="dob"
              rules={[{ required: false, message: "Please input a tel!" }]}
            >
              <DatePicker style={{ width: "100%" }} onChange={onChangeDoB} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: false, message: "Please input a tel!" }]}
            >
              <Select>
                <Option value=""></Option>
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
                <Option value="others">Others</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: false, message: "Please input a Email!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Assign to agent :"
              // name="ito"
              rules={[{ required: false, message: "Please input an ito!" }]}
            >
              <DebounceSelect
                // selectMode="multiple"
                // mode="tags"
                showSearch={true}
                value={exten}
                placeholder="Select users"
                fetchOptions={fetchExtenList}
                onChange={handleOnChangeIto}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label="note"
              name="note"
              rules={[
                { required: false, message: "Please input a priority_level!" },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>{" "}
          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
