import { PageHeader, Tabs, Button, Statistic, Descriptions } from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
import useAxios from './../../CustomHooks/useAxios';


export default function DeleteAgents({item,setRefreshSearch,setVisibleRemove}) {
  const {axiosi}=useAxios();
  const handleDelete= async()=>{
      console.log("[handleDelete]:item=",item)
      try {
          const kq=await axiosi.delete(`agents/${item.id}`);
          console.log("[handleDelete]:kq=",kq) ;
          setVisibleRemove(false);
            setRefreshSearch(pre=>!pre);
      } catch (error){
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
      }
  }
  return(<>
      
      <Descriptions size="small" column={1} bordered>
          <Descriptions.Item label="company_name">{item.company_name}</Descriptions.Item>
          <Descriptions.Item label="Agent Name">{item.name}</Descriptions.Item>
          <Descriptions.Item label="Agent Username">{item.username}</Descriptions.Item>
          <Descriptions.Item label="Exten Id">{item.endpoint_id}</Descriptions.Item>
          
          
      </Descriptions>
      <div style={{marginTop:"0.5rem"}}>
  <label style={{marginRight:"0.2rem"}}>Are you really want to delete this ?</label>
  <Button onClick={handleDelete} ><DeleteFilled style={{ color: 'red' }} />Delete</Button>
      </div>
  </>)
}