import React from 'react'
import { useSelector} from "react-redux";
import {Button,Drawer,Input,Space,Form,Select,Spin,message} from 'antd';
import useAxios from './CustomHooks/useAxios';
import { EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';
const { Option } = Select;

export default function Profile({setVisible}) {
    const user = useSelector((state) => state.loginReducer.user);
    console.log('[Profile]:user=',user?.agents[0]);
    const {axiosi}=useAxios();




    
    function isStrongPwd1(password) {
      var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/;
      var validPassword = regExp.test(password);
      return validPassword;
  }
    const onFinishAdd=async (values)=>{
    
    try {
        if (values.password) {
          if (!isStrongPwd1(values.password)) {
            message.info("The Agent password must have atleast 8 chars with one uppercase letter, one lower case letter, one digit and one of !@#$%*()");
            return;
          }
        }
        const kq=await axiosi.put(`agents/${user?.agents[0].id}/updateprofile`,{...values,company_id:user?.agents[0].company_id
         });
        console.log("[onFinishAdd]:kq=",kq) ;
        setVisible(false);
        message.info("Please logout and login again to apply the new change !")
        // setSelectedItem(null);
        // setRefreshSearch(pre=>!pre);
    } catch (error){
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            message.error(`Error: ${error.response.data.code}`);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
    }
}
const onFinishFailedAdd=(error)=>{
    console.log("[onFinishFailedAdd]:error=",error);
}

  return (
    <>




<Form
      name="basic"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      initialValues={{...user?.agents[0]}}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
        
      
      
    
        <Form.Item
        label="Agent Name :"
        name="name"
        rules={[{ required: true, message: 'Please input an Agent name!' }]}
        >
            <Input  />
        
        </Form.Item>


      <Form.Item
        label="Agent Username :"
        name="username"
        rules={[{ required: true, message: 'Please input an Agent password!' }]}
      >
        <Input/>
      </Form.Item>

      <Form.Item
        label="Agent Password :"
        name="password"
        rules={[{ required: false, message: 'Please input an Agent password!' }]}
      >
            <Input.Password placeholder="input Agent password"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />       
      </Form.Item>

      
    


        <Form.Item
        label="Agent email :"
        name="email"
        // rules={[{ required: true, message: 'Please input an email!' }]}
      >
        <Input  />       
      </Form.Item>

      <Form.Item
        label="Agent tel :"
        name="tel"
        // rules={[{ required: true, message: 'Please input an email!' }]}
      >
        <Input  />       
      </Form.Item>
      

     
      
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
    </>
  )
}
