import React,{useState,useEffect} from 'react';
import { useSelector} from "react-redux";
import {Button,Drawer,Input,Space,Form,Select,Spin,TimePicker} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import moment from 'moment';
import debounce from 'lodash/debounce';
import useAxios from './../../../CustomHooks/useAxios';
import DebounceSelect from './../../DebounceSelect'
const { Option } = Select;
export default function Edit({item,setSelectedItem,setRefressSearch,setVisibleEdit}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const {axiosi}=useAxios();
  const [file, setFile] = useState();
  const [iaction,setIaction]=useState('');
    const [companyid, setCompanyid] = React.useState({value:item.company_id,label:item.company_name});
    const [exten,setExten]=useState([]);
    
    const [forwardTo,setForwardTo]=useState('');

    const [telsoutData,setTelsoutData]=useState(null);
    const [pbxtel,setPbxtel]=useState(item.pbxtel);
    const [telsoutDataLoading,setTelsoutDataLoading]=useState(false);

    const [telout,setTelout]=useState(item.pbxtel);
    const [mainDialPlanData,setMainDialPlanData]=useState(null);
    const [mainDialPlainSelectedid,setMainDialPlainSelectedid]=useState(item.maindialplanid);
    
    const [fromtime,setFromtime]=useState(item.fromtime);
    const [totime,setTotime]=useState(item.totime);
    const [wdayofweekSelected,setWdayofweekSelected]=useState(item.wdayofweek+"");
    useEffect(()=>{
      const getMainDialplan=async()=>{
        try {
          if (companyid){
        const kq=await axiosi.get('ivrplanmain',{params:{company_id:companyid.value,pageSize:100}});
        console.log("[useEffect-getMainDialplan]:kq=",kq)
        setMainDialPlanData(kq.data.rows);
        setMainDialPlainSelectedid(item.maindialplanid)
          } else {
            setMainDialPlanData(null);
          }
        }
        catch (err){
          console.log("[useEffect-getMainDialplan]:err=",err);
        }

      }
      getMainDialplan();
    },[companyid])
   async function fetchUserList(company_name) {        
        console.log('fetching user', company_name);
        const body=await axiosi.get('companies',{params:{company_name,pageSize:100}} );
        if (body?.data?.rows) {
            return body?.data?.rows.map((user) => ({
                label: user.company_name,
                 value: user.id,
                //  value:{id: user.id,company_code:user.company_code},
                 company_code:user.company_code,
               }));
        }
        else {
            const a=[{label:"---no data---",value:""}]
            return a.map(i=> (i))
        }
       
    }

    
    
    const onFinishAdd=async (values)=>{
        console.log("[onFinishAdd]:values=",values);
        console.log("[onFinishAdd]:companyid=",companyid);
        console.log("[onFinishAdd]:telsoutData=",telsoutData);
        console.log("[onFinishAdd]:telout=",telout);
        const teloutSelected=telsoutData?.rows?.find((item)=>item.id===telout)
        console.log("[onFinishAdd]:teloutSelected=",teloutSelected);
        console.log("[onFinishAdd]:file=",file);
        // const newAgent={...values,
        //   // name:values.name,
        //   // username,
        //   password,
        //   authorization_group_id,
        //   endpoint_id,
        //   company_id,
        //   telout_num,
        //   telout_peer,
        //   max_contacts,
        //   ep_username,
        //   ep_password,
        //   note,
        //   tel,
        //   email,
        //   webrtc,
        // }
        // return;
        
       
        let adddata={...values,company_id:companyid.value,pbxtel:telout,wdayofweek:wdayofweekSelected ,fromtime,totime ,maindialplanid :mainDialPlainSelectedid  };
        
        console.log("[onFinishAdd]:adddata=",adddata);

        //  return;
        try {
            const kq=await axiosi.put(`ivrplanforweekday/${item.id}`,adddata);
            console.log("[onFinishAdd]:kq=",kq) 
            setSelectedItem(null);
            setVisibleEdit(false);
            setRefressSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error.response.data:",error.response.data);
                console.log("error.response.status:",error.response.status);
                console.log("error.response.headers:",error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request :",error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error error.message', error.message);
              }
              console.log("error.config:",error.config);
        }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }

    
    const handleOnChangeCompanyName=async(value)=>{
          setCompanyid(value);
          if(value){
          const kq= await axiosi.get('tels',{params:{companyid:value.value,pageSize:100}});
          console.log("[handleOnChangeCompanyName]:kq_get_tels=",kq);
          console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
          setTelsoutData(kq.data);
          setTelout("");
          } else {
            setTelsoutData(null);
            setTelout("");
          }
    }
    
    useEffect(()=>{
      const getTelout=async()=>{
        const kq= await axiosi.get('tels',{params:{companyid:item.company_id,pageSize:100}});
        console.log("[handleOnChangeCompanyName]:kq_get_tels=",kq);
        // console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
        setTelsoutData(kq.data);
        setTelout(item?.pbxtel);
      }
      getTelout();
    },[item?.company_id])

    const handleOnChangeTelsout=async(value)=>{
      setTelout(value);
      console.log("[handleOnChangeTelsout]:value=",value);
      console.log("[handleOnChangeTelsout]:telsoutData=",telsoutData);
    }

    const handleOnChangeMainDialPlan=(value)=>{
      setMainDialPlainSelectedid(value);
      console.log("[handleOnChangeMainDialPlan]:value=",value)
    }
    

    

    const HandleOnChangeFromTime=(time, timeString)=>{
      console.log("[HandleOnChangeFromTime]:value=",time, timeString)
      setFromtime(timeString);
    }
    const HandleOnChangeToTime=(time, timeString)=>{
      console.log("[HandleOnChangeToTime]:value=",time, timeString)
      setTotime(timeString);
    }
    function onSearch(val) {
      console.log('search:', val);
    }
    function handleOnChangeWDayofWeek(val){
      console.log("[handleOnChangeWDayofWeek]:val=",val)
      setWdayofweekSelected(val);

    }
    // if (1)return (<h1>hello</h1>)
    
  return (<>
   

  {/* {console.log("[return]:ivrVisible=",ivrVisible)} */}
  {/* { fromtime: moment(item.fromtime, 'HH:mm:ss'),
        totime:moment(item.totime, 'HH:mm:ss')} */}
        <Form
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      initialValues={{ordnum:item.ordnum,fromtime: moment(item.fromtime, 'HH:mm:ss'),
      totime:moment(item.totime, 'HH:mm:ss')} }
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
      
      
      <Form.Item className="hideItem"
        label="ordnum :"
        name="ordnum"
        rules={[{ required: true, message: 'Please input an ordnum!' }]}
      >
        <Input  />        
      </Form.Item>

        <Form.Item className={hideItem}
        label="Company Name"
        // name="company_name"
        rules={[{ required: true, message: 'Please input a company_name!' }]}
      >
        <DebounceSelect
          showSearch={true}
          value={companyid}
          placeholder="Select users"
          fetchOptions={fetchUserList}
          onChange={handleOnChangeCompanyName}          
          style={{  width: '100%', }}
        />
      </Form.Item>

      <Form.Item
        label="pbxtel :"
        // name="pbxtel"
        rules={[{ required: false, message: 'Please input a Main IVR Name!' }]}
      >
        {telsoutData?.rows && <>
            <Select style={{ width: '100%' }} 
                    onChange={handleOnChangeTelsout}
                    //(value)=>setMenu_item_id(value)}
                    // loading={menuItemDataLoading}
                    value={telout}
                    
            >
                        <Option value=""></Option>
                        { telsoutData?.rows && telsoutData.rows.map(item=>(
                           <Option key={item.telin} value={item.telin}>{item.telin}</Option>
                       ))}                       
            </Select>
        
      </>}
      </Form.Item>

      <Form.Item
        label="wdayofweek :"
        //name="wdayofweek"
        rules={[{ required: true, message: 'Please input a wdayofweek!' }]}
      >
          <Select style={{ width: '100%' }}  showSearch
                    onChange={handleOnChangeWDayofWeek}                    
                    value={wdayofweekSelected}
                    
                    optionFilterProp="children"
                    placeholder="Select a weekday of week !"
                    filterOption={(input, option) =>
                    option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }                    
            >
              <Option value=""></Option>
              <Option value="all">All days of week</Option>              
              <Option value="2">Monday</Option>
              <Option value="3">Tuesday</Option>
              <Option value="4">Wendnesday</Option>
              <Option value="5">Thursday</Option>
              <Option value="6">Friday</Option>
              <Option value="7">Saturday</Option>
              <Option value="1">Sunday</Option>
            </Select> 
            
      </Form.Item>

      <Form.Item
        label="fromtime :"
        name="fromtime"
        rules={[{ required: true, message: 'Please input a fromtime!' }]}
      >
        <TimePicker onChange={HandleOnChangeFromTime} />
      </Form.Item>
      <Form.Item
        label="totime :"
        name="totime"
        rules={[{ required: true, message: 'Please input a totime!' }]}
      >
        <TimePicker onChange={HandleOnChangeToTime}  />
      </Form.Item>
      <Form.Item
      
        label="maindialplanid :"
        // name="maindialplanid"
        rules={[{ required: true, message: 'Please input a maindialplanid!' }]}
      >
        {mainDialPlanData && <>
            <Select style={{ width: '100%' }}  showSearch
                    onChange={handleOnChangeMainDialPlan}
                    //(value)=>setMenu_item_id(value)}
                    // loading={menuItemDataLoading}
                    value={mainDialPlainSelectedid}
                    optionFilterProp="children"
                    placeholder="Select a person"
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    
            >
                        <Option value=""></Option>
                        { mainDialPlanData && mainDialPlanData.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
            </Select>
        
      </>}     
      
      </Form.Item>
      
        

      

     
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
  </>);
}
