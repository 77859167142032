import {useState,useEffect} from 'react';
import {Button,Drawer,Input,Space,Form,Select,message} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import useAxios from './../../CustomHooks/useAxios';
const { Option } = Select;
export default function AddAuthorizationGroup({setVisibleAdd,setRefreshSearch}){
    
    const {axiosi}=useAxios();
    const [menu_sub_id,setMenu_sub_id]=useState("");
    const onFinishAdd=async (values)=>{
        console.log("[onFinishAdd]:values=",values);
        try {
            const kq=await axiosi.post("authorization_groups",values);
            console.log("[onFinishAdd]:kq=",kq) ;
            setVisibleAdd(false);
            setRefreshSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                message.error(`Error: ${error.response.data.code}`);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
        }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }
    return(<>
        
        <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    //   initialValues={{icon:"LaptopOutlined" }}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
        <Form.Item
        label="name"
        name="name"
        rules={[{ required: true, message: 'Please input a item_key!' }]}
      >
        <Input  />
      </Form.Item>
      

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
    </>)
}