import {useState,useEffect} from 'react';
import {Button,Drawer,Input,Space,Form} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
import EditAuthorizationGroup from "./EditAuthorizationGroup";


import DeleteAuthorizationGroup from "./DeleteAuthorizationGroup";
export default function ShowAuthorizationGroupTable({data,setRefreshSearch}){
    
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleRemove, setVisibleRemove] = useState(false);
    
    const [selectedItem,setSelectedItem]=useState(null);
    const [selectedDeleteItem,setSelectedDeleteItem]=useState(null);

    console.log("[ShowMenuItemTable]:data=",data)
    const handleEdit=(item)=>{
        console.log("[handleEdit]:item=",item);
        setSelectedItem(item);
        setVisibleEdit(true);
    }
    const handleRemove=(item)=>{
        console.log("[handleRemove]:item=",item);
        setVisibleRemove(true);
        setSelectedDeleteItem(item);
    }

    /////////
    
    const onCloseEdit=()=>{
        console.log("[onCloseEdit]")
        setSelectedItem(null);
        setVisibleEdit(false);
    }
    const onCloseRemove=()=>{
        setVisibleRemove(false);
        setSelectedDeleteItem(null);
    }
    // const onSaveAdd=()=>{
    //     console.log("[onSaveAdd]:begin");
    //     setVisibleAdd(false)
    //     console.log("[onSaveAdd]:end");
    // }

    return (
        <>
            {/* <h1>Hello ShowMenuItemTable</h1> */}
            

            <Drawer title="Edit  Menu Item" placement="right" onClose={onCloseEdit} visible={visibleEdit}>
                {selectedItem && <EditAuthorizationGroup item={selectedItem} 
                setVisibleEdit={setVisibleEdit}
                setSelectedItem={setSelectedItem}
                setRefreshSearch={setRefreshSearch}
                 />}        
            </Drawer>

            <Drawer title="Remove  Menu Item" placement="right" onClose={onCloseRemove} visible={visibleRemove}>
                {selectedDeleteItem && <DeleteAuthorizationGroup item={selectedDeleteItem} 
                        setVisibleRemove={setVisibleRemove}
                        setRefreshSearch={setRefreshSearch}
                        />}        
            </Drawer>

            <table className='table1'>
        <thead>
        <tr>
            {/* <th>id</th> */}
            {/* <th>calldirection</th>
            <th>connected</th> */}
            
            <th width="220px">name</th>
            
            
            <th width="120px">Action</th>
            

        </tr>
        </thead>
        <tbody>
    {data.map(item=>(
        <tr key={item.id}>
            {/* <td>{item.id}</td> */}
            {/* <td>{item.calldirection}</td>
            <td>{item.connected}</td> */}
            
            
            <td>{item.name}</td>
            
            
            <td> 
               {/* <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
               <div className='rowFlex'>
                   <Button onClick={()=>handleEdit(item)} icon={<EditFilled  style={{ color: 'hotpink' }}  />}>Edit</Button>
                    <Button onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Remove</Button>
               </div>
               {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
          Download
        </Button> */}
            </td>
            
        </tr>
    ))}
    </tbody>
    </table>
        </>
    )
}