import React,{useState} from 'react';
import { useSelector} from "react-redux";
import {Button,Drawer,Switch } from 'antd';
import {EditFilled ,DeleteFilled} from '@ant-design/icons';
import useAxios from './../../../CustomHooks/useAxios';


import Edit from "./Edit";
import Delete from "./Delete";


export default function ShowTable({data,setRefressSearch}) {


  const {axiosi}=useAxios();

  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  
  const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleRemove, setVisibleRemove] = useState(false);
    
    const [selectedItem,setSelectedItem]=useState(null);
    const [selectedDeleteItem,setSelectedDeleteItem]=useState(null);

    const handleEdit=(item)=>{
      console.log("[handleEdit]:item=",item);
      setSelectedItem(item);
      setVisibleEdit(true);
  }
  const handleRemove=(item)=>{
      console.log("[handleRemove]:item=",item);
      setVisibleRemove(true);
      setSelectedDeleteItem(item);
  }

  const onCloseEdit=()=>{
    console.log("[onCloseEdit]")
    setSelectedItem(null);
    setVisibleEdit(false);
}
  const onCloseRemove=()=>{
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
}

async function onChange (checked,item) {
  console.log(`switch to ${checked}`,item);
  
  try {
    const kq=await axiosi.put(`surveyplan/${item.id}/running`,{runplan:checked,numcurrentcall :item.numcurrentcall });
    console.log("[onFinishAdd]:kq=",kq) ;
    // form.resetFields();
    // setSelectedItem(null);
    // setVisibleEdit(false);
    setRefressSearch(pre=>!pre);
} catch (error){
  console.log("eror");
}
}
return (
  <>
  
      <Drawer title="Edit  Menu Item" placement="right" onClose={onCloseEdit} visible={visibleEdit}>
          {selectedItem && <Edit item={selectedItem} setSelectedItem={setSelectedItem} setRefressSearch={setRefressSearch} setVisibleEdit={setVisibleEdit} />}        
      </Drawer>

      <Drawer title="Remove  Menu Item" placement="right" onClose={onCloseRemove} visible={visibleRemove}>
          {selectedDeleteItem && <Delete item={selectedDeleteItem} setRefressSearch={setRefressSearch} setVisibleRemove={setVisibleRemove}/>}        
      </Drawer>

      <table className='table1'>
  <thead>
  <tr>            
      <th width="220px" className={hideItem}>company_name</th>
      <th>Name</th>
      <th>Tel</th>
      <th>MainDialPlan</th>
      <th>manualsurvey</th>
      <th>Running</th>
      <th width="120px">Action</th>    
  </tr>
  </thead>
  <tbody>
{data.map(item=>(
  <tr key={item.id}>
      
      <td className={hideItem}>{item.company_name}</td>
      <td>{item.name}</td>
      <td>{item.telout}</td>
      <td>{item.ivrplanmain_name}</td>
      <td>{(item.manualsurvey===1)?"Yes":"No"}</td>
      <td><Switch  size="small" checkedChildren="Running" unCheckedChildren="Stopped" checked={item.runplan} onChange={(checked)=>onChange(checked,item)} /></td>
      <td>          
         <div className='rowFlex'>
            <Button onClick={()=>handleEdit(item)} icon={<EditFilled  style={{ color: 'hotpink' }}  />}>Edit</Button>
            <Button onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Remove</Button>              
         </div>         
      </td>      
  </tr>
))}
</tbody>
</table>

        
  </>
)
}