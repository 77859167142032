import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  DatePicker,
  Button,
  Drawer,
  Input,
  Space,
  Form,
  Select,
  Spin,
  Checkbox,
} from "antd";
import {
  DownloadOutlined,
  PlusCircleFilled,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";

import moment from "moment";
import debounce from "lodash/debounce";
import useAxios from "./../../../CustomHooks/useAxios";
import DebounceSelect from "./../../DebounceSelect";
const { Option } = Select;
export default function Edit({
  item,
  setSelectedItem,
  setRefressSearch,
  setVisibleEdit,
  uniqueid,
  tkid,
}) {
  const [znsTemplateList, setZnsTemplateList] = useState({});
  const [znsTemplateListSelected, setZnsTemplateListSelected] = useState(
    parseInt(item.template_id)
  );

  const { axiosi } = useAxios();

  const user = useSelector((state) => state.loginReducer.user);
  const [companyid, setCompanyid] = React.useState({
    value: user.agents[0].company_id,
    label: user.agents[0].gmcomname,
  });
  const [departmentData, setDepartmentData] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState(
    item.department_id
  );
  useEffect(() => {
    const getDepartmentData = async () => {
      try {
        const kq = await axiosi.get("department", {
          params: { company_id: companyid?.value, pageSize: 100 },
        });
        console.log("[getDepartmentData]:kq.data.rows=", kq.data.rows);
        setDepartmentData(kq.data.rows);
        // setDepartmentSelected(setSelectedItem.department_id);

        // console.log("[getDepartmentData]:kq.data.rows.length=",kq.data.rows.length);
        // console.log("[getDepartmentData]:user.agents[0].logdepartments.length=",user.agents[0].logdepartments.length);
      } catch (err) {
        setDepartmentData(null);
        console.log("[useEffect]:getDepartmentData has an Error !");
      }
    };
    getDepartmentData();
  }, [companyid?.value]);
  function checkLogDep(item) {
    if (user.agents[0].company_id === 1) return true;
    const logdeps = user.agents[0].logdepartments;
    // console.log("[checkLogDep]:logdeps=",logdeps);
    // console.log("[checkLogDep]:item.id=",item.id);
    // console.log("[checkLogDep]:logdeps.includes(item.id)=",logdeps.includes(item.id));
    return logdeps.includes(item.id);
  }
  const handleOnChangeDepartment = (value) => {
    console.log("[handleOnChangeDepartment]:value=", value);
    setDepartmentSelected(value);
  };
  const onFinishAdd = async (values) => {
    let adddata = {
      ...values,
      department_id: departmentSelected,
      template_id: znsTemplateListSelected,
    };
    console.log("[onFinishAdd]:values=", adddata);
    try {
      const kq = await axiosi.put(`znsplan/${item.id}`, adddata);
      console.log("[onFinishAdd]:kq=", kq);
      setVisibleEdit(false);
      setSelectedItem(null);

      setRefressSearch((pre) => !pre);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error.response.data:", error.response.data);
        console.log("error.response.status:", error.response.status);
        console.log("error.response.headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("error.request :", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error error.message", error.message);
      }
      console.log("error.config:", error.config);
    }
    return;
  };
  const onFinishFailedAdd = (error) => {
    console.log("[onFinishFailedAdd]:error=", error);
  };

  const handleOnChangeZNSTemplateList = (value) => {
    console.log("[handleOnChangeZNSTemplateList]:value=", value);
    console.log(
      "[handleOnChangeZNSTemplateList]:departmentSelected=",
      departmentSelected
    );
    setZnsTemplateListSelected(value);
  };
  const getZNSTemplateList = async (company_idv) => {
    const postData = { company_id: company_idv };
    console.log("[getZNSTemplateList]:postData=", postData);
    try {
      const kq = await axiosi.post("zalo/getTemplateList", postData);
      console.log("[getZNSTemplateList]:kq=", kq);
      setZnsTemplateList(kq.data.data);
      console.log("[getZNSTemplateList]:template_id=", item.template_id);
      console.log(
        "[getZNSTemplateList]:znsTemplateListSelected=",
        znsTemplateListSelected
      );
      setZnsTemplateListSelected(parseInt(item.template_id));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getZNSTemplateList(companyid?.value);
  }, [companyid?.value]);
  return (
    <>
      {/* {console.log("[Edit_ZnsPlan]:item:", item)}
      {console.log("[Edit_ZnsPlan]:item:", item)} */}
      <Form
        name="basic"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        initialValues={{
          ...item,
          // department_ids: JSON.parse(item?.department_ids),
          dob: moment(moment(item.dob).format("YYYY-MM-DD"), "YYYY-MM-DD"),
        }}
        onFinish={onFinishAdd}
        onFinishFailed={onFinishFailedAdd}
        autoComplete="off"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input a Name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="departments"
              // name="department_id"
              rules={[
                { required: false, message: "Please select the departments!" },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleOnChangeDepartment}
                value={departmentSelected}
                // mode="multiple"
              >
                <Option value=""></Option>
                {departmentData &&
                  departmentData.map((item) => {
                    if (checkLogDep(item)) {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    }
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="ZNS Template"
              // name="znsTemplate"
              rules={[
                { required: true, message: "Please input a ZNS Template!" },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleOnChangeZNSTemplateList}
                value={znsTemplateListSelected}
                // mode="multiple"
              >
                <Option value=""></Option>

                {znsTemplateList &&
                  znsTemplateList.length > 0 &&
                  znsTemplateList.map((item) => {
                    return (
                      <Option key={item.templateId} value={item.templateId}>
                        {item.templateId} - {item.templateName}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label="note"
              name="note"
              rules={[
                { required: false, message: "Please input a priority_level!" },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>{" "}
          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
