const data = {
  title: "title1",
  content: `Các nước tiêm mũi tăng cường thế nào? Để đối phó biến chủng Omicron
  lây lan nhanh, Israel, Mỹ cùng nhiều nước châu Âu và một số quốc gia
  châu Á đang thúc đẩy tiêm mũi tăng cường. Từ khi biến chủng Omicron
  xuất hiện, hàng loạt quốc gia trên thế giới đang chứng kiến xu hướng
  ca nhiễm tăng nhanh chóng, kể cả những nước đã có tỷ lệ dân số tiêm
  chủng cao. Trước thực tế đó, chính phủ nhiều nước đang bắt đầu xem
  xét lại định nghĩa "tiêm đầy đủ vaccine", nhằm thúc đẩy mũi tiêm
  tăng cường để ứng phó biến chủng lây lan nhanh. "Theo thời gian, khả
  năng bảo vệ của hai liều vaccine bắt đầu suy yếu, trong khi mũi thứ
  ba giúp tăng cường khả năng bảo vệ trở lại, với hiệu quả chống ca
  nhiễm có triệu chứng lên tới hơn 90%", Thủ tướng Anh Boris Johnson
  phát biểu trong cuộc họp báo hôm 15/11. Theo ông, tiêm chủng đầy đủ
  giờ đây không phải là hai mũi vaccine Covid-19, mà là ba mũi. Israel
  là nước tiên phong triển khai chương trình tiêm mũi vaccine tăng
  cường từ cuối tháng 7, với đối tượng đầu tiên là nhóm trên 60 tuổi
  đã tiêm liều thứ hai được 5 tháng. Quyết định được đưa ra sau khi
  một nghiên cứu của hãng dịch vụ y tế Leumit cho thấy những người
  trên 60 tuổi sau 5 tháng tiêm hai liều vaccine có nguy cơ nhiễm nCoV
  cao gấp ba lần so với những người được tiêm muộn hơn. Đến ngày 29/8,
  Israel bắt đầu tiêm mũi vaccine tăng cường cho tất cả những người từ
  12 tuổi trở lên. Giờ đây, những người tiêm liều thứ hai sau 6 tháng
  phải tiêm mũi tăng cường mới đủ điều kiện duy trì "thẻ xanh
  vaccine", giúp họ có thể đến nhà hàng, phòng gym và các địa điểm
  công cộng khác. Bất chấp những nghi ngờ ban đầu về mức độ cần thiết
  của mũi tăng cường, sau hơn ba tháng triển khai, giới chức y tế
  Israel cho biết dữ liệu thu được chứng minh chương trình tiêm chủng
  tăng cường giúp kiềm chế làn sóng đại dịch thứ tư hồi tháng 8 và
  tháng 9. Vào lúc đỉnh điểm của làn sóng này, Israel ghi nhận hơn
  8.000 ca nhiễm mới mỗi ngày, nhưng đến cuối tháng 11 chỉ còn khoảng
  500 ca. Giới chức Israel cho biết hiệu quả của mũi tăng cường được
  thể hiện rõ rệt ở số ca nhập viện. Hồi tháng 10, tỷ lệ ca nhiễm
  nghiêm trọng trên 60 tuổi đã tiêm hai liều vaccine cao gấp 5 lần so
  với những người đã tiêm ba mũi. Các nghiên cứu gần đây cũng cho thấy
  mũi vaccine tăng cường là "vũ khí" chống dịch hữu hiệu, trong bối
  cảnh biến chủng Omicron gây lo ngại về nguy cơ lấn lướt Delta để trở
  thành chủng trội toàn cầu.
`,
};

export default data;
