
import React,{useState,useEffect} from 'react';
import {Button,Input,Space,Form,Select,Spin,notification } from 'antd';
import { CloudUploadOutlined} from '@ant-design/icons';
import useAxios from './../../../CustomHooks/useAxios';
export default function UploadFile({setRefressSearch,setVisibleAdd}) {
    const [api, contextHolder] = notification.useNotification();
    const {axiosi}=useAxios();
    const [file, setFile] = useState();

    const openNotificationWithIcon = (type,message,description,duration,placement) => {
      api[type]({message,description,duration,placement  });
    };

    const handleUpload=async()=>{
        console.log("handleUpload");
        const data = new FormData();
        data.append("file", file);
        const kq=await axiosi.post("comcontacts/importExcel",data);
        console.log(kq);
        setRefressSearch(pre=>!pre);
        setVisibleAdd(false);
        openNotificationWithIcon('success',"Successfully","Upload data is successfully!",2,"bottomRight");

    }
    
  return (
    <>
     {contextHolder}
    <div>UploadFile</div>
    <Input type="file"  allowClear  onChange={(e) => setFile(e.target.files[0])} />   
    
    <Button onClick={handleUpload}  className="addButtonkk" icon={<CloudUploadOutlined  style={{ color: '#1890ff' }} />}>Add</Button>
    
    </>
  )
}
