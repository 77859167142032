import React,{useState,useEffect} from 'react';
import { useSelector} from "react-redux";
import {Button,Drawer,Input,Space,Form,Select,Spin} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import debounce from 'lodash/debounce';
import useAxios from './../../../CustomHooks/useAxios';
import DebounceSelect from './../../DebounceSelect'
const { Option } = Select;
export default function Add({setRefressSearch,setVisibleAdd}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const user = useSelector((state) => state.loginReducer.user);
  const {axiosi}=useAxios();
  
    const [companyid, setCompanyid] = React.useState({value:user.agents[0].company_id,label:user.agents[0].gmcomname});
    const [mainReadData,setMainReadData]=useState(null);
    const [mainReadSelected,setMainReadSelected]=useState('');    
    const [nextMainDialPlanData,setNextMainDialPlanData]=useState(null);
    const [nextMainDialPlainSelectedid,setNextMainDialPlainSelectedid]=useState('');

   

    useEffect(()=>{
      const getItemsRead=async()=>{
          try {
          const kq=await axiosi.get('ivrplanmain/getItemsRead',{params:{company_id:companyid.value,pageSize:100}} );
          console.log(kq.data.rows);
          setMainReadData(kq.data.rows);
         setMainReadSelected('');
         setNextMainDialPlainSelectedid('');
          } catch (err){
              setMainReadData(null);
              console.log("[useEffect]:getItemsRead has an Error !")
          }
      }
      getItemsRead();
  },[companyid?.value])

  useEffect(()=>{
    const getNextMainDialplan=async()=>{
      try {
        if (companyid){
      const kq=await axiosi.get('ivrplanmain',{params:{company_id:companyid.value,pageSize:100}});
      console.log("[useEffect-getNextMainDialplan]:kq=",kq)
      setNextMainDialPlanData(kq.data.rows);
        } else {
          setNextMainDialPlanData(null);
        }
      }
      catch (err){
        console.log("[useEffect-getNextMainDialplan]:err=",err);
      }

    }
    getNextMainDialplan();
  },[companyid])

  const handleOnChangeNextMainDialPlan=(value)=>{
    setNextMainDialPlainSelectedid(value);
    console.log("[handleOnChangeNextMainDialPlan]:value=",value)
  }
  const handleOnChangeMainRead=(value)=>{
    console.log("[handleOnChangeMainRead]:value=",value);
    setMainReadSelected(value);
}
   async function fetchUserList(company_name) {        
        console.log('fetching user for companies ', company_name);
        const body=await axiosi.get('companies',{params:{company_name,pageSize:100}} );
        if (body?.data?.rows) {
            return body?.data?.rows.map((user) => ({
                label: user.company_name,
                 value: user.id,
                //  value:{id: user.id,company_code:user.company_code},
                 company_code:user.company_code,
               }));
        }
        else {
            const a=[{label:"---no data---",value:""}]
            return a.map(i=> (i))
        }
       
    }

    
    const onFinishAdd=async (values)=>{
  
        try {
            const kq=await axiosi.post("ivrplanforkey",{...values,maindialplanid:mainReadSelected,nextmaindialplanid:nextMainDialPlainSelectedid});
            console.log("[onFinishAdd]:kq=",kq) 
            setVisibleAdd(false);
            setRefressSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error.response.data:",error.response.data);
                console.log("error.response.status:",error.response.status);
                console.log("error.response.headers:",error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request :",error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error error.message', error.message);
              }
              console.log("error.config:",error.config);
        }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }

    
    const handleOnChangeCompanyName=async(value)=>{
          setCompanyid(value);
          // const kq= await axiosi.get('tels',{params:{companyid:value.value,pageSize:100}});
          // console.log("[handleOnChangeCompanyName]:kq_get_tels=",kq);
          // console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
          // setTelsoutData(kq.data);
          // setTelout("");
    }
    
    
  return (<>
  {/* {console.log("[return]:ivrVisible=",ivrVisible)} */}
        <Form
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      initialValues={{ordnum:0 }}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
      

        <Form.Item className={hideItem}
        label="Company Name"
        // name="company_name"
        rules={[{ required: true, message: 'Please input a company_name!' }]}
      >
        <DebounceSelect
          showSearch={true}
          value={companyid}
          placeholder="Select users"
          fetchOptions={fetchUserList}
          onChange={handleOnChangeCompanyName}          
          style={{  width: '100%', }}
        />
      </Form.Item>

      <Form.Item
        label="Main Plan IVR :"
        //name="ordnum"
        rules={[{ required: true, message: 'Please input a Main Plan IVR!' }]}
      >
        {mainReadData && <>
                    <Select style={{ width: '100%' }} 
                            onChange={handleOnChangeMainRead}                            
                            value={mainReadSelected}                    
                    >
                        <Option value=""></Option>
                        { mainReadData && mainReadData.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
                    </Select>
        
      </>}      
      </Form.Item>
      <Form.Item
        label="IVR Key :"
        name="ikey"
        rules={[{ required: false, message: 'Please input an IVR Key!' }]}
      >
        <Input  />        
      </Form.Item>
      
      



      <Form.Item
        label="nextmaindialplanid :"
       // name="nextmaindialplanid"
        rules={[{ required: false, message: 'Please input a nextmaindialplanid!' }]}
      >
        {nextMainDialPlanData && <>
            <Select style={{ width: '100%' }}  showSearch
                    onChange={handleOnChangeNextMainDialPlan}
                    //(value)=>setMenu_item_id(value)}
                    // loading={menuItemDataLoading}
                    value={nextMainDialPlainSelectedid}
                    optionFilterProp="children"
                    placeholder="Select a person"
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    
            >
                        <Option value=""></Option>
                        { nextMainDialPlanData && nextMainDialPlanData.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
            </Select>
        
      </>}  
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
  </>);
}
