import React from 'react';
import {useState,useEffect} from 'react';
import { useSelector} from "react-redux";
import {Button,Drawer,Input,Space,Form} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
// import Upload from "./Upload";
import Edit from "./Edit";
import Delete from "./Delete";
import CallTo from './../../CallTo'
import useAxios from './../../../CustomHooks/useAxios';

export default function SelectaContactShow({data,setRefressSearchCallLog,setRefressSearch,provinceData,setCustomerid,setVisibleSwitch,uniqueid}) {
    const hideItem = useSelector((state) => state.loginReducer.hideItem);
    const {axiosi}=useAxios();
  const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleRemove, setVisibleRemove] = useState(false);
    
    const [selectedItem,setSelectedItem]=useState(null);
    const [selectedDeleteItem,setSelectedDeleteItem]=useState(null);

    const handleEdit=async(item)=>{
      console.log("[handleEdit]:item=",item);

      setCustomerid(item.id);
      setVisibleSwitch(pre=>!pre);
      // setSelectedItem(item);
      // setVisibleEdit(true);
      if (uniqueid){
        try {
        const kq=await axiosi.put("calllogs/updateCustomerToCDR",{
          customer_company:item.ccomname,
          customer_name:item.name,
          customer_id:item.id,
          uniqueid,
        });
        setRefressSearchCallLog(pre=>!pre);
      }
      catch {
        console.log("[handleEdit]:calllogs/updateCustomerToCDR : errr");
      }
      }
  }
  const handleRemove=(item)=>{
      console.log("[handleRemove]:item=",item);
      setVisibleRemove(true);
      setSelectedDeleteItem(item);
  }

  const onCloseEdit=()=>{
    console.log("[onCloseEdit]")
    setSelectedItem(null);
    setVisibleEdit(false);
}
  const onCloseRemove=()=>{
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
}

 
return (
  <>
  
      <Drawer width={640} title="Edit  Menu Item" placement="right" onClose={onCloseEdit} visible={visibleEdit}>
          {selectedItem && <Edit provinceData={provinceData} item={selectedItem} setSelectedItem={setSelectedItem} setRefressSearch={setRefressSearch} setVisibleEdit={setVisibleEdit} />}        
      </Drawer>

      <Drawer title="Remove  Menu Item" placement="right" onClose={onCloseRemove} visible={visibleRemove}>
          {selectedDeleteItem && <Delete item={selectedDeleteItem} setRefressSearch={setRefressSearch} setVisibleRemove={setVisibleRemove}/>}        
      </Drawer>

      <table className='table1'>
  <thead>
  <tr>
      {/* <th>id</th> */}
      {/* <th>calldirection</th>
      <th>connected</th> */}
      
      <th width="220px" className={hideItem}>GM's Customer</th>
      <th>company_name</th>
      <th>name</th>
      <th>tel</th>
      <th>gender</th>
      <th>note</th>
      
      
      
      <th width="120px">Action</th>
      

  </tr>
  </thead>
  <tbody>
{data.map(item=>(
  <tr key={item.id}>
      {/* <td>{item.id}</td> */}
      {/* <td>{item.calldirection}</td>
      <td>{item.connected}</td> */}
      
      
      <td className={hideItem}>{item.company_name}</td>
      <td>{item.ccomname}</td>
      <td>{item.name}</td>
      <td><CallTo callTo= {item.tel} /></td>
      <td>{item.gender}</td>
      <td>{item.note}</td>
      
      
      
      <td> 
         {/* <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
         <div className='rowFlex'>
             <Button onClick={()=>handleEdit(item)} icon={<EditFilled  style={{ color: 'hotpink' }}  />}>Select</Button>
              {/* <Button onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Remove</Button> */}
              {/* <Upload/> */}
              
         </div>
         {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
    Download
  </Button> */}
      </td>
      
  </tr>
))}
</tbody>
</table>
  </>
)
}