import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Input, Form, Select, InputNumber } from "antd";

import useAxios from "./../../../CustomHooks/useAxios";
import DebounceSelect from "./../../DebounceSelect";
const { Option } = Select;
const { TextArea } = Input;
export default function SendSMS({
  setRefressSearch,
  setVisibleSendSMS,
  showSMSResult,
}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const user = useSelector((state) => state.loginReducer.user);
  const { axiosi } = useAxios();

  const [companyid, setCompanyid] = React.useState({
    value: user.agents[0].company_id,
    label: user.agents[0].gmcomname,
  });

  async function fetchUserList(company_name) {
    console.log("fetching user", company_name);
    const body = await axiosi.get("companies", {
      params: { company_name, pageSize: 100 },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
        //  value:{id: user.id,company_code:user.company_code},
        company_code: user.company_code,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
  }

  const onFinish = async (values) => {
    console.log("Success:", values);
    let adddata = {
      ...values,
    };
    console.log("[onFinishAdd]:values=", adddata);

    try {
      const kq = await axiosi.post("sms/sendSMS", adddata);
      console.log("[onFinishAdd]:kq=", kq);
      //   setVisibleAdd(false);
      setRefressSearch((pre) => !pre);
      if (kq["data"]["error"] != "") {
        console.log("[Error1]:", kq["data"]["error"]);
        showSMSResult(kq["data"]["error"], "warning");
        return "";
      }

      if (kq["data"]["data"]["result"] != "1") {
        console.log("[Error2]:", kq["data"]["data"]["message"]);
        showSMSResult(kq["data"]["data"]["message"], "warning");
        return "";
      }
      console.log("[OK]:", kq["data"]["data"]["message"]);
      showSMSResult(kq["data"]["data"]["message"], "success");
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error.response.data:", error.response.data);
        console.log("error.response.status:", error.response.status);
        console.log("error.response.headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("error.request :", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error3 error.message", error.message);
      }
      console.log("error.config:", error.config);
    }
  };
  const onFinishFailedAdd = (error) => {
    console.log("[onFinishFailedAdd]:error=", error);
  };

  const handleOnChangeCompanyName = async (value) => {
    setCompanyid(value);
  };

  return (
    <>
      {/* {console.log("[return]:ivrVisible=",ivrVisible)} */}
      <Form
        name="basic"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        initialValues={{
          ordnum: 0,
          numcurrentcall: 5,
          allownumcall: 2,
          minutebetweencall: 300,
          allowdaycall: 1,
          manualsurvey: 0,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailedAdd}
        autoComplete="off"
      >
        <Form.Item
          label="Tel"
          name="tel"
          rules={[
            {
              required: true,
              message: "Please input a tel!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Content"
          name="smsContent"
          rules={[
            {
              required: true,
              message: "Please input a SMS Content!",
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
