import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Button, Input, Form, Select, Spin, message } from 'antd';
// import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import debounce from 'lodash/debounce';
import useAxios from './../../CustomHooks/useAxios';
const { Option } = Select;


function DebounceSelect({ fetchOptions, debounceTimeout = 350, ...props }) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);


  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      allowClear
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
} // Usage of DebounceSelect

export default function EditAgentsRange({ setVisible, setRefreshSearch }) {
  const { axiosi } = useAxios();
  const user = useSelector((state) => state.loginReducer.user);
  // console.log("[AddAgents]:user=",user);
  const [companyid, setCompanyid] = React.useState({ value: user.agents[0].company_id, label: user.agents[0].gmcomname });

  const [authorization_groups, setAuthorization_groups] = useState(null);
  const [authorization_groupsLoading, setAuthorization_groupsLoading] = useState(true);
  const [authorization_group_id, setAuthorization_group_id] = useState("");

  const [telsoutData, setTelsoutData] = useState(null);
  const [telsoutDataLoading, setTelsoutDataLoading] = useState(false);

  const [telout, setTelout] = useState("");
  const hideItem = useSelector((state) => state.loginReducer.hideItem);



  const [enablecallout, setEnablecallout] = useState(1);
  const [teloutrule, setTeloutrule] = useState("");
  const [department_idData, setDepartment_idData] = useState(null);
  const [department_id, setDepartment_id] = useState("");

  const [callRuleData, setCallRuleData] = useState(null);
  const [call1Rule, setCall1Rule] = useState("");
  const [call2Rule, setCall2Rule] = useState("");
  const [showCallout, setShowCallout] = useState((teloutrule !== "calloutrule"));
  const [showCallTelout, setShowTelout] = useState((teloutrule !== ""));
  function handleOnChangeCalloutRule(value) {
    console.log({ value, showCallout, hideItem });

    setTeloutrule(value);
    setShowCallout(value !== "calloutrule");
    setShowTelout(value !== "");
  }

  useEffect(() => {
    const getCallRuleData = async () => {
      const kq = await axiosi.get('tels_callout_rule', { params: { companyid: companyid?.value, pageSize: 100 } });
      console.log("[getCallRuleData]:kq.data=", kq.data);
      // console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
      setCallRuleData(kq.data);
      // setTelout(item?.tels_id);
    }
    getCallRuleData();
  }, [companyid?.value])
  useEffect(() => {
    if (user?.agents) {
      setCompanyid({ value: user.agents[0].company_id, label: user.agents[0].gmcomname });
      // if(user?.agents[0].company_id!==1) {
      //     // console.log("[AddAgents-useEffect]-------------------:Set setCompanyid:",companyid);
      //     setCompanyid({value:user.agents[0].company_id,label:user.agents[0].gmcomname});
      //     // setHideItem('col');
      // } else {
      //     // console.log("[AddAgents-useEffect]-------------------:Set unsetCompanyid:",companyid);
      //     setCompanyid({value:"",label:""});
      // }
      // console.log("[Tels-useEffect]-------------------:company_id=",user?.agents[0]?.company_id);
    }
  }, [user?.agents])


  useEffect(() => {
    const getTelout = async () => {
      if (companyid?.value === '') return;
      const kq = await axiosi.get('tels', { params: { companyid: companyid?.value, pageSize: 100 } });
      console.log("[handleOnChangeCompanyName]:kq_get_tels=", kq);
      // console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
      setTelsoutData(kq.data);
      setTelout("");
    }
    getTelout();
  }, [companyid?.value])

  useEffect(() => {
    const getDepartment = async () => {
      const kq = await axiosi.get('department', { params: { company_id: companyid?.value, pageSize: 100 } });
      console.log("[getDepartment]:kq=", kq);

      setDepartment_idData(kq.data);
      setDepartment_id('');
    }
    getDepartment();
  }, [companyid?.value])

  useEffect(() => {
    const getAuthorizationGroups = async () => {
      const kq = await axiosi.get('authorization_groups', { params: { pageSize: 200 } });
      console.log("[MenuItem-useEffect]:kq.data=", kq.data)
      setAuthorization_groups(kq.data);
      setAuthorization_groupsLoading(false);
    }
    getAuthorizationGroups();
  }, [])

  async function fetchUserList(company_name) {

    console.log('fetching user', company_name);
    const body = await axiosi.get('companies', { params: { company_name, pageSize: 100 } });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
        //  value:{id: user.id,company_code:user.company_code},
        company_code: user.company_code,
      }));
    }
    else {
      const a = [{ label: "---no data---", value: "" }]
      return a.map(i => (i))
    }

  }
  function isDigit(myString) {
    var reg = /^\d+$/;
    return (reg.test(myString));
  }
  const onFinishAdd = async (values) => {
    // console.log("[onFinishAdd]:values=",values);
    // console.log("[onFinishAdd]:companyid=",companyid);
    // console.log("[onFinishAdd]:telsoutData=",telsoutData);
    // console.log("[onFinishAdd]:telout=",telout);
    const teloutSelected = telsoutData?.rows?.find((item) => item.id === telout)
    // console.log("[onFinishAdd]:teloutSelected=",teloutSelected);
    //check ep_usernameFrom///////////////////////////////////////////////////
    if (!isDigit(values.ep_usernameFrom)) {
      message.info('"From Ext number"  should be a number !');
    }
    if (values.ep_usernameFrom[0] == 0) {
      message.info(" [From Ext number] : The first letter should not be 0 !");
      return;
    }
    if (values.ep_usernameFrom.length != 4 && values.ep_usernameFrom.length != 5) {
      message.info(" [From Ext number] : The ext should be 4 or 5 digits !");
      return;
    }

    //check ep_usernameFrom///////////////////////////////////////////////
    if (!isDigit(values.ep_usernameTo)) {
      message.info('"To Ext number"  should be a number !');
      return;
    }
    if (values.ep_usernameTo[0] == 0) {
      message.info(" [To Ext number] : The first letter should not be 0 !");
      return;
    }
    if (values.ep_usernameTo.length != 4 && values.ep_usernameTo.length != 5) {
      message.info(" [To Ext number] : The ext should be 4 or 5 digits !");
      return;
    }

    //check ep_usernameTo - ep_usernameFrom
    if (values.ep_usernameFrom.length != values.ep_usernameTo.length) {
      message.info(" [To Ext number] And [From Ext number] should be the same length (XXXX or XXXXX) !");
      return;
    }

    let numExt = values.ep_usernameTo - values.ep_usernameFrom + 1;
    if (numExt < 0) {
      message.info(" [To Ext number] should be greater than  [From Ext number] !");
      return;
    }
    if (enablecallout && teloutrule == "calloutrule") {
      if (call1Rule == "") {
        message.info(" [Call1 rule] : Please set a rule for call1 !");
        return;
      }
    }
    let kq0;
    try {
      kq0 = await axiosi.get('agents/getNumberAgentLeft', { params: { company_id: companyid.value } });
      console.log("[agents/getNumberAgentLeft]:kq0.data=", kq0.data);
      // setData(kq.data);
      // let canNumExt=kq0.data.rows[0].numagents-kq0.data.rows[0].curagents;
      // if (numExt>canNumExt){
      //   message.info(`The range Ext is over the limited ! ${numExt}>${canNumExt}`);
      //   return;
      // }
    } catch (err) {
      console.log("ERROR");
      message.info("error");
      return;
    }
    // message.info("" + numExt);
    // return;
    // const newAgent={...values,
    //   // name:values.name,
    //   // username,
    //   password,
    //   authorization_group_id,
    //   endpoint_id,
    //   company_id,
    //   telout_num,
    //   telout_peer,
    //   max_contacts,
    //   ep_username,
    //   ep_password,
    //   note,
    //   tel,
    //   email,
    //   webrtc,
    // }
    // return;
    try {
      const kq = await axiosi.put("agents/Range", {
        ...values, company_id: companyid.value
        , company_code: kq0.data.rows[0].company_code
        , call1rule: call1Rule
        , call2rule: call2Rule
        , tels_id: telout
        , telout_num: teloutSelected ? teloutSelected.telout : ""
        , telout_peer: teloutSelected ? teloutSelected.peername : ""
        , endpoint_id: values.ep_username
        , department_id, enablecallout, teloutrule
      });
      console.log("[onFinishAdd]:kq=", kq);
      setVisible(false);
      setRefreshSearch(pre => !pre);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        message.error(`Error: ${error.response.data.code}`);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    }
  }
  const onFinishFailedAdd = (error) => {
    console.log("[onFinishFailedAdd]:error=", error);
  }


  const handleOnChangeCompanyName = async (value) => {
    setCompanyid(value);
    const kq = await axiosi.get('tels', { params: { companyid: value.value, pageSize: 100 } });
    console.log("[handleOnChangeCompanyName]:kq_get_tels=", kq);
    console.log("[handleOnChangeCompanyName]:selectedvalue=", value);
    setTelsoutData(kq.data);
    setTelout("");
  }
  const handleOnChangeTelsout = async (value) => {
    setTelout(value);
    console.log(value);
  }

  // if (1)return (<h1>hello</h1>)

  return (<>
    <Form
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      initialValues={{ webrtc: "yes", max_contacts: 10 }}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
      <Form.Item className={hideItem}
        label="Company Name"
        // name="company_name"
        rules={[{ required: (hideItem != "hideItem"), message: 'Please input a company_name!' }]}
      >
        <DebounceSelect
          showSearch={true}
          value={companyid}
          placeholder="Select users"
          fetchOptions={fetchUserList}
          onChange={handleOnChangeCompanyName}
          // {(newValue) => {setCompanyid(newValue);}}
          style={{ width: '100%', }}
        />
      </Form.Item>
      <Form.Item label="Author group" name="authorization_group_id"
        rules={[{ required: true, message: 'Please input a Author group!' }]}
      >
        <Select style={{ width: '100%' }}
          onChange={(value) => setAuthorization_group_id(value)}
          loading={authorization_groupsLoading}

        >
          <Option value=""></Option>
          {authorization_groups?.rows && authorization_groups.rows.map(item => (
            <Option key={item.id} value={item.id}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>




      <Form.Item
        label="Department"
      >
        {department_idData?.rows && <>
          <Select style={{ width: '100%' }}
            // onChange={handleOnChangeTelsout}
            onChange={(value) => setDepartment_id(value)}
            //(value)=>setMenu_item_id(value)}
            // loading={menuItemDataLoading}
            value={department_id}

          >
            <Option value=""></Option>
            {department_idData?.rows && department_idData.rows.map(item => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>

        </>}
      </Form.Item>

      <Form.Item
        label="From Ext number :"
        name="ep_usernameFrom"
        rules={[{ required: true, message: 'Please input an ext number!' }]}
      >
        <Input />

      </Form.Item>

      <Form.Item
        label="To Ext number :"
        name="ep_usernameTo"
        rules={[{ required: true, message: 'Please input an ext number!' }]}
      >
        <Input />

      </Form.Item>

      {/* <Form.Item
        label="Agent Name :"
        name="name"
        rules={[{ required: true, message: 'Please input an Agent name!' }]}
      >
        <Input  />
        
      </Form.Item> */}


      {/* <Form.Item
        label="Agent Password :"
        name="password"
        rules={[{ required: true, message: 'Please input an ext password!' }]}
      >
        <Input.Password  />       
      </Form.Item> */}

      {/* <Form.Item
        label="Ext number :"
        name="ep_username"
        rules={[{ required: true, message: 'Please input an ext number!' }]}
      >
        <Input  />
        
      </Form.Item> */}

      {/* <Form.Item
        label="ext username :"
        name="ep_username"
        // rules={[{ required: true, message: 'Please input an ext username!' }]}
      >
        <Input  />       
      </Form.Item> */}

      {/* <Form.Item
        label="Ext password :"
        name="ep_password"
        rules={[{ required: true, message: 'Please input an ext password!' }]}
      >
        <Input.Password  />       
      </Form.Item> */}
      {/* <Form.Item
        label="max_contacts :"
        name="max_contacts"
        rules={[{ required: true, message: 'Please input an max_contacts!' }]}
      >
        <Input  />       
      </Form.Item> */}
      <Form.Item
        label="webrtc :"
        name="webrtc"
      // rules={[{ required: true, message: 'Please input an webrtc!' }]}
      >
        <Select>
          <Option value="no">no</Option>
          <Option value="yes" >yes</Option>
        </Select>
      </Form.Item>

      


      <Form.Item
        label="Enable Callout :"
        // name="dep"
        rules={[{ required: false, message: 'Please input an department!' }]}
      >
        <Select
          // defaultValue="0"
          value={enablecallout} onChange={(value) => setEnablecallout(value)}>
          <Option value={1}>Enable</Option>
          <Option value={0} >Disable</Option>
        </Select>
      </Form.Item>




      <Form.Item className={hideItem}
        label="Callout Rule :"
        // name="dep"
        rules={[{ required: false, message: 'Please input an department!' }]}
      >
        <Select onChange={(value) => handleOnChangeCalloutRule(value)} value={teloutrule}>
          <Option value="">fixed</Option>
          <Option value="random">Random</Option>
          <Option value="lowestcode" >Lowest cost</Option>
          <Option value="calloutrule" >Company Callout Rule</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="telout"
        name1="telout"
        // hidden={hideItem === "hideItem"}
        hidden={showCallTelout}
      >
        {telsoutData?.rows && <>
          <Select style={{ width: '100%' }}
            onChange={handleOnChangeTelsout}
            //(value)=>setMenu_item_id(value)}
            // loading={menuItemDataLoading}
            value={telout}

          >
            <Option value=""></Option>
            {telsoutData?.rows && telsoutData.rows.map(item => (
              <Option key={item.id} value={item.id}>{item.telout}</Option>
            ))}
          </Select>

        </>}
        {/* {!telsoutData?.rows && <Input/>} */}
      </Form.Item>




      <Form.Item

        label="Call1 rule"
        name1="telout"
        // hidden={(hideItem === "hideItem")&&showCallout}
        hidden={showCallout}
      >
        {callRuleData?.rows && <>
          <Select style={{ width: '100%' }}
            onChange={value=>setCall1Rule(value)}
            value={call1Rule}

          >
            <Option key={0} value={0}> </Option>
            {callRuleData?.rows && callRuleData.rows.map(item => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>

        </>}

      </Form.Item>

      <Form.Item

        label="Call2 rule"
        name1="telout"
        hidden={showCallout}
      >
        {callRuleData?.rows && <>
          <Select style={{ width: '100%' }}
            onChange={value=>setCall2Rule(value)}
            value={call2Rule}

          >
            <Option key={0} value={0}> </Option>
            {callRuleData?.rows && callRuleData.rows.map(item => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>

        </>}

      </Form.Item>

      {/* <Form.Item
        label="Agent email :"
        name="email"
        // rules={[{ required: true, message: 'Please input an email!' }]}
      >
        <Input  />       
      </Form.Item> */}

      {/* <Form.Item
        label="Agent tel :"
        name="tel"
        // rules={[{ required: true, message: 'Please input an email!' }]}
      >
        <Input  />       
      </Form.Item> */}



      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  </>);
}
