import React from 'react';
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD HH:mm:ss';
export default function ShowTable({data}) {
  console.log("[ShowTable]:data=",data);
  const formatTime=(second)=>{
    // return("second");
    
    const b=86400;
    // second += b*22;
    if (second>=b) {
        let giay=second%b
        let ngay = (second - giay)/b
        //new Date(3600*24 * 1000).toISOString().substr(11, 8)
        return `${ngay}d.`+ new Date(second * 1000).toISOString().substring(11, 19);
        //${moment(giay).format("HH:mm:ss")}`; 
        //+ new Date(giay * 1000).toISOString().substr(11, 8);
        //${moment(giay).format(dateFormat)}`;
    } else {
        
       return  new Date(second * 1000).toISOString().substring(11, 19);//
        // return `${moment(12).format("HH:mm:ss")}`;
    } 
    //return `${moment(second).format(dateFormat)}`;
    //new Date(giay * 1000).toISOString().substr(11, 8);//
}
const displayExt = (endpointid) => {
  // console.log("[displayExt]:endpointid=",endpointid);
  if (endpointid) {
      const ext = endpointid.split("_");
      return ext[0];
  } else {
      return "";
  }

};
  return (
    <>
      <table className='table1'>
  <thead>
  <tr>
      {/* <th>id</th> */}
      {/* <th>calldirection</th>
      <th>connected</th> */}
      
      
      <th>Agent</th>
      <th>Event</th>
      <th>Start</th>
      <th>End</th>      
      <th>Duration</th>
      
      

  </tr>
  </thead>
  <tbody>
{data.map(item=>(
  <tr key={item.id}>
      
      
      <td>{displayExt(item.agentcode)} - {item.agentname}</td>
      <td>{item.eventname}</td>
      <td>{moment(item.starttime).format(dateFormat)}</td>
      <td>{item.endtime && moment(item.endtime).format(dateFormat)}</td>
      <td>{item.duration!=0 && formatTime(item.duration) }</td>
      
      
      
  </tr>
))}
</tbody>
</table>
    </>
  )
}
