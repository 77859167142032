import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Button, Drawer, Input, Space, Form } from "antd";
import {
  PhoneOutlined,
  DownloadOutlined,
  PlusCircleFilled,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import moment from "moment";
// import Upload from "./Upload";
import Edit from "./Edit";
import Delete from "./Delete";
import CallTo from "./../../CallTo";
const dateFormat = "YYYY-MM-DD HH:mm:ss";
export default function ShowTable({ data, setRefressSearch, provinceData }) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  console.log("[outboundplan-ShowTable]:hideItem=", hideItem);
  const user = useSelector((state) => state.loginReducer.user);

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleRemove, setVisibleRemove] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);

  const makeCall = useSelector((state) => state.jsSIPReducer.makeCall);

  const handleEdit = (item) => {
    console.log("[handleEdit]:item=", item);
    setSelectedItem(item);
    setVisibleEdit(true);
  };
  const handleRemove = (item) => {
    console.log("[handleRemove]:item=", item);
    setVisibleRemove(true);
    setSelectedDeleteItem(item);
  };

  const onCloseEdit = () => {
    console.log("[onCloseEdit]");
    setSelectedItem(null);
    setVisibleEdit(false);
  };
  const onCloseRemove = () => {
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
  };

  const handleMakeCall = (callto) => {
    makeCall(callto);
  };
  const getDepName = (depid) => {
    return user?.deps[depid];
  };
  const getEditInfo = (item) => {
    let agetntcode = item.editagentcode.split("_")[0];
    let kq = item.editagentname + " - " + agetntcode;

    return kq;
  };
  return (
    <>
      <Drawer
        width={640}
        title="Edit Item"
        placement="right"
        onClose={onCloseEdit}
        visible={visibleEdit}
      >
        {selectedItem && (
          <Edit
            provinceData={provinceData}
            item={selectedItem}
            setSelectedItem={setSelectedItem}
            setRefressSearch={setRefressSearch}
            setVisibleEdit={setVisibleEdit}
          />
        )}
      </Drawer>

      <Drawer
        title="Remove Item"
        placement="right"
        onClose={onCloseRemove}
        visible={visibleRemove}
      >
        {selectedDeleteItem && (
          <Delete
            item={selectedDeleteItem}
            setRefressSearch={setRefressSearch}
            setVisibleRemove={setVisibleRemove}
          />
        )}
      </Drawer>

      <table className="table1">
        <thead>
          <tr>
            {/* <th>id</th> */}
            {/* <th>calldirection</th>
      <th>connected</th> */}
            {hideItem != "hideItem" ? (
              <th width="220px">GM's Customer</th>
            ) : (
              <th className={hideItem}></th>
            )}

            <th>Name</th>
            <th>Department</th>

            <th>Note</th>
            <th>Update</th>

            <th width="120px">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              {/* <td>{item.id}</td> */}
              {/* <td>{item.calldirection}</td>
      <td>{item.connected}</td> */}

              {hideItem != "hideItem" ? (
                <td>{item.company_name}</td>
              ) : (
                <td className={hideItem}></td>
              )}

              <td>{item.name}</td>
              <td>{getDepName(item.department_id)}</td>

              <td>{item.note}</td>
              <td>
                {getEditInfo(item)} <br />[{" "}
                {moment(item.edittime).format(dateFormat)}]
              </td>

              <td>
                {/* <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
                <div className="rowFlex">
                  <Button
                    onClick={() => handleEdit(item)}
                    icon={<EditFilled style={{ color: "hotpink" }} />}
                  >
                    Edit
                  </Button>
                  <Button onClick={() => handleRemove(item)}>
                    <DeleteFilled style={{ color: "red" }} />
                    Remove
                  </Button>
                  {/* <Upload/> */}
                </div>
                {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
    Download
  </Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
