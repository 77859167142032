import {useState,useEffect} from 'react';
import {Button,Drawer,Input,Space,Form,message} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import useAxios from './../../CustomHooks/useAxios';
export default function EditMenuSubItem({selectedItem,setSelectedItem,setVisibleEdit,setRefreshSearch}){
  const {axiosi}=useAxios();
    console.log("[EditMenuSubItem]:selectedItem=",selectedItem)
    const [form] = Form.useForm();
    const onFinishEdit=async(values)=>{
        console.log("[onFinishEdit]:values=",values)
        try {
        const kq=await axiosi.put(`menu_sub/${selectedItem.id}`,values);
        console.log("[onFinishEdit]:kq.data=",kq.data)
        
        setVisibleEdit(false);
        setSelectedItem(null);
        setRefreshSearch(pre=>!pre);
        }
        catch (error){
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("error.response.data:",error.response.data);
            console.log("error.response.status:",error.response.status);
            console.log("error.response.headers:",error.response.headers);
            message.error(`Error: ${error.response.data.code}`)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
    }
        
    }
    const onFinishFailedEdit=(error)=>{
        console.log("[onFinishFailedEdit]:error=",error)
    }
    return(
        <>
        <Form 
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ menukey: selectedItem.menukey ,
      icon:selectedItem.icon,
    title:selectedItem.title}}
      onFinish={onFinishEdit}
      onFinishFailed={onFinishFailedEdit}
      autoComplete="off"
    >
        <Form.Item
        label="menukey"
        name="menukey"
        rules={[{ required: true, message: 'Please input a menukey!' }]}
      >
        <Input  />
        
      </Form.Item>

      <Form.Item
        label="icon"
        name="icon"
        rules={[{ required: true, message: 'Please input a icon!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="title"
        name="title"
        rules={[{ required: true, message: 'Please input a title!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Update
        </Button>
      </Form.Item>
        </Form>
        </>
    )
}