import {useState,useEffect} from 'react';
import {Select} from 'antd';
import useAxios from './../../CustomHooks/useAxios';
const { Option } = Select;
const b=(<Select style={{ width: '100%' }} autoClearSearchValue >
                        <Option value="b">B</Option>)
                        
                    </Select>);
export default function SelectMenuItem({menu_sub_id}){
    const {axiosi}=useAxios();
    const [menuItemData,setMenuItemData]=useState(null);
    const [a,setA]=useState(b)
    console.log("[SelectMenuItem]:menu_sub_id=",menu_sub_id);
    useEffect(()=>{
        const getMenuItem=async()=>{
            console.log("[SelectMenuItem - useEffect]:menu_sub_id=",menu_sub_id)
            const kq=await axiosi.get('menu_item',{params:{menu_sub_id,item_name:"",pageSize:200}});
            console.log("[SelectMenuItem - useEffect]:kq.data=",kq.data)
            setMenuItemData(kq.data);
            // setMenuItemDataLoading(true);
            let avalue="";
            if (kq.status!=204){
                avalue=(<Select style={{ width: '100%' }} autoClearSearchValue >
                <Option value=""></Option>
                <Option value="a">A</Option>
            </Select>);
            } else {
                avalue=(<Select style={{ width: '100%' }} autoClearSearchValue >
                <Option value=""></Option>
                <Option value="c">C</Option>
            </Select>);
            }
            
           setA(avalue)

            
        }
        getMenuItem();

    },[menu_sub_id])
    // const handleChangeSubMenu=async(value)=>{
    //     console.log("[handleChangeSubMenu]:value=",value)
    //     const kq=await axiosi.get('menu_item',{params:{menu_sub_id,item_name:"",pageSize:200}});
    //     setMenuItemData(kq.data);
    //     setMenuItemDataLoading(true);
        
    // }
    return (<>
    {a}
    {/* <Select style={{ width: '100%' }} >
            <Option value=""></Option>
        {menuItemData?.rows && menuItemData.rows.map(item=>(
            <Option key={item.id} value={item.id}>{item.title}</Option>
        ))}
        <Option value="abc">abc</Option>
    </Select> */}
    </>)
}