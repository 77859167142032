import React,{useState,useEffect} from 'react';
import { useSelector} from "react-redux";
import {Button,Drawer,Input,Space,Form,Select,InputNumber } from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import debounce from 'lodash/debounce';
import useAxios from './../../../CustomHooks/useAxios';
import DebounceSelect from './../../DebounceSelect';
import ToPhoneList from './ToPhoneList';
const {TextArea} = Input;
const { Option } = Select;

export default function Edit({item,setRefressSearch,setVisibleEdit,setSelectedItem}) {
  
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const user = useSelector((state) => state.loginReducer.user);
  const {axiosi}=useAxios();
  const [form] = Form.useForm();

  const [teloutrule,setTeloutrule]=useState(item?.rule);
  const [telsoutData,setTelsoutData]=useState(null);
  const [telout,setTelout]=useState("");
  const [companyid, setCompanyid] = React.useState({value:item.companyid,label:item.company_name});
  
  

  const [showTelList, setShowTelList] = useState((item?.rule==="random"));
  const [toRandomPhone, setToRandomPhone] = useState(JSON.parse(item.tel_list)['toRandomPhone']);
  
  const [toMobileFone, setToMobileFone] = useState(JSON.parse(item.tel_list)['toMobileFone']);
  const [toVinaPhone, setToVinaPhone] = useState(JSON.parse(item.tel_list)['toVinaPhone']);
  const [toViettel, setToViettel] = useState(JSON.parse(item.tel_list)['toViettel']);
  const [toFixedPhone, setToFixedPhone] = useState(JSON.parse(item.tel_list)['toFixedPhone']);
  const [toOthers, setToOthers] = useState(JSON.parse(item.tel_list)['toOthers']);

  
  function handleOnChangeTelRule(value) {
    setTeloutrule(value);
    setShowTelList(value === "random");
  }
  

    
    const onFinishAdd=async (values)=>{
      let tel_list = { toRandomPhone, toMobileFone, toVinaPhone, toViettel, toFixedPhone, toOthers };
        let adddata={...values,companyid:companyid.value ,rule:teloutrule,tel_list:JSON.stringify(tel_list) };
       console.log(adddata);
       console.log(companyid);
        // return ;
        try {
            const kq=await axiosi.put(`tels_callout_rule/${item.id}`,adddata);
            console.log("[onFinishAdd]:kq=",kq) ;
            // form.resetFields();
            setSelectedItem(null);
            setVisibleEdit(false);
            setRefressSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error.response.data:",error.response.data);
                console.log("error.response.status:",error.response.status);
                console.log("error.response.headers:",error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request :",error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error error.message', error.message);
              }
              console.log("error.config:",error.config);
        }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }

   
    const handleOnChangeCompanyName=async(value)=>{
          setCompanyid(value);
          const kq= await axiosi.get('tels',{params:{companyid:value.value,pageSize:100}});
          console.log("[handleOnChangeCompanyName]:kq_get_tels=",kq);
          console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
          setTelsoutData(kq.data);
          // setTelout(null);
          
    }

    async function fetchUserList(company_name) {        
      console.log('fetching user', company_name);
      const body=await axiosi.get('companies',{params:{company_name,pageSize:100}} );
      if (body?.data?.rows) {
          return body?.data?.rows.map((user) => ({
              label: user.company_name,
               value: user.id,
              //  value:{id: user.id,company_code:user.company_code},
               company_code:user.company_code,
             }));
      }
      else {
          const a=[{label:"---no data---",value:""}]
          return a.map(i=> (i))
      }
     
  }
    


  useEffect(()=>{
    const getTelout=async()=>{
      const kq= await axiosi.get('tels',{params:{companyid:item.companyid,pageSize:100}});
      console.log("[handleOnChangeCompanyName]:kq_get_tels=",kq);
      // console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
      setTelsoutData(kq.data);
      // setTelout(item?.tels_id);
    }
    getTelout();
    setTelout(JSON.parse(item?.tel_list));    
  },[item?.company_id])

  return (<>
  
        <Form form={form}
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      initialValues={item}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
      

        <Form.Item className={hideItem}
        label="Company Name"
        // name="company_name"
        rules={[{ required: (hideItem!=="hideItem"), message: 'Please input a company_name!' }]}
      >
        <DebounceSelect
        disabled
          showSearch={true}
          value={companyid}
          placeholder="Select users"
          fetchOptions={fetchUserList}
          onChange={handleOnChangeCompanyName}          
          style={{  width: '100%', }}
        />
      </Form.Item>
      <Form.Item
        label="Name :"
        name="name"
        rules={[{ required: true, message: 'Please input a survey name!' }]}
      >
        <Input  />  
        </Form.Item>


        <Form.Item className={hideItem}
        label="Callout Rule :"
        // name="dep"
        rules={[{ required: false, message: 'Please input a callout rule' }]}
      >
        <Select onChange={(value)=>handleOnChangeTelRule(value)} value={teloutrule}>
          
          <Option value="random">Random</Option>  
          <Option value="lowestcode" >Lowest cost</Option>  
        </Select> 
      </Form.Item>

      <ToPhoneList lableName="TelList" showTelList={!showTelList} telsoutData={telsoutData} setToPhone={setToRandomPhone} phoneValues={toRandomPhone} />
      <ToPhoneList lableName="to MobiFone" showTelList={showTelList} telsoutData={telsoutData} setToPhone={setToMobileFone} phoneValues={toMobileFone} />
      <ToPhoneList lableName="to VinaPhone" showTelList={showTelList} telsoutData={telsoutData} setToPhone={setToVinaPhone} phoneValues={toVinaPhone} />
      <ToPhoneList lableName="to Viettel" showTelList={showTelList} telsoutData={telsoutData} setToPhone={setToViettel} phoneValues={toViettel} />
      <ToPhoneList lableName="to FixedPhone" showTelList={showTelList} telsoutData={telsoutData} setToPhone={setToFixedPhone} phoneValues={toFixedPhone} />
      <ToPhoneList lableName="to OthersPhone" showTelList={showTelList} telsoutData={telsoutData} setToPhone={setToOthers} phoneValues={toOthers}/>


        



      


      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
  </>);
}
