import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {Button,Tooltip} from 'antd'

export default function CallTo({callTo,txt}) {    
    const makeCall = useSelector(state => state.jsSIPReducer.makeCall)
    return (       
     <>   
     <Tooltip title={"Click to call to " + callTo}>
        <Button type="link" onClick={() => makeCall(callTo)} >{txt?txt:callTo}</Button> 
        </Tooltip>           
     </>
        
    )
}
