import React from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Drawer,
  Input,
  Space,
  Form,
  Select,
  Spin,
  message,
} from "antd";
import useAxios from "./CustomHooks/useAxios";

const { Option } = Select;
export default function Pause({ setVisible, setPauseStatus, setPauseReason }) {
  const user = useSelector((state) => state.loginReducer.user);
  const { axiosi } = useAxios();
  const onFinishAdd = async (values) => {
    try {
      const kq = await axiosi.put(`queue_members/pause`, {
        ...values,
        paused: 1,
        membername: user?.agents[0].endpoint_id,
        company_id: user?.agents[0].company_id,
      });
      console.log("[onFinishAdd]:kq=", kq);

      setPauseStatus(true);
      setPauseReason(values.reason);
      setVisible(false);
      message.info("Done !");
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        message.error(`Error: ${error.response.data.code}`);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      console.log(error.config);
    }
  };
  const onFinishFailedAdd = (error) => {
    console.log("[onFinishFailedAdd]:error=", error);
  };
  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        initialValues={{}}
        onFinish={onFinishAdd}
        onFinishFailed={onFinishFailedAdd}
        autoComplete="off"
      >
        <Form.Item
          label="Pause reason"
          name="reason"
          rules={[{ required: true, message: "Please input a pause reason!" }]}
        >
          <Select>
            <Option value=""></Option>
            <Option value="pause_meetings">pause_meetings</Option>
            <Option value="pause_training">pause_training</Option>

            <Option value="pause_eating">pause_eating</Option>
            <Option value="pause_others">pause_others</Option>
          </Select>
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
