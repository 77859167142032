import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Button, Drawer, Input, Space, Form } from "antd";
import {
  PhoneOutlined,
  DownloadOutlined,
  PlusCircleFilled,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
// import Upload from "./Upload";
import Edit from "./Edit";
import Delete from "./Delete";
import CallTo from "./../../CallTo";

export default function ShowTable({ data, setRefressSearch, provinceData }) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleRemove, setVisibleRemove] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);

  const makeCall = useSelector((state) => state.jsSIPReducer.makeCall);

  const handleEdit = (item) => {
    console.log("[handleEdit]:item=", item);
    setSelectedItem(item);
    setVisibleEdit(true);
  };
  const handleRemove = (item) => {
    console.log("[handleRemove]:item=", item);
    setVisibleRemove(true);
    setSelectedDeleteItem(item);
  };

  const onCloseEdit = () => {
    console.log("[onCloseEdit]");
    setSelectedItem(null);
    setVisibleEdit(false);
  };
  const onCloseRemove = () => {
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
  };

  const handleMakeCall = (item) => {
    const callto = outboundCallTel(item);
    makeCall(callto);
  };
  const outboundCallTel = (item) => {
    const comcontacts_id =
      item.comcontacts_id !== null ? "item.comcontacts_id" : "";
    let kq = "ob$" + item.tel + "$" + item.id + "$" + comcontacts_id;
    kq += "$" + item.outboundplan_id;
    return kq;
  };
  const getAgentCode = (agentcode) => {
    return agentcode.split("_")[0];
  };
  return (
    <>
      <Drawer
        width={640}
        title="Edit Item"
        placement="right"
        onClose={onCloseEdit}
        visible={visibleEdit}
      >
        {selectedItem && (
          <Edit
            provinceData={provinceData}
            item={selectedItem}
            setSelectedItem={setSelectedItem}
            setRefressSearch={setRefressSearch}
            setVisibleEdit={setVisibleEdit}
          />
        )}
      </Drawer>

      <Drawer
        title="Remove Item"
        placement="right"
        onClose={onCloseRemove}
        visible={visibleRemove}
      >
        {selectedDeleteItem && (
          <Delete
            item={selectedDeleteItem}
            setRefressSearch={setRefressSearch}
            setVisibleRemove={setVisibleRemove}
          />
        )}
      </Drawer>

      <table className="table1">
        <thead>
          <tr>
            {/* <th>id</th> */}
            {/* <th>calldirection</th>
      <th>connected</th> */}

            <th className={hideItem} width="220px">
              GM's Customer
            </th>
            <th>OutboundPlan</th>
            <th>company_name</th>
            <th>name</th>
            <th>tel</th>
            <th>gender</th>
            <th>Assigned Agent</th>
            <th>note</th>
            <th>Last Call Info</th>
            <th>Last Note Info</th>

            <th width="120px">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              {/* <td>{item.id}</td> */}
              {/* <td>{item.calldirection}</td>
      <td>{item.connected}</td> */}

              <td className={hideItem}>{item.company_name}</td>
              <td>{item.obp_name}</td>
              <td>{item.ccomname}</td>
              <td>{item.name}</td>
              <td>
                {/* <CallTo callTo= {item.tel} /> */}
                <span
                  className="stitle"
                  title="Tel, Click here to make a call!"
                  onClick={() => handleMakeCall(item)}
                >
                  <PhoneOutlined className={"icon"} />
                </span>
                <span className="svalue">{item.tel}</span>
                <br />
              </td>
              <td>{item.gender}</td>
              <td>
                {item.assign_agentname} - {getAgentCode(item.assign_agentcode)}
              </td>
              <td>{item.note}</td>
              <td>numbercalls:{item.numbercalls}</td>
              <td>datastatus:{item.datastatus}</td>

              <td>
                {/* <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
                <div className="rowFlex">
                  <Button
                    onClick={() => handleEdit(item)}
                    icon={<EditFilled style={{ color: "hotpink" }} />}
                  >
                    Edit
                  </Button>
                  <Button onClick={() => handleRemove(item)}>
                    <DeleteFilled style={{ color: "red" }} />
                    Remove
                  </Button>
                  {/* <Upload/> */}
                </div>
                {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
    Download
  </Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
