import React from "react";
import { useSelector } from "react-redux";
import { Button, Checkbox, Form, Input } from "antd";
import useAxios from "./../../../CustomHooks/useAxios";

const { TextArea } = Input;
export default function Index() {
  const user = useSelector((state) => state.loginReducer.user);

  const [companyid, setCompanyid] = React.useState({
    value: user.agents[0].company_id,
    label: user.agents[0].gmcomname,
  });
  const { axiosi } = useAxios();

  const onFinish = async (values) => {
    console.log("Success:", values);
    let adddata = {
      ...values,
      //   department_id: departmentSelected,
      //   template_id: znsTemplateListSelected,
    };
    console.log("[onFinishAdd]:values=", adddata);

    try {
      const kq = await axiosi.post("sms/sendSMS", adddata);
      console.log("[onFinishAdd]:kq=", kq);
      //   setVisibleAdd(false);
      //   setRefressSearch((pre) => !pre);
      if (kq["data"]["error"] != "") {
        console.log("[Error1]:", kq["data"]["error"]);
        return "";
      }

      if (kq["data"]["data"]["result"] != "1") {
        console.log("[Error2]:", kq["data"]["data"]["message"]);
        return "";
      }
      console.log("[OK]:", kq["data"]["data"]["message"]);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error.response.data:", error.response.data);
        console.log("error.response.status:", error.response.status);
        console.log("error.response.headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("error.request :", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error error.message", error.message);
      }
      console.log("error.config:", error.config);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Tel"
          name="tel"
          rules={[
            {
              required: true,
              message: "Please input a tel!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Content"
          name="smsContent"
          rules={[
            {
              required: true,
              message: "Please input a SMS Content!",
            },
          ]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Send
          </Button>
        </Form.Item>
      </Form>

      <div className="formSMSLogs">
        <h3>SMS Logs</h3>
      </div>
    </>
  );
}
