import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Drawer, Button, Typography, Input, Select } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";

import useAxios from "./../../../CustomHooks/useAxios";
import ShowTable from "./ShowTable";
import PageLink from "./../../PageLink/PageLink";
import Add from "./Add";
// import Upload from "./Upload";
// import UploadAntd from "./UploadAntd";
import DebounceSelect from "./../../DebounceSelect";

const { Option } = Select;
export default function IVRPlanMain() {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const user = useSelector((state) => state.loginReducer.user);
  let { page } = useParams();
  const { axiosi } = useAxios();

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [data, setData] = useState();

  const [companyid, setCompanyid] = React.useState({
    value: user.agents[0].company_id,
    label: user.agents[0].gmcomname,
  });
  const [name, setName] = useState("");

  const [refressSearch, setRefressSearch] = useState(true);
  const [mainReadData, setMainReadData] = useState(null);
  const [mainReadSelected, setMainReadSelected] = useState("");
  useEffect(() => {
    doSearch();
  }, [refressSearch, page]);

  useEffect(() => {
    const getItemsRead = async () => {
      try {
        const kq = await axiosi.get("ivrplanmain/getItemsRead", {
          params: { company_id: companyid.value, pageSize: 100 },
        });
        console.log(kq.data.rows);
        setMainReadData(kq.data.rows);
      } catch (err) {
        setMainReadData(null);
        console.log("[useEffect]:getItemsRead has an Error !");
      }
    };
    getItemsRead();
  }, [companyid?.value]);
  async function fetchUserList(company_name) {
    console.log("fetching user", company_name);
    const body = await axiosi.get("companies", {
      params: { company_name, pageSize: 100 },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
    // return axiosi.get('companies',{params:{company_name,pageSize:100}} ) // fetch('https://randomuser.me/api/?results=5')
    //   .then((body) =>
    //     body?.data?.rows.map((user) => ({
    //      label: user.company_name,
    //       value: user.id,
    //     })),
    //   );
  }
  const handleAdd = () => {
    console.log("[handleAdd]:");
    setVisibleAdd(true);
  };
  const onCloseAdd = () => {
    setVisibleAdd(false);
  };
  const handleSearch = async () => {
    console.log("[handleSearch]");
    setRefressSearch((pre) => !pre);
  };
  const doSearch = async () => {
    console.log("[doSearch]");
    try {
      const kq = await axiosi.get("ivrplanforkey", {
        params: {
          page,
          maindialplanid: mainReadSelected,
          company_id: companyid?.value,
        },
      });
      console.log("[handleSearch]:kq.data=", kq.data);
      setData(kq.data);
    } catch (err) {
      console.log("ERROR");
    }
  };
  const handleOnChangeMainRead = (value) => {
    console.log("[handleOnChangeMainRead]:value=", value);
    setMainReadSelected(value);
  };
  return (
    <>
      {/* <Upload/>
  <UploadAntd/> */}
      <Drawer
        title="Add  Menu Item"
        placement="right"
        onClose={onCloseAdd}
        visible={visibleAdd}
      >
        <Add
          setRefressSearch={setRefressSearch}
          setVisibleAdd={setVisibleAdd}
        />
      </Drawer>
      <section className="search">
        <div className="container">
          <div className="row">
            <Typography.Title level={3}>
              Search :
              <Button
                onClick={handleAdd}
                className="addButton"
                icon={<PlusCircleFilled style={{ color: "#1890ff" }} />}
              >
                Add
              </Button>
            </Typography.Title>
          </div>

          <div className="row">
            <div className={hideItem}>
              <label>Company :</label>
              <DebounceSelect
                showSearch={true}
                value={companyid}
                placeholder="Select a company !"
                fetchOptions={fetchUserList}
                onChange={(newValue) => {
                  setCompanyid(newValue);
                }}
                style={{ width: "100%" }}
              />
            </div>
            <div className="col">
              <label>Main Plan IVR :</label>
              {mainReadData && (
                <>
                  <Select
                    style={{ width: "100%" }}
                    onChange={handleOnChangeMainRead}
                    value={mainReadSelected}
                  >
                    <Option value=""></Option>
                    {mainReadData &&
                      mainReadData.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </>
              )}
            </div>

            <div className="col">
              <label>Search:</label>
              <Button onClick={handleSearch}>Search :</Button>
            </div>
          </div>
        </div>
      </section>
      <section className="searchResult">
        <div className="row">
          <Typography.Title level={3}>Result</Typography.Title>
        </div>
        <div className="row">
          {data?.rows && (
            <ShowTable data={data.rows} setRefressSearch={setRefressSearch} />
          )}
        </div>
        <div className="row">
          {data?.pages > 1 ? (
            <PageLink
              pageSize={data.pageSize}
              searchpage={"ivrplanforkey"}
              pages={data.pages}
              numrows={data.numrows}
              page={data.page}
            />
          ) : (
            <h1>chi co' 1 page</h1>
          )}
        </div>
      </section>
    </>
  );
}
