import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Drawer, Input, Space, Form } from "antd";
import {
  DownloadOutlined,
  PlusCircleFilled,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import EditAgents from "./EditAgents";
import DeleteAgents from "./DeleteAgents";

export default function ShowAgentsTable({ data, setRefreshSearch }) {
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleRemove, setVisibleRemove] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);
  const hideItem = useSelector((state) => state.loginReducer.hideItem);

  const handleEdit = (item) => {
    console.log("[handleEdit]:item=", item);
    setSelectedItem(item);
    setVisibleEdit(true);
  };
  const handleRemove = (item) => {
    console.log("[handleRemove]:item=", item);
    setVisibleRemove(true);
    setSelectedDeleteItem(item);
  };

  const onCloseEdit = () => {
    console.log("[onCloseEdit]");
    setSelectedItem(null);
    setVisibleEdit(false);
  };
  const onCloseRemove = () => {
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
  };
  return (
    <>
      <Drawer
        title="Edit"
        placement="right"
        onClose={onCloseEdit}
        visible={visibleEdit}
        width={720}
      >
        {selectedItem && (
          <EditAgents
            item={selectedItem}
            setRefreshSearch={setRefreshSearch}
            setVisibleEdit={setVisibleEdit}
            setSelectedItem={setSelectedItem}
          />
        )}
      </Drawer>

      <Drawer
        title="DELETE"
        placement="right"
        onClose={onCloseRemove}
        visible={visibleRemove}
      >
        {selectedDeleteItem && (
          <DeleteAgents
            item={selectedDeleteItem}
            setRefreshSearch={setRefreshSearch}
            setVisibleRemove={setVisibleRemove}
          />
        )}
      </Drawer>

      <table className="table1">
        <thead>
          <tr>
            {/* <th>id</th> */}
            {/* <th>calldirection</th>
      <th>connected</th> */}

            <th width="220px" className={hideItem}>
              company_name
            </th>
            <th>name</th>
            <th>username</th>
            <th>author_groupname</th>
            <th>endpoint_id</th>
            <th>webrtc</th>

            <th width="120px">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              {/* <td>{item.id}</td> */}
              {/* <td>{item.calldirection}</td>
      <td>{item.connected}</td> */}

              <td className={hideItem}>{item.company_name}</td>
              <td>{item.name}</td>
              <td>{item.username}</td>
              <td>
                <div>
                  <span className="lable">AuthG:</span>
                  <span className="lable_value">{item.author_groupname}</span>
                </div>
                <div>
                  <span className="lable">Dept:</span>
                  <span className="lable_value">
                    {item.department_name}-{item.department_id}
                  </span>
                </div>
                <div>
                  <span className="lable">LogDepts:</span>
                  <span className="lable_value">{item.logdepartments}</span>
                </div>
              </td>
              <td>{item.endpoint_id}</td>
              <td>{item.webrtc}</td>

              <td>
                {/* <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
                <div className="rowFlex">
                  <Button
                    onClick={() => handleEdit(item)}
                    icon={<EditFilled style={{ color: "hotpink" }} />}
                  >
                    Edit
                  </Button>
                  <Button onClick={() => handleRemove(item)}>
                    <DeleteFilled style={{ color: "red" }} />
                    Remove
                  </Button>
                </div>
                {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
    Download
  </Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
