import { Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
function Page({pageSize,pages,page,numrows,searchpage,setPage}){
    const navigate = useNavigate();
    function onChange(pageNumber) {
        console.log('Page: ', pageNumber);
        // navigate(`/calllog/${pageNumber}`);
        // navigate(`/${searchpage}/${pageNumber}`);
        setPage(pageNumber);
      }
    return <>
        <h1>Page : {page}/{pages}</h1>
     
        {/* <h2>defaultCurrent={page} total={pages}</h2> */}
        {/* <Pagination showSizeChanger={false} defaultCurrent={page} total={numrows} pageSize={pageSize} onChange={onChange} /> */}
        <Pagination showSizeChanger={false} current={page} total={numrows} pageSize={pageSize} onChange={onChange} />
    </>
}

export default Page;