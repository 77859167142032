import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  DatePicker,
  Button,
  Drawer,
  Input,
  Space,
  Form,
  Select,
  Spin,
  Checkbox,
} from "antd";
import {
  DownloadOutlined,
  PlusCircleFilled,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";

import moment from "moment";
import debounce from "lodash/debounce";
import useAxios from "./../../../CustomHooks/useAxios";
import DebounceSelect from "./../../DebounceSelect";
const { Option } = Select;
export default function Edit({
  item,
  setSelectedItem,
  setRefressSearch,
  setVisibleEdit,
  uniqueid,
  tkid,
}) {
  const [checkempty_customerid, setCheckempty_customerid] = useState(0);
  const [checkdup_customerid, setCheckdup_customerid] = useState(0);
  const [checkdup_tel, setCheckdup_tel] = useState(1);
  const { axiosi } = useAxios();

  const user = useSelector((state) => state.loginReducer.user);
  const [companyid, setCompanyid] = React.useState({
    value: user.agents[0].company_id,
    label: user.agents[0].gmcomname,
  });
  const [departmentData, setDepartmentData] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState([]);
  useEffect(() => {
    const getDepartmentData = async () => {
      try {
        const kq = await axiosi.get("department", {
          params: { company_id: companyid?.value, pageSize: 100 },
        });
        console.log("[getDepartmentData]:kq.data.rows=", kq.data.rows);
        setDepartmentData(kq.data.rows);
        setDepartmentSelected("");

        // console.log("[getDepartmentData]:kq.data.rows.length=",kq.data.rows.length);
        // console.log("[getDepartmentData]:user.agents[0].logdepartments.length=",user.agents[0].logdepartments.length);
      } catch (err) {
        setDepartmentData(null);
        console.log("[useEffect]:getDepartmentData has an Error !");
      }
    };
    getDepartmentData();
  }, [companyid?.value]);
  function checkLogDep(item) {
    if (user.agents[0].company_id === 1) return true;
    const logdeps = user.agents[0].logdepartments;
    // console.log("[checkLogDep]:logdeps=",logdeps);
    // console.log("[checkLogDep]:item.id=",item.id);
    // console.log("[checkLogDep]:logdeps.includes(item.id)=",logdeps.includes(item.id));
    return logdeps.includes(item.id);
  }
  const handleOnChangeDepartment = (value) => {
    console.log("[handleOnChangeDepartment]:value=", value);
    setDepartmentSelected(value);
  };
  const onFinishAdd = async (values) => {
    let adddata = {
      ...values,
      checkempty_customerid,
      checkdup_customerid,
      checkdup_tel,
    };
    console.log("[onFinishAdd]:values=", adddata);
    try {
      const kq = await axiosi.put(`outboundplan/${item.id}`, adddata);
      console.log("[onFinishAdd]:kq=", kq);
      setVisibleEdit(false);
      setSelectedItem(null);

      if (tkid) {
        try {
          console.log("[ticketnote/update/updateCustomerToTicket]:tkid=", tkid);
          const kq = await axiosi.put(
            `ticketnote/update/updateCustomerToTicket`,
            {
              ccomname: values.ccomname,
              name: values.name,
              tel: values.tel,
              email: values.email,
              comcontacts_id: item.id,

              id: tkid,
            }
          );
        } catch (error) {
          console.log("ticketnote/updateCustomerToTicket: error");
        }
      } else if (uniqueid) {
        try {
          const kq = await axiosi.put("calllogs/updateCustomerToCDR", {
            customer_company: values.ccomname,
            customer_name: values.name,
            customer_id: item.id,
            uniqueid,
          });
          // setRefressSearchCallLog(pre=>!pre);
        } catch {
          console.log("[handleEdit]:calllogs/updateCustomerToCDR : errr");
        }
      }

      setRefressSearch((pre) => !pre);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error.response.data:", error.response.data);
        console.log("error.response.status:", error.response.status);
        console.log("error.response.headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("error.request :", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error error.message", error.message);
      }
      console.log("error.config:", error.config);
    }
    return;
  };
  const onFinishFailedAdd = (error) => {
    console.log("[onFinishFailedAdd]:error=", error);
  };
  const onChangeCustomerId_empty = (e) => {
    console.log(`checked_CustomerId_empty = ${e.target.checked}`);
    setCheckempty_customerid(e.target.checked);
  };
  const onChangeCustomerId_dup = (e) => {
    console.log(`checked_CustomerId_dup = ${e.target.checked}`);
    setCheckdup_customerid(e.target.checked);
  };
  const onChangeTel_dup = (e) => {
    console.log(`checked_Tel_dup = ${e.target.checked}`);
    setCheckdup_tel(e.target.checked);
  };
  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        initialValues={{
          ...item,
          // department_ids: JSON.parse(item?.department_ids),
          dob: moment(moment(item.dob).format("YYYY-MM-DD"), "YYYY-MM-DD"),
        }}
        onFinish={onFinishAdd}
        onFinishFailed={onFinishFailedAdd}
        autoComplete="off"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input a Name!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="departments"
              name="department_id"
              rules={[
                { required: false, message: "Please select the departments!" },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleOnChangeDepartment}
                value={departmentSelected}
                // mode="multiple"
              >
                <Option value=""></Option>
                {departmentData &&
                  departmentData.map((item) => {
                    if (checkLogDep(item)) {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    }
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Enable Empty CustomerId"
              name="checkempty_customerid"
            >
              <Checkbox onChange={onChangeCustomerId_empty}></Checkbox>
            </Form.Item>
            <Form.Item
              label="Enable Duplicate CustomerId"
              name="checkdup_customerid"
            >
              <Checkbox onChange={onChangeCustomerId_dup}></Checkbox>
            </Form.Item>
            <Form.Item
              label="Enable Duplicate Tel"
              valuePropName="checked"
              name="checkdup_tel"
            >
              <Checkbox onChange={onChangeTel_dup}></Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="note"
              name="note"
              rules={[
                { required: false, message: "Please input a priority_level!" },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>{" "}
          </Col>
        </Row>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
