import {useState,useEffect} from 'react';
import {Button,Drawer,Input,Space,Form,Select,message} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import useAxios from './../../CustomHooks/useAxios';
const { Option } = Select;

export default function EditCompanies({item,setSelectedItem,setVisibleEdit,setRefreshSearch}) {
  const {axiosi}=useAxios();
  const onFinishAdd=async (values)=>{
      console.log("[onFinishAdd]:values=",values);
      try {
          const kq=await axiosi.put(`companies/${item.id}`,values);
          console.log("[onFinishAdd]:kq=",kq) ;
          setVisibleEdit(false);
            setSelectedItem(null);
            setRefreshSearch(pre=>!pre);
      } catch (error){
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              message.error(`Error: ${error.response.data.code}`);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
      }
  }
  const onFinishFailedAdd=(error)=>{
      console.log("[onFinishFailedAdd]:error=",error);
  }
return (<>
      <Form
    name="basic"
    labelCol={{ span: 9 }}
    wrapperCol={{ span: 15 }}
    initialValues={item}
    onFinish={onFinishAdd}
    onFinishFailed={onFinishFailedAdd}
    autoComplete="off"
  >
      <Form.Item
      label="companyName"
      name="company_name"
      rules={[{ required: true, message: 'Please input a company_name!' }]}
    >
      <Input  />
    </Form.Item>

    <Form.Item
      label="contactName"
      name="contactname"
      rules={[{ required: true, message: 'Please input a contactname!' }]}
    >
      <Input  />
    </Form.Item>

    <Form.Item
      label="contactTel"
      name="contacttel"
      rules={[{ required: true, message: 'Please input a contacttel!' }]}
    >
      <Input  />
    </Form.Item>

    <Form.Item
      label="contactEmail"
      name="contactemail"
      // rules={[{ required: true, message: 'Please input a contactemail!' }]}
    >
      <Input  />
    </Form.Item>
    

    <Form.Item
      label="tax"
      name="tax"
      // rules={[{ required: true, message: 'Please input a tax!' }]}
    >
      <Input  />
    </Form.Item>

    <Form.Item
      label="address"
      name="address"
      // rules={[{ required: true, message: 'Please input a address!' }]}
    >
      <Input  />
    </Form.Item>
    <Form.Item
      label="Max Agents"
      name="numagents"
      // rules={[{ required: true, message: 'Please input a address!' }]}
    >
      <Input type="number" min={1} max={1000}  />
    </Form.Item>

    <Form.Item style={{display:"none"}}
      label="dcontext"
      name="dcontext"
      // rules={[{ required: true, message: 'Please input a dcontext!' }]}
    >
      <Input  />
    </Form.Item>
    

    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
      <Button type="primary" htmlType="submit">
        Save
      </Button>
    </Form.Item>
      </Form>
</>);
}
