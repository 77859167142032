import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Drawer, Button, Typography, Input } from "antd";
import { PlusCircleFilled, SaveOutlined } from "@ant-design/icons";

import useAxios from "./../../../CustomHooks/useAxios";
import ShowTable from "./ShowTable";
import PageLink from "./../../PageLink/PageLink";
import Add from "./Add";
import UploadFile from "./UploadFile";
import DebounceSelect from "./../../DebounceSelect";

export default function OutboundPlan() {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const user = useSelector((state) => state.loginReducer.user);
  let { page } = useParams();
  const { axiosi } = useAxios();

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [data, setData] = useState();

  const [companyid, setCompanyid] = React.useState({
    value: user.agents[0].company_id,
    label: user.agents[0].gmcomname,
  });
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");

  const [refressSearch, setRefressSearch] = useState(true);

  useEffect(() => {
    doSearch();
  }, [refressSearch, page]);
  async function fetchUserList(company_name) {
    console.log("fetching user", company_name);
    const body = await axiosi.get("companies", {
      params: { company_name, pageSize: 100 },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
  }
  const handleAdd = () => {
    console.log("[handleAdd]:");
    setVisibleAdd(true);
  };

  const onCloseAdd = () => {
    setVisibleAdd(false);
  };

  const handleSearch = async () => {
    console.log("[handleSearch]");
    setRefressSearch((pre) => !pre);
  };
  const handleExport = async () => {
    console.log("[handleExport1]");
    try {
      const kq = await axiosi.get("comcontacts/export", {
        params: {
          pageSize: 2000,
          page,
          company_id: companyid?.value,
          tel,
          name,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([kq.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `exportContacts.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.log("ERROR");
    }
  };
  const doSearch = async () => {
    console.log("[doSearch]");
    try {
      const kq = await axiosi.get("znsplan", {
        params: { page, company_id: companyid?.value, tel, name },
      });
      console.log("[handleSearch]:kq.data=", kq.data);
      setData(kq.data);
    } catch (err) {
      console.log("ERROR");
    }
  };
  return (
    <>
      <Drawer
        title="Add  Menu Item"
        width={640}
        placement="right"
        onClose={onCloseAdd}
        visible={visibleAdd}
      >
        <Add
          setRefressSearch={setRefressSearch}
          setVisibleAdd={setVisibleAdd}
        />
      </Drawer>

      <section className="search">
        <div className="container">
          <div className="row">
            <Typography.Title level={3}>Search :</Typography.Title>

            <div className="addButton2">
              <Button
                onClick={handleAdd}
                icon={<PlusCircleFilled style={{ color: "#1890ff" }} />}
              >
                Add
              </Button>
            </div>
          </div>

          <div className="row">
            <div className={hideItem}>
              <label>GM's Company :</label>
              <DebounceSelect
                showSearch={true}
                value={companyid}
                placeholder="Select a company !"
                fetchOptions={fetchUserList}
                onChange={(newValue) => {
                  setCompanyid(newValue);
                }}
                style={{ width: "100%" }}
              />
            </div>

            <div className="col">
              <label>Name:</label>
              <Input onChange={({ target: { value } }) => setName(value)} />
            </div>

            <div className="col">
              <label>Search:</label>
              <Button onClick={handleSearch}>Search</Button>
            </div>

            {/*<div className="col">
              <label>Export:</label>
              <Button onClick={handleExport}>Export</Button>
              </div>*/}
          </div>
        </div>
      </section>
      <section className="searchResult">
        <div className="row">
          <Typography.Title level={3}>Result</Typography.Title>
        </div>
        <div className="row">
          {data?.rows && (
            <ShowTable data={data.rows} setRefressSearch={setRefressSearch} />
          )}
        </div>
        <div className="row">
          {data?.pages > 1 ? (
            <PageLink
              pageSize={data.pageSize}
              searchpage={"comcontacts"}
              pages={data.pages}
              numrows={data.numrows}
              page={data.page}
            />
          ) : (
            <h1>chi co' 1 page</h1>
          )}
        </div>
      </section>
    </>
  );
}
