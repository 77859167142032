import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
export default function CallInAndAbandonedColumnChart({ sumAbandoned }) {
  const [data, setData] = useState([]);
  const config = {
    data,
    isGroup: true,
    xField: "xValue",
    yField: "yValue",
    seriesField: "name",

    /** Đặt màu */
    //color: ['#1ca9e6', '#f88c24'],

    /** Đặt khoảng cách */
    // marginRatio: 0.1,
    label: {
      // Vị trí nhãn dữ liệu nhãn có thể được cấu hình thủ công
      position: "middle",
      // 'top', 'middle', 'bottom'
      // Các phương pháp bố trí bổ sung có thể định cấu hình
      layout: [
        // Vị trí nhãn dữ liệu biểu đồ cột được điều chỉnh tự động
        {
          type: "interval-adjust-position",
        }, // Nhãn dữ liệu chống tắc nghẽn
        {
          type: "interval-hide-overlap",
        }, // Màu văn bản nhãn dữ liệu được điều chỉnh tự động
        {
          type: "adjust-color",
        },
      ],
    },
  };
  useEffect(() => setData(sumAbandoned), [sumAbandoned]);
  return (
    <>
      {
        <div className="DashboardDisplay">
          <h2 className="gachduoi">Callin and Abandoned Call Chart</h2>
          <Column {...config} />
        </div>
      }
    </>
  );
}
