import React from "react";
import { useSelector } from "react-redux";

import { PhoneOutlined } from "@ant-design/icons";

export default function ShowTable({ data }) {
  const makeCall = useSelector((state) => state.jsSIPReducer.makeCall);

  const handleMakeCall = (callto) => {
    makeCall(callto);
  };
  return (
    <>
      <table className="table1">
        <thead>
          <tr>
            <th width="220px">GM's Customer</th>
            <th>company_name</th>
            <th>name</th>
            <th>tel</th>
            <th>gender</th>
            <th>note</th>

            <th width="120px">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.company_name}</td>
              <td>{item.ccomname}</td>
              <td>{item.name}</td>
              <td>
                <span
                  className="stitle"
                  title="Tel, Click here to make a call!"
                  onClick={() => handleMakeCall(item.tel)}
                >
                  <PhoneOutlined className={"icon"} />
                </span>
                <span className="svalue">{item.tel}</span>
                <br />
              </td>
              <td>{item.gender}</td>
              <td>{item.note}</td>

              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
