import React,{useEffect,useState,useRef} from 'react'
import { useDispatch, useSelector} from "react-redux";
import { useParams ,Link} from "react-router-dom";
import moment from 'moment';
import { Drawer,Form,Button,Row, Col,Divider,Typography ,DatePicker,Select,Input,message } from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
import useAxios from './../../CustomHooks/useAxios';
import ShowAuthorizationGroupItemTable from './ShowAuthorizationGroupItemTable';
import PageLink from './../PageLink/PageLink';

import AddAuthorizationGroupItem from "./AddAuthorizationGroupItem";
const { Text } = Typography;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
export default function AuthorizationGroupItem(){
    let { page } = useParams();
    const {axiosi}=useAxios();

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [data,setData]=useState();
    const [refreshSearch,setRefreshSearch]=useState(true);


    const [authorization_groups,setAuthorization_groups]=useState(null);
    const [authorization_groupsLoading,setAuthorization_groupsLoading]=useState(true);
    
    const [authorization_group_id,setAuthorization_group_id]=useState("");
    const [menu_sub_id,setMenu_sub_id]=useState("");
    // const [name,setName]=useState("");
    useEffect(()=>{
        const getAuthorizationGroups=async()=>{
            const kq=await axiosi.get('authorization_groups',{params:{pageSize:200}});
            console.log("[MenuItem-useEffect]:kq.data=",kq.data)
            setAuthorization_groups(kq.data);
            setAuthorization_groupsLoading(false);
        }
        getAuthorizationGroups();
    },[])

    const [menuSubLoading,setMenuSubLoading]=useState(true);
    const [menuSubData,setMenuSubData]=useState(null);

    useEffect(()=>{
        const getMenuSub=async()=>{
            // message.info("Loading....");
            const kq=await axiosi.get('menu_sub',{params:{pageSize:200}});
            console.log("[MenuItem-useEffect]:kq.data=",kq.data)
            setMenuSubData(kq.data);
            setMenuSubLoading(false);
        }
        getMenuSub();
    },[])
    const handleAdd=()=>{
        console.log("[handleAdd]:");
        setVisibleAdd(true);
    }
    const onCloseAdd=()=>{
        setVisibleAdd(false);
        
    }

    useEffect(()=>{
        doSearch();
    },[refreshSearch,page])
    
        const handleSearch=(value)=>{
            // setName(value);
            setRefreshSearch(pre=>!pre);
        }

    const doSearch= async()=>{
        console.log("[search]");
        const kq=await axiosi.get('authorization_group_items',{params:{page,pageSize:20,authorization_group_id,menu_sub_id}});
        console.log("[handleSearch]:kq.data=",kq.data);
        setData(kq.data);
    }
    return (<>
     
            <Drawer title="Add  Menu Item" placement="right" onClose={onCloseAdd} visible={visibleAdd}>             
                    <AddAuthorizationGroupItem  
                    authorization_groups={authorization_groups} 
                    authorization_groupsLoading={authorization_groupsLoading}
                    menuSubData={menuSubData}
                    menuSubLoading={menuSubLoading}
                    setVisibleAdd={setVisibleAdd} setRefreshSearch={setRefreshSearch}
                    />        
            </Drawer>
        <section className='search'>
        <div className="container">
            <div className="row">
                <Typography.Title  level={3}>Search :
                <Button onClick={handleAdd}  className="addButton" icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button>
                </Typography.Title>
                
            </div>
            
            <div className="row">
           
                <div className="col">
                    <label>Authorization Group  Name :</label>                    
                    <Select style={{ width: '100%' }} 
                    onChange={(value)=>setAuthorization_group_id(value)}
                    loading={authorization_groupsLoading}
                    
                    >
                        <Option value=""></Option>
                       { authorization_groups?.rows && authorization_groups.rows.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
                    </Select>
                </div>
                <div className="col">
                    <label>Sub-Menu :</label>                    
                    <Select style={{ width: '100%' }} 
                    onChange={(value)=>setMenu_sub_id(value)}
                    loading={menuSubLoading}
                    
                    >
                        <Option value=""></Option>
                       { menuSubData?.rows && menuSubData.rows.map(item=>(
                           <Option key={item.id} value={item.id}>{item.title}</Option>
                       ))}                       
                    </Select>
                </div>
                <div className="col">
                    <label>Search:</label>
                    <Button onClick={handleSearch}>Search :</Button>
                </div>
                
            </div>
        </div>

    </section>
    <section className='searchResult'>
        <div className="row">
        <Typography.Title  level={3}>Result</Typography.Title>
        </div>
        <div className="row">
            {data?.rows && <ShowAuthorizationGroupItemTable data={data.rows}
            authorization_groups={authorization_groups} 
            authorization_groupsLoading={authorization_groupsLoading}
            menuSubData={menuSubData}
            menuSubLoading={menuSubLoading}
            setRefreshSearch={setRefreshSearch}
            />}
        </div>
        <Row>
              <Col span={24}>
                {data?.pages>1?<PageLink pageSize={data.pageSize} searchpage={"authorization_group_item"} pages={data.pages} numrows={data.numrows} page={data.page} />:<h1>chi co' 1 page</h1>}
              </Col>
            </Row>

    </section>
    </>)
}