import React,{useState} from "react";
import { Col, Row, Table, Tag, Space, Button, Typography, Drawer } from "antd";

import moment from "moment";

export default function ShowSummary({ data }) {
  const [pickupSecond, setPickupSecond] = useState(process.env.REACT_APP_PICKUP_SECOND);
  const formatTime = (second) => {
    // return("second");

    const b = 86400;
    // second += b*22;
    if (second >= b) {
      let giay = second % b;
      let ngay = (second - giay) / b;
      //new Date(3600*24 * 1000).toISOString().substr(11, 8)
      return (
        `${ngay}d.` + new Date(second * 1000).toISOString().substring(11, 19)
      );
      //${moment(giay).format("HH:mm:ss")}`;
      //+ new Date(giay * 1000).toISOString().substr(11, 8);
      //${moment(giay).format(dateFormat)}`;
    } else {
      return new Date(second * 1000).toISOString().substring(11, 19); //
      // return `${moment(12).format("HH:mm:ss")}`;
    }
    //return `${moment(second).format(dateFormat)}`;
    //new Date(giay * 1000).toISOString().substr(11, 8);//
  };
  return (
    <>
      <Typography.Title level={3}>Summary</Typography.Title>
      {data.callLogsSumALl.map((item) => {
        console.table(item);
        return (
          <>
            {" "}
            <Row>
              <Col span={12}>
                <h2>Inbound</h2>
                <div>
                  <span className="sumLabel">- Total</span>:
                  <span className="sumValue">{item.number_incalls}</span>
                </div>
                <div>
                  <span className="sumLabel">- Answered</span>:
                  <span className="sumValue">{item.answered_calls}</span>
                </div>
                <div>
                  <span className="sumLabel">+ total_talktime</span>:
                  <span className="sumValue">
                    {formatTime(item.total_talktime)}
                  </span>
                </div>

                <div>
                  <span className="sumLabel">+ average_talktime</span>:
                  <span className="sumValue">
                    {formatTime(item.average_talktime)}
                  </span>
                </div>

                <div>
                  <span className="sumLabel">+ avg_pickup</span>:
                  <span className="sumValue">
                    {formatTime(item.average_queue_pickup)}
                  </span>
                </div>

                <div>
                  <span className="sumLabel">+ queue_pickup [≤{pickupSecond}s]</span>:
                  <span className="sumValue">
                    {item.queue_pickup_less_eq7s}
                  </span>
                </div>
                {/* <div>
                  <span className="sumLabel">+ queue_pickup_greater7s</span>:
                  <span className="sumValue">
                    {item.queue_pickup_greater7s}
                  </span>
                </div> */}
                <div>- Missed</div>
                <div>
                  <span className="sumLabel">+ hangup_before</span>:
                  <span className="sumValue">{item.hangup_before_calls}</span>
                </div>
                <div>
                  <span className="sumLabel">+ missed_inqueue_calls</span>:
                  <span className="sumValue">{item.missed_inqueue_calls}</span>
                </div>
                <div>
                  <span className="sumLabel">+ missed_noagentpickup_calls</span>
                  :
                  <span className="sumValue">
                    {item.missed_noagentpickup_calls}
                  </span>
                </div>
                <div>- Abandoned Call</div>
                <div>
                  <span className="sumLabel">+ Abandoned call</span>:
                  <span className="sumValue">{item.abandoned_calls}</span>
                </div>
                <div>
                  <span className="sumLabel">+ Abandoned call rate</span>:
                  <span className="sumValue">
                    {(
                      (item.abandoned_calls * 100) /
                      item.number_incalls
                    ).toFixed(2)}{" "}
                    %
                  </span>
                </div>
              </Col>
              <Col span={12}>
                <h2>Outbound Call</h2>
                <div>
                  <span className="sumLabel">- Total</span>:
                  <span className="sumValue">{item.number_outcalls}</span>
                </div>
                <div>
                  <span className="sumLabel">+ Connected</span>:
                  <span className="sumValue">{item.out_connected}</span>
                </div>
                <div>
                  <span className="sumLabel">+ Average talktime</span>:
                  <span className="sumValue">
                    {formatTime(item.average_talktime_out)}
                  </span>
                </div>
                <div>
                  <span className="sumLabel">+ NotConnected</span>:
                  <span className="sumValue">{item.out_notconnected}</span>
                </div>
              </Col>
            </Row>
          </>
        );
      })}
      <Typography.Title level={3}> Agents's Summary Call</Typography.Title>
      <table className="table1">
        <thead>
          <tr>
            <th rowspan="2" width="220px">
              Agent
            </th>
            <th colspan="7">Inbound</th>
            <th colspan="3">Outbound</th>
          </tr>
          <tr>
            <th width="120px">Not connected</th>
            <th width="120px">Missed</th>
            <th width="120px">Missed_Cancel</th>
            <th width="80px">Connected</th>
            {/* <th width="80px">Pickup</th> */}
            <th width="80px">AvgPickup</th>
            <th width="80px">Pickup [≤{pickupSecond}s]</th>
            {/* <th width="80px">Talktime</th> */}
            <th width="80px">AvgTalktime</th>
            {/* <th width="80px">AVG (Talktime) </th> */}

            <th width="120px">Not connected</th>
            <th width="80px">Connected</th>
            <th width="80px">AvgTalktime</th>
            {/* <th width="80px">AVG (Talktime) </th>                      */}
          </tr>
          {/* <tr>            
            <th width="520px">Agent</th>
            
            <th >inNotConnected</th>
            <th width="120px">inConnected</th>
            <th width="120px">inConnectedTalkTime</th>

            <th >outNotConnected</th>
            <th width="120px">outConnected</th>
            <th width="120px">outConnectedTalkTime</th>
        </tr> */}
        </thead>
        <tbody>
          {data.agentCallLogsSum.map(
            (item) =>
              item.ext != "" && (
                <tr key={item.ext}>
                  <td>
                    [{item.ext} - {item.agentname}] {console.log(item)}
                  </td>
                  <td> {item.inNotConnected} </td>
                  <td> {item.inMissed} </td>
                  <td> {item.inMissedCancel} </td>
                  <td> {item.inConnected} </td>
                  {/* <td>
                    {" "}
                    {item.inConnectedPickup != 0
                      ? formatTime(item.inConnectedPickup)
                      : ""}{" "}
                  </td> */}
                  <td>
                    {" "}
                    {item.inConnected != 0
                      ? formatTime(item.inConnectedPickup / item.inConnected)
                      : ""}{" "}
                  </td>
                  <td> {item.pickup_less_eq7s} </td>
                  {/* <td>
                    {" "}
                    {item.inConnectedTalkTime != 0
                      ? formatTime(item.inConnectedTalkTime)
                      : ""}{" "}
                  </td> */}
                  <td>
                    {" "}
                    {item.inConnected != 0
                      ? formatTime(item.inConnectedTalkTime / item.inConnected)
                      : ""}{" "}
                  </td>

                  <td> {item.outNotConnected} </td>
                  <td> {item.outConnected} </td>
                  <td>
                    {/* {" "}
                    {formatTime(item.outConnectedTalkTime)}{" "} */}
                    {item.outConnected != 0
                      ? formatTime(
                          item.outConnectedTalkTime / item.outConnected
                        )
                      : ""}{" "}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </>
  );
}
