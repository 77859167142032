import React from 'react'
import data from '../../../src/data'
export default function Home() {
    return (
        <div>
            {/* <h3>Home</h3>
            <div>{data.content}</div>
            <div>{data.content}</div>
            <div>{data.content}</div>
            <div>{data.content}</div> */}
        </div>
    )
}
