import {useState,useEffect} from 'react';
import {Button,Drawer,Input,Space,Form,Select} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import useAxios from './../../CustomHooks/useAxios';
const { Option } = Select;
export default function EditMenuItem({selectedItem,setSelectedItem,setVisibleEdit,setRefreshSearch,menuSubData,menuSubLoading,setMenuSubLoading}){
    console.log("[EditMenuItem]:selectedItem=",selectedItem)
    const {axiosi}=useAxios();
    const [menu_sub_id,setMenu_sub_id]=useState("");
    const onFinishEdit=async (values)=>{
        console.log("[onFinishAdd]:values=",values);
        try {
            const kq=await axiosi.put(`menu_item/${selectedItem.id}`,values);
            console.log("[onFinishAdd]:kq=",kq) ;
            setVisibleEdit(false);
            setSelectedItem(null)
            setRefreshSearch(pre=>!pre);

        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
        }
    }
    const onFinishFailedEdit=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }
    return(<>
        
        <Form 
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={
                { menu_sub_id: selectedItem.menu_sub_id ,
                    item_key:selectedItem.item_key,
                    item_to:selectedItem.item_to,
                    item_name:selectedItem.item_name}}
            onFinish={onFinishEdit}
            onFinishFailed={onFinishFailedEdit}
            autoComplete="off"
        >
             <Form.Item
                label="menu_sub_id"
                name="menu_sub_id"
                rules={[{ required: true, message: 'Please input a menu_sub_id!' }]}
             >
                <Select defaultValue=""  style={{ width: "100%" }} 
                        loading={menuSubLoading}
                        onChange={(value)=>setMenu_sub_id(value)}
                >
                        <Option value=""></Option>
                        {menuSubData? menuSubData?.rows?.map(item=>(
                            <Option value={item.id}>{item.title}</Option>
                        )):""}
                        
                </Select>
            </Form.Item>

      <Form.Item
        label="item_key"
        name="item_key"
        rules={[{ required: true, message: 'Please input a item_key!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="item_to"
        name="item_to"
        rules={[{ required: true, message: 'Please input a item_to!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="item_name"
        name="item_name"
        rules={[{ required: true, message: 'Please input a item_name!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save Update
        </Button>
      </Form.Item>
        </Form>
    </>)
}