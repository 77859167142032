import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Input, notification, Select, Form } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";

import useAxios from "./../../../CustomHooks/useAxios";
import UploadFileStatus from "./UploadFileStatus";
export default function UploadFile({ setRefressSearch, setVisibleAdd }) {
  const { axiosi } = useAxios();
  const user = useSelector((state) => state.loginReducer.user);

  const [file, setFile] = useState();
  const [upload_id, setUpload_id] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type,
    message,
    description,
    duration,
    placement
  ) => {
    api[type]({ message, description, duration, placement });
  };
  const [outboundplans, setOutboundplans] = useState();
  useEffect(() => {
    doSearch();
  }, []);
  const doSearch = async () => {
    console.log("[doSearch]");
    try {
      const kq = await axiosi.get("outboundplan", {
        params: {
          page: 1,
          pageSize: 200,
          company_id: user?.agents[0]["company_id"],
        },
      });
      console.log("[setOutboundplans]:kq.data=", kq.data);
      setOutboundplans(kq.data);
    } catch (err) {
      console.log("ERROR");
    }
  };

  const handleUpload = async () => {
    console.log("handleUpload");
    const data = new FormData();
    data.append("file", file);
    const kq = await axiosi.post("outbounddata/importExcel_UploadStatus", data);
    console.log(kq);
    setRefressSearch((pre) => !pre);
    setVisibleAdd(false);
    openNotificationWithIcon(
      "success",
      "Successfully",
      "Upload data is successfully!",
      2,
      "bottomRight"
    );
  };

  const onFinishAdd = async (values) => {
    console.log("[onFinishAdd]:values=", values);
    // return;

    let adddata = { ...values };
    const data = new FormData();
    data.append("file", file);
    data.append("adddata", JSON.stringify(adddata));
    try {
      const kq = await axiosi.post(
        "outbounddata/importExcel_UploadStatus",
        data
      );
      console.log("[onFinishAdd]:kq=", kq);
      setRefressSearch((pre) => !pre);
      setUpload_id(kq.data.rows.insertId);
      // setVisibleAdd(false);
      openNotificationWithIcon(
        "success",
        "Successfully",
        "Upload file is successfully! And the data is processing!",
        2,
        "bottomRight"
      );
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error.response.data:", error.response.data);
        console.log("error.response.status:", error.response.status);
        console.log("error.response.headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("error.request :", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error error.message", error.message);
      }
      console.log("error.config:", error.config);
    }
  };
  const onFinishFailedAdd = (error) => {
    console.log("[onFinishFailedAdd]:error=", error);
  };
  return (
    <>
      {contextHolder}
      <Form
        name="basic"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        initialValues={{ ordnum: 0 }}
        onFinish={onFinishAdd}
        onFinishFailed={onFinishFailedAdd}
        autoComplete="off"
      >
        <Form.Item
          label="Upload File :"
          name="file1"
          rules={[
            { required: true, message: "Please select an upload file !" },
          ]}
        >
          <Input
            type="file"
            allowClear
            onChange={(e) => setFile(e.target.files[0])}
          />
        </Form.Item>
        <Form.Item
          label="OutboundPlan :"
          name="outboundplan_id"
          rules={[
            { required: true, message: "Please select an OutboundPlan!" },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            // onChange={handleOnChangeDepartment}
            // value={departmentSelected}
          >
            <Select.Option value=""></Select.Option>
            {outboundplans?.rows.map((obplan) => {
              return (
                <Select.Option key={obplan.id} value={obplan.id}>
                  {obplan.name}
                </Select.Option>
              );
            })}{" "}
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<CloudUploadOutlined style={{ color: "#1890ff" }} />}
          >
            Upload
          </Button>
        </Form.Item>
      </Form>
      {/* <div>UploadStatus</div> */}
      <UploadFileStatus upload_id={upload_id} />
      {/* <div>UploadFile</div> */}
      {/* <Input
        type="file"
        allowClear
        onChange={(e) => setFile(e.target.files[0])}
      /> */}
      {/* <div>Outboundplan</div> */}
      {/* <Input /> */}
      {/* <Select
        style={{ width: "100%" }}
        // onChange={handleOnChangeDepartment}
        // value={departmentSelected}
      >
        <Select.Option value=""></Select.Option>
        {user?.agents[0].logdepartments.map((depid) => {
          return (
            <Select.Option key={depid} value={depid}>
              {user?.deps[depid]}
            </Select.Option>
          );
        })}
        
      </Select> */}
      {/* <Button
        onClick={handleUpload}
        className="addButtonkk"
        icon={<CloudUploadOutlined style={{ color: "#1890ff" }} />}
      >
        Add
      </Button> */}
      {/* <Button onClick={handleNotification}>open Notification</Button> */}
    </>
  );
}
