import React,{useState,useEffect} from 'react';
import { useSelector} from "react-redux";
import {Button,Drawer,Input,Space,Form,Select,Spin} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import debounce from 'lodash/debounce';
import useAxios from './../../../CustomHooks/useAxios';
import DebounceSelect from './../../DebounceSelect'
const { Option } = Select;
const { TextArea } = Input;
export default function Add({setRefressSearch,setVisibleAdd}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const user = useSelector((state) => state.loginReducer.user);
  const {axiosi}=useAxios();
  
  
    const [companyid, setCompanyid] = React.useState({value:user.agents[0].company_id,label:user.agents[0].gmcomname});
    const [exten,setExten]=useState([]);
    
    const [forwardTo,setForwardTo]=useState('');

    const [telsoutData,setTelsoutData]=useState(null);
    

    const [telout,setTelout]=useState("");

    
    const [nextMainDialPlainSelectedid,setNextMainDialPlainSelectedid]=useState('');

    




   async function fetchUserList(company_name) {        
        console.log('fetching user', company_name);
        const body=await axiosi.get('companies',{params:{company_name,pageSize:100}} );
        if (body?.data?.rows) {
            return body?.data?.rows.map((user) => ({
                label: user.company_name,
                 value: user.id,
                //  value:{id: user.id,company_code:user.company_code},
                 company_code:user.company_code,
               }));
        }
        else {
            const a=[{label:"---no data---",value:""}]
            return a.map(i=> (i))
        }
       
    }

   
    
    const onFinishAdd=async (values)=>{
        
        let adddata={...values,company_id:companyid.value   };
        try {
            const kq=await axiosi.post("blacklist",adddata);
            console.log("[onFinishAdd]:kq=",kq) 
            setVisibleAdd(false);
            setRefressSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error.response.data:",error.response.data);
                console.log("error.response.status:",error.response.status);
                console.log("error.response.headers:",error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request :",error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error error.message', error.message);
              }
              console.log("error.config:",error.config);
        }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }

    
    const handleOnChangeCompanyName=async(value)=>{
          setCompanyid(value);
          const kq= await axiosi.get('tels',{params:{companyid:value.value,pageSize:100}});
          console.log("[handleOnChangeCompanyName]:kq_get_tels=",kq);
          console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
          setTelsoutData(kq.data);
          setTelout("");
    }
  

  
  
    
  return (<>
  
        <Form
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      initialValues={{ordnum:0 }}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
      

        <Form.Item className={hideItem}
        label="Company Name"
        // name="company_name"
        rules={[{ required: (hideItem!=="hideItem"), message: 'Please input a company_name!' }]}
      >
        <DebounceSelect
          showSearch={true}
          value={companyid}
          placeholder="Select users"
          fetchOptions={fetchUserList}
          onChange={handleOnChangeCompanyName}          
          style={{  width: '100%', }}
        />
      </Form.Item>

      <Form.Item
        label="Tel :"
        name="tel"
        rules={[{ required: true, message: 'Please input a tel!' }]}
      >
        <Input  />        
      </Form.Item>
      
      
      

      <Form.Item
        label="note :"
        name="note"
        rules={[{ required: false, message: 'Please input an note!' }]}
        
      >
        <TextArea />
      
      </Form.Item>

      

  
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
  </>);
}
