import React,{useState,useRef} from 'react';
import { useSelector} from "react-redux";
import {Button,Drawer} from 'antd';

import axios from 'axios';

import moment from 'moment';
import {StepForwardOutlined, DownloadOutlined,RollbackOutlined,EditFilled ,DeleteFilled} from '@ant-design/icons';


import Edit from "./Edit";
import Delete from "./Delete";
import useAxios from './../../../CustomHooks/useAxios';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';
export default function ShowTableSum({data,setRefressSearch}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const {axiosi}=useAxios();
  const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleRemove, setVisibleRemove] = useState(false);
    
    const [selectedItem,setSelectedItem]=useState(null);
    const [selectedDeleteItem,setSelectedDeleteItem]=useState(null);
    const refAudio = useRef();
    const [displayAudio,setDisplayAudio]=useState("none");
    const handlePlayAudio=async(id,uniqueid)=>{
        // refAudio.current.pause();
        setDisplayAudio("block");
        const kq=await axiosi.post('users/getFileLink',{id,uniqueid});
        // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
        refAudio.current.src=`https://gcall.com.vn/gcalldev/surveydata/fileLink?fileLink=${kq.data.fileLink}`
        refAudio.current.play();
    }
    const handleDownloadAudio=async(id,uniqueid,name)=>{
      // refAudio.current.pause();
      const kq=await axiosi.post('users/getFileLink',{id,uniqueid});
      // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
      const linkfile=`https://gcall.com.vn/gcalldev/surveydata/fileLink?fileLink=${kq.data.fileLink}`
      // window.open(linkfile);
      axios({
          url: linkfile, //your url
          method: 'GET',
          responseType: 'blob', // important
      }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${uniqueid}_${id}_${name}.wav`); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
      });
  }
    const handleEdit=(item)=>{
      console.log("[handleEdit]:item=",item);
      setSelectedItem(item);
      setVisibleEdit(true);
  }
  const handleRemove=(item)=>{
      console.log("[handleRemove]:item=",item);
      setVisibleRemove(true);
      setSelectedDeleteItem(item);
  }

  const onCloseEdit=()=>{
    console.log("[onCloseEdit]")
    setSelectedItem(null);
    setVisibleEdit(false);
}
  const onCloseRemove=()=>{
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
}
  const handleReset=async(item)=>{
    console.log("[handleReset]:item=",item);
    try {
      const kq=await axiosi.put(`surveydata/${item.id}/reset`,{a:1});
      console.log("[handleReset]:kq=",kq) ;
      
      
      setRefressSearch(pre=>!pre);
  } catch (error){
    console.log("[handleReset]:error=",error);
  }

  }
  const rdCallResult=(item)=>{
    if (item.callresult===1) {
      return "Success";
    } else if (item.numcalls>=item.allownumcall && item.callstatus===0 ){
      return "Failed";
    } else {
      return "";
    }

  }
  const rdCallStatus=(item)=>{
    if (item.callstatus===1) {
      return "Processing";
 
    } else {
      return "";
    }

  }
return (
  <>
  
      <Drawer title="Edit  Menu Item" placement="right" onClose={onCloseEdit} visible={visibleEdit}>
          {selectedItem && <Edit item={selectedItem} setSelectedItem={setSelectedItem} setRefressSearch={setRefressSearch} setVisibleEdit={setVisibleEdit} />}        
      </Drawer>

      <Drawer title="Remove  Menu Item" placement="right" onClose={onCloseRemove} visible={visibleRemove}>
          {selectedDeleteItem && <Delete item={selectedDeleteItem} setRefressSearch={setRefressSearch} setVisibleRemove={setVisibleRemove}/>}        
      </Drawer>

      <table className='table1 center'>
  <thead>
  <tr>            
      <th width="220px" className={hideItem}>company_name</th>
      <th>SurveyPlan</th>
      <th width="220px">Total</th>
      
      <th>Success</th>
      <th width="220px">Failed </th>
      <th>Processing</th>
      <th>Others</th>
        
  </tr>
  </thead>
  <tbody>
{data.map(item=>(
  <tr key={item.id}>
      
      <td className={hideItem}>{item.company_name}</td>
      <td>{item.surveyplanname }</td>
      
      <td>
        {item.total}
        
        
      </td>
      
      
      <td>
        
        {item.success}

        </td>
        <td>
          {item.failed}
          
        </td>
        <td>{item.processing}</td>
      <td>{item.total-item.success -item.failed -item.processing}</td>
           
  </tr>
))}
</tbody>
</table>
<div className='callLogAudio' style={{"display":displayAudio}} >
            <audio ref={refAudio} controls>
                <source src={""} type="audio/wav" />                
                Your browser does not support the audio element.
            </audio>
            <Button type="link" onClick={()=>setDisplayAudio("none")}>Close Audio</Button>
        </div>
        
  </>
)
}