import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Drawer } from "antd";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import moment from "moment";

import {
  StepForwardOutlined,
  DownloadOutlined,
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Edit from "./Edit";
import Delete from "./Delete";
import useAxios from "./../../../CustomHooks/useAxios";
import TicketNoteEdit from "./../../TicketNoteOutEdit";
import TicketNoteOut_obEdit from "./../../TicketNoteOut_obEdit";
const dateFormat = "YYYY-MM-DD HH:mm:ss";

export default function ShowTable({ data, setRefressSearch }) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);

  const { axiosi } = useAxios();
  const refAudio = useRef();
  const [displayAudio, setDisplayAudio] = useState("none");
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleRemove, setVisibleRemove] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);

  const handleEdit = (item) => {
    console.log("[handleEdit]:item=", item);
    setSelectedItem(item);
    setVisibleEdit(true);
  };
  const handleRemove = (item) => {
    console.log("[handleRemove]:item=", item);
    setVisibleRemove(true);
    setSelectedDeleteItem(item);
  };

  const onCloseEdit = () => {
    console.log("[onCloseEdit]");
    setSelectedItem(null);
    setVisibleEdit(false);
  };
  const onCloseRemove = () => {
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
  };

  const handlePlayAudio = async (id, uniqueid) => {
    // refAudio.current.pause();
    setDisplayAudio("block");
    const kq = await axiosi.post("users/getFileLink", { id, uniqueid });
    // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
    refAudio.current.src = `${process.env.REACT_APP_DOMAIN}/ticketnote/fileLink?fileLink=${kq.data.fileLink}`;
    refAudio.current.play();
  };

  const handleDownloadAudio = async (id, uniqueid, tel, ext) => {
    // refAudio.current.pause();
    const kq = await axiosi.post("users/getFileLink", { id, uniqueid });
    // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
    const linkfile = `${process.env.REACT_APP_DOMAIN}/ticketnote/fileLink?fileLink=${kq.data.fileLink}`;
    // window.open(linkfile);
    axios({
      url: linkfile, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${uniqueid}_${tel}_${ext}.wav`); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  };
  const displayExt = (endpointid) => {
    const ext = endpointid.split("_");
    return ext[0];
  };
  const displayTicketEdit = () => {
    if (selectedItem.calltype === "ob") {
      return (
        <TicketNoteOut_obEdit
          item={selectedItem}
          setSelectedItem={setSelectedItem}
          setRefressSearch={setRefressSearch}
          setVisibleEdit={setVisibleEdit}
        />
      );
    }
    return (
      <TicketNoteEdit
        item={selectedItem}
        setSelectedItem={setSelectedItem}
        setRefressSearch={setRefressSearch}
        setVisibleEdit={setVisibleEdit}
      />
    );
  };
  return (
    <>
      {visibleEdit && selectedItem && displayTicketEdit()}
      {/* {visibleEdit && selectedItem && (
        <TicketNoteEdit
          item={selectedItem}
          setSelectedItem={setSelectedItem}
          setRefressSearch={setRefressSearch}
          setVisibleEdit={setVisibleEdit}
        />
      )} */}
      {/* <Drawer title="Edit  Menu Item" placement="right" onClose={onCloseEdit} visible={visibleEdit}>
      
          {selectedItem && <Edit item={selectedItem} setSelectedItem={setSelectedItem} setRefressSearch={setRefressSearch} setVisibleEdit={setVisibleEdit} />}        
      </Drawer> */}

      <Drawer
        title="Remove  Menu Item"
        placement="right"
        onClose={onCloseRemove}
        visible={visibleRemove}
      >
        {selectedDeleteItem && (
          <Delete
            item={selectedDeleteItem}
            setRefressSearch={setRefressSearch}
            setVisibleRemove={setVisibleRemove}
          />
        )}
      </Drawer>

      <table className="table1">
        <thead>
          <tr>
            <th width="120px" className={hideItem}>
              company_name
            </th>
            <th>Customer</th>
            <th>Ticket Note</th>
            <th>Call</th>
            <th width="120px">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td className={hideItem}>{item.company_name}</td>
              <td>
                <span className="stitle">Name:</span>
                <span className="svalue">{item.name}</span>
                <br />
                <span className="stitle">Company:</span>
                <span className="svalue">{item.ccomname}</span>
                <br />
                <span className="stitle">Tel:</span>
                <span className="svalue">{item.tel}</span>
                <br />
                <span className="stitle">Email:</span>
                <span className="svalue">{item.email}</span>
                <br />
              </td>
              <td>
                <span className="stitle">Title:</span>
                <span className="svalue">{item.title}</span>
                <br />
                <span className="stitle">Order code:</span>
                <span className="svalue">{item.ordercode}</span>
                <br />
                <span className="stitle">Bill code:</span>
                <span className="svalue">{item.billcode}</span>
                <br />
                <span className="stitle">Content:</span>
                <span className="svalue">{item.content}</span>
                <br />
                <span className="stitle">Solution:</span>
                <span className="svalue">{item.solution}</span>
                <br />
                <span className="stitle">Category:</span>
                <span className="svalue">{item.cat}</span>
                <br />
                <span className="stitle">Status:</span>
                <span className="svalue">{item.status}</span>
                <br />
              </td>
              <td>
                <span className="stitle">Agent:</span>
                <span className="svalue">
                  {displayExt(item.editagentcode)} - {item.editagentname}
                </span>
                <br />
                <span className="stitle">Create:</span>
                <span className="svalue">
                  {" "}
                  {moment(item.createtime).format(dateFormat)}{" "}
                </span>
                <br />
                <span className="stitle">Update:</span>
                <span className="svalue">
                  {" "}
                  {moment(item.edittime).format(dateFormat)}{" "}
                </span>
                <br />
                <span className="stitle">Calltype:</span>
                <span className="svalue">{item.calltype}</span>
                <br />
                {/* <span className='stitle'>recordpath:</span><span className='svalue'>{item.recordpath}</span><br/> */}
              </td>
              <td className="tdAction">
                <Button
                  type="link"
                  onClick={() => handlePlayAudio(item.id, item.uniqueid)}
                >
                  <StepForwardOutlined />
                  Play
                </Button>
                <Button
                  type="link"
                  onClick={() =>
                    handleDownloadAudio(
                      item.id,
                      item.uniqueid,
                      item.ctel,
                      item.editagentcode
                    )
                  }
                >
                  <DownloadOutlined />
                  Download
                </Button>
                <div className="rowFlex">
                  <Button
                    type="link"
                    onClick={() => handleEdit(item)}
                    icon={<EditFilled style={{ color: "hotpink" }} />}
                  >
                    Edit
                  </Button>
                  {/* <Button onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Remove</Button>               */}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="callLogAudio" style={{ display: displayAudio }}>
        <audio ref={refAudio} controls>
          <source src={""} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
        <Button type="link" onClick={() => setDisplayAudio("none")}>
          Close Audio
        </Button>
      </div>
    </>
  );
}
