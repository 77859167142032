import React,{useEffect,useState} from 'react'
import { useParams,useNavigate  } from "react-router-dom";
import { useSelector} from "react-redux";
import { Drawer,Button,Typography ,Input } from 'antd';
import { PlusCircleFilled} from '@ant-design/icons';

import useAxios from './../../../CustomHooks/useAxios';
import ShowTable from './ShowTable';
import PageLink from './../../PageLink/PageLink';
import Add from "./Add";
// import Upload from "./Upload";
// import UploadAntd from "./UploadAntd";
import DebounceSelect from './../../DebounceSelect';

export default function BlackList() {
  let { page } = useParams();
    let navigate  = useNavigate();
    const {axiosi}=useAxios();

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [data,setData]=useState();
    
    const [companyid, setCompanyid] = React.useState();
    const [tel,setTel]=useState('');
    
    const [refressSearch,setRefressSearch]=useState(true)
    const hideItem = useSelector((state) => state.loginReducer.hideItem);
    useEffect(()=>{
        doSearch();
    },[refressSearch,page])
    async function fetchUserList(company_name) {
        
        console.log('fetching user', company_name);
        const body=await axiosi.get('companies',{params:{company_name,pageSize:100}} );
        if (body?.data?.rows) {
            return body?.data?.rows.map((user) => ({
                label: user.company_name,
                 value: user.id,
               }));
        }
        else {
            const a=[{label:"---no data---",value:""}]
            return a.map(i=> (i))
        }
        // return axiosi.get('companies',{params:{company_name,pageSize:100}} ) // fetch('https://randomuser.me/api/?results=5')
        //   .then((body) =>
        //     body?.data?.rows.map((user) => ({
        //      label: user.company_name,
        //       value: user.id,
        //     })),
        //   );
      }
    const handleAdd=()=>{
        console.log("[handleAdd]:");
        setVisibleAdd(true);
    }
    const onCloseAdd=()=>{
        setVisibleAdd(false);
        
    }
    const handleSearch= async()=>{
        console.log("[handleSearch]");
        setRefressSearch(pre=>!pre)
    }
    const doSearch= async()=>{
        console.log("[doSearch]");
        // navigate('/ivrplanmain');
        try {
        const kq=await axiosi.get('blacklist',{params:{page,tel,company_id:companyid?.value}});
        console.log("[handleSearch]:kq.data=",kq.data);
        setData(kq.data);
        } catch (err){
            console.log("ERROR")
        }
    }
  return (<>
  {/* <Upload/>
  <UploadAntd/> */}
        <Drawer title="Add  Menu Item" placement="right" onClose={onCloseAdd} visible={visibleAdd}>             
                    <Add setRefressSearch={setRefressSearch} setVisibleAdd={setVisibleAdd}/>        
        </Drawer>
        <section className='search'>
        <div className="container">
            <div className="row">
                <Typography.Title  level={3}>Search :
                <Button onClick={handleAdd}  className="addButton" icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button>
                </Typography.Title>
                
            </div>
            
            <div className="row">
            
                <div className={hideItem}>
                    <label>Company :</label>                    
                    <DebounceSelect
                        showSearch={true}
                        value={companyid}
                        placeholder="Select a company !"
                        fetchOptions={fetchUserList}
                        onChange={(newValue) => {setCompanyid(newValue);  }}
                        style={{  width: '100%',   }}
                    />
                </div>
                <div className="col">
                    <label>Tel :</label>
                    <Input  onChange={({ target: { value } } )=>setTel(value)}/>
                </div>
                
                <div className="col">
                    <label>Search:</label>
                    <Button onClick={handleSearch}>Search :</Button>
                </div>
                
            </div>
        </div>

    </section>
    <section className='searchResult'>
        <div className="row">
        <Typography.Title  level={3}>Result</Typography.Title>
        </div>
        <div className="row">
            {data?.rows && <ShowTable data={data.rows} setRefressSearch={setRefressSearch} />}
        </div>
        <div className="row">
        {data?.pages>1?<PageLink pageSize={data.pageSize} searchpage={"blacklist"} pages={data.pages} numrows={data.numrows} page={data.page} />:<h1>chi co' 1 page</h1>}
        </div>
        

    </section>
  </>);
}
