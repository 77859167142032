import React,{useEffect,useState} from 'react'
import { useSelector} from "react-redux";
import { useParams } from "react-router-dom";
// import moment from 'moment';
import { Drawer,Form,Button,Row, Col,Divider,Typography ,DatePicker,Select,Input ,Spin} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
import debounce from 'lodash/debounce';
import useAxios from './../../CustomHooks/useAxios';

import ShowAgentsTable from './ShowAgentsTable';
import PageLink from './../PageLink/PageLink';
import AddAgents from "./AddAgents";


import AddAgentsRange from "./AddAgentsRange";

import EditAgentsRange from "./EditAgentsRange";
const { Text } = Typography;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
function DebounceSelect({ fetchOptions, debounceTimeout = 350, ...props }) {
    const [fetching, setFetching] = React.useState(false);
const [options, setOptions] = React.useState([]);
const fetchRef = React.useRef(0);


const debounceFetcher = React.useMemo(() => {
  const loadOptions = (value) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setFetching(true);
    fetchOptions(value).then((newOptions) => {
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }

      setOptions(newOptions);
      setFetching(false);
    });
  };

  return debounce(loadOptions, debounceTimeout);
}, [fetchOptions, debounceTimeout]);
return (
  <Select
  allowClear
    labelInValue
    filterOption={false}
    onSearch={debounceFetcher}
    notFoundContent={fetching ? <Spin size="small" /> : null}
    {...props}
    options={options}
  />
);
} // Usage of DebounceSelect

export default function Agents(){
    let { page } = useParams();
    const {axiosi}=useAxios();

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [visibleAddRange, setVisibleAddRange] = useState(false);
    const [visibleEditRange, setVisibleEditRange] = useState(false);
    const [data,setData]=useState();
    const [refresh,setRefresh]=useState(false);

    const [companyid, setCompanyid] = React.useState();
    const [name,setName]=useState('');
    const [username,setUsername]=useState('');
    const [endpoint_id,setEndpoint_id]=useState('');
    const [refreshSearch,setRefreshSearch]=useState(true)

    const hideItem = useSelector((state) => state.loginReducer.hideItem);
    
    

    useEffect(()=>{
        doSearch();
    },[refreshSearch,page])
    async function fetchUserList(company_name) {
        
        console.log('fetching user', company_name);
        const body=await axiosi.get('companies',{params:{company_name,pageSize:100}} );
        if (body?.data?.rows) {
            return body?.data?.rows.map((user) => ({
                label: user.company_name,
                 value: user.id,
               }));
        }
        else {
            const a=[{label:"---no data---",value:""}]
            return a.map(i=> (i))
        }
        // return axiosi.get('companies',{params:{company_name,pageSize:100}} ) // fetch('https://randomuser.me/api/?results=5')
        //   .then((body) =>
        //     body?.data?.rows.map((user) => ({
        //      label: user.company_name,
        //       value: user.id,
        //     })),
        //   );
      }
    const handleAddRange=()=>{
        console.log("[handleAddRange]:");
        setVisibleAddRange(true);
    }
    
    const handleEditRange=()=>{
        console.log("[handleEditRange]:");
        setVisibleEditRange(true);
    }
    const onCloseAddRange=()=>{
        setVisibleAddRange(false);
    }
    const onCloseEditRange=()=>{
        setVisibleEditRange(false);
    }
    const handleAdd=()=>{
        console.log("[handleAdd]:");
        setVisibleAdd(true);
    }
    const onCloseAdd=()=>{
        setVisibleAdd(false);
        
    }
    const handleSearch= async()=>{
        console.log("[search]");
        try {
        const kq=await axiosi.get('agents',{params:{name,username,company_id:companyid?.value,endpoint_id}});
        console.log("[handleSearch]:kq.data=",kq.data);
        setData(kq.data);
        } catch (err){
            console.log("ERROR")
        }
    }
    
    const handleSearchExport= async()=>{
        console.log("[search]");
        try {
            const sdata={pageSize:2000,name,username,company_id:companyid?.value,endpoint_id
              }
        // const kq=await axiosi.get('agents',{params:{name,username,company_id:companyid?.value,endpoint_id}});
        const kq=await axiosi.get("agents/exportAgentToFile",
          {params:sdata,responseType: 'blob'});

          const url = window.URL.createObjectURL(new Blob([kq.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `exportAgents.xlsx`); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();

        // console.log("[handleSearch]:kq.data=",kq.data);
        // setData(kq.data);
        } catch (err){
            console.log("ERROR")
        }
    }
    const doSearch= async()=>{
        console.log("[search]");
        try {
        const kq=await axiosi.get('agents',{params:{page,pageSize:20,name,username,company_id:companyid?.value,endpoint_id}});
        console.log("[handleSearch]:kq.data=",kq.data);
        setData(kq.data);
        } catch (err){
            console.log("ERROR")
        }
    }
  return (<>
  
        <Drawer title="Add" placement="right" onClose={onCloseAdd} visible={visibleAdd}>             
                    <AddAgents setVisibleAdd={setVisibleAdd} setRefreshSearch={setRefreshSearch} />        
        </Drawer>
        <Drawer title="Add" width={450} placement="right" onClose={onCloseAddRange} visible={visibleAddRange}>             
                    <AddAgentsRange setVisibleAdd={setVisibleAddRange} setRefreshSearch={setRefreshSearch} />        
        </Drawer>
        <Drawer title="Edit" width={450} placement="right" onClose={onCloseEditRange} visible={visibleEditRange}>             
                    <EditAgentsRange setVisible={setVisibleEditRange} setRefreshSearch={setRefreshSearch} />        
        </Drawer>

        <section className='search'>
        <div className="container">
            <div className="row searchbox">
                {/* <div > */}
                    <div className="right">Search:</div>
                {/* <Typography.Title  level={3}>Search :
                </Typography.Title> */}
                {/* </div> */}
                <div className="left">
                <Button onClick={handleAdd}   icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button>
                <Button onClick={handleAddRange}  icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add Range</Button>
                <Button onClick={handleEditRange}  icon={<EditFilled  style={{ color: '#1890ff' }} />}>Edit Range</Button>
                </div>
                
                
            </div>
            {/* <div className='doubleline'></div> */}
            
            <div className="row">
            
                <div className={hideItem}>
                    <label>Company :</label>                    
                    <DebounceSelect
                        showSearch={true}
                        value={companyid}
                        placeholder="Select a company !"
                        fetchOptions={fetchUserList}
                        onChange={(newValue) => {setCompanyid(newValue);  }}
                        style={{  width: '100%',   }}
                    />
                </div>
                <div className="col">
                    <label>Agent name :</label>
                    <Input  onChange={({ target: { value } } )=>setName(value)}/>
                </div>
                <div className="col">
                    <label>User name :</label>
                    <Input  onChange={({ target: { value } } )=>setUsername(value)}/>
                </div>
                <div className="col">
                    <label>Exten :</label>
                    <Input  onChange={({ target: { value } } )=>setEndpoint_id(value)}/>
                </div>
                <div className="col">
                    <label>&nbsp;</label>
                    <Button onClick={handleSearch}>Search</Button>
                </div>
                <div className="col">
                    <label>&nbsp;</label>
                    <Button onClick={handleSearchExport}>Export</Button>
                </div>
                
            </div>
        </div>

    </section>
    <section className='searchResult'>
        <div className="row">
        <Typography.Title  level={3}>Result:</Typography.Title>
        </div>
        <div className="row">
            {data?.rows && <ShowAgentsTable data={data.rows} setRefreshSearch={setRefreshSearch} />}
        </div>
        <div className="row">
        {data?.pages>1?<PageLink pageSize={data.pageSize} searchpage={"agents"} pages={data.pages} numrows={data.numrows} page={data.page} />:<h1>chi co' 1 page</h1>}
        </div>
        

    </section>
  </>);
}
