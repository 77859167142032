import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Button, Drawer, Input, Space, Form } from "antd";
import {
  PhoneOutlined,
  PlusCircleFilled,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
// import Upload from "./Upload";
import Edit from "./Edit";
import Delete from "./Delete";
import AddOutbound from "./AddOutbound";
import CallTo from "./../../CallTo";
import useAxios from "./../../../CustomHooks/useAxios";

export default function ShowTable({ data, setRefressSearch, provinceData }) {
  const { axiosi } = useAxios();
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleRemove, setVisibleRemove] = useState(false);

  const [visibleAddOutbound, setVisibleAddOutbound] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);
  const [selectedAddOutbound, setSelectedAddOutbound] = useState(null);

  const makeCall = useSelector((state) => state.jsSIPReducer.makeCall);

  const handleEdit = (item) => {
    console.log("[handleEdit]:item=", item);
    setSelectedItem(item);
    setVisibleEdit(true);
  };
  const handleRemove = (item) => {
    console.log("[handleRemove]:item=", item);
    setVisibleRemove(true);
    setSelectedDeleteItem(item);
  };

  const handleAddOutbound = (item) => {
    console.log("[setVisibleAddOutbound]:item=", item);
    setVisibleAddOutbound(true);
    setSelectedAddOutbound(item);
  };
  const handleAddOutbound1 = async (item) => {
    console.log("[setVisibleAddOutbound]:item=", item);
    let adddata = { ...item };
    console.log("[handleAddOutbound1]:values=", adddata);
    try {
      const kq = await axiosi.post(`comcontacts/addToOutbound`, adddata);
      console.log("[onFinishAdd]:kq=", kq);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error.response.data:", error.response.data);
        console.log("error.response.status:", error.response.status);
        console.log("error.response.headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("error.request :", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error error.message", error.message);
      }
      console.log("error.config:", error.config);
    }
  };
  const onCloseAddOutbound = () => {
    console.log("[onCloseAddOutbound]");
    setSelectedAddOutbound(null);
    setVisibleAddOutbound(false);
  };

  const onCloseEdit = () => {
    console.log("[onCloseEdit]");
    setSelectedItem(null);
    setVisibleEdit(false);
  };
  const onCloseRemove = () => {
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
  };

  const handleMakeCall = (callto) => {
    makeCall(callto);
  };
  return (
    <>
      <Drawer
        width={640}
        title="Edit Item"
        placement="right"
        onClose={onCloseEdit}
        visible={visibleEdit}
      >
        {selectedItem && (
          <Edit
            provinceData={provinceData}
            item={selectedItem}
            setSelectedItem={setSelectedItem}
            setRefressSearch={setRefressSearch}
            setVisibleEdit={setVisibleEdit}
          />
        )}
      </Drawer>

      <Drawer
        title="Remove Item"
        placement="right"
        onClose={onCloseRemove}
        visible={visibleRemove}
      >
        {selectedDeleteItem && (
          <Delete
            item={selectedDeleteItem}
            setRefressSearch={setRefressSearch}
            setVisibleRemove={setVisibleRemove}
          />
        )}
      </Drawer>

      <Drawer
        title="Add to Outbound"
        placement="right"
        onClose={onCloseAddOutbound}
        visible={visibleAddOutbound}
      >
        {selectedAddOutbound && (
          <AddOutbound
            item={selectedAddOutbound}
            setRefressSearch={setRefressSearch}
            setVisibleRemove={setVisibleAddOutbound}
          />
        )}
      </Drawer>

      <table className="table1">
        <thead>
          <tr>
            {/* <th>id</th> */}
            {/* <th>calldirection</th>
      <th>connected</th> */}

            <th width="220px" className={hideItem}>
              GM's Customer
            </th>
            <th>company_name</th>
            <th>name</th>
            <th>tel</th>
            <th>gender</th>
            <th>note</th>

            <th width="120px">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              {/* <td>{item.id}</td> */}
              {/* <td>{item.calldirection}</td>
      <td>{item.connected}</td> */}

              <td className={hideItem}>{item.company_name}</td>
              <td>{item.ccomname}</td>
              <td>{item.name}</td>
              <td>
                {/* <CallTo callTo= {item.tel} /> */}
                <span
                  className="stitle"
                  title="Tel, Click here to make a call!"
                  onClick={() => handleMakeCall(item.tel)}
                >
                  <PhoneOutlined className={"icon"} />
                </span>
                <span className="svalue">{item.tel}</span>
                <br />
              </td>
              <td>{item.gender}</td>
              <td>{item.note}</td>

              <td>
                {/* <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
                <div className="rowFlex">
                  <Button
                    onClick={() => handleEdit(item)}
                    icon={<EditFilled style={{ color: "hotpink" }} />}
                  >
                    Edit
                  </Button>
                  <Button onClick={() => handleRemove(item)}>
                    <DeleteFilled style={{ color: "red" }} />
                    Remove
                  </Button>
                  {/* <Button onClick={() => handleAddOutbound(item)}>
                    <PlusCircleFilled style={{ color: "#1890ff" }} />
                    Add to Outbound
                  </Button> */}
                  {/* <Button onClick={() => handleAddOutbound1(item)}>
                    <PlusCircleFilled style={{ color: "#1890ff" }} />
                    Add to Outbound
                  </Button> */}
                  {/* <Upload/> */}
                </div>
                {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
    Download
  </Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
