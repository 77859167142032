import React,{useEffect,useState,useRef} from 'react'
import { useDispatch, useSelector} from "react-redux";
import { useParams ,Link} from "react-router-dom";
import moment from 'moment';
import { Drawer,Form,Button,Row, Col,Divider,Typography ,DatePicker,Select,Input } from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
import useAxios from './../../CustomHooks/useAxios';
import ShowMenuItemTable from './ShowMenuItemTable';
import PageLink from './../PageLink/PageLink';

import AddMenuItem from "./AddMenuItem";
const { Text } = Typography;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

export default function MenuItem(){
    let { page } = useParams();
    const {axiosi}=useAxios();

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [data,setData]=useState();
    // const [refresh,setRefresh]=useState(false);
    const [refreshSearch,setRefreshSearch]=useState(true);

    const [menuSubLoading,setMenuSubLoading]=useState(true);
    const [menuSubData,setMenuSubData]=useState(null);
    
    const [menu_sub_id,setMenu_sub_id]=useState("");
    const [item_name,setItem_name]=useState("");

    useEffect(()=>{
        const getMenuSub=async()=>{
            const kq=await axiosi.get('menu_sub',{params:{pageSize:200}});
            console.log("[MenuItem-useEffect]:kq.data=",kq.data)
            setMenuSubData(kq.data);
            setMenuSubLoading(false);
        }
        getMenuSub();
    },[])

    useEffect(()=>{
        doSearch();
    },[refreshSearch,page])

    const handleAdd=()=>{
        console.log("[handleAdd]:");
        setVisibleAdd(true);
    }
    const onCloseAdd=()=>{
        setVisibleAdd(false);
        
    }
    const handleSearch= async()=>{
        console.log("[handleSearch]");
        setRefreshSearch(pre=>!pre)
    }
    const doSearch= async()=>{
        console.log("[search]");
        const kq=await axiosi.get('menu_item',{params:{page,menu_sub_id,item_name}});
        console.log("[handleSearch]:kq.data=",kq.data);
        setData(kq.data);
    }
    const onFinishSearch=(values)=>{
        console.log("[onFinishSearch]:values",values)
    }
    const onFinishFailedSearch=(error)=>{
        console.log("[onFinishFailedSearch]:Error=",error)
    }
    return (<>
            <Drawer title="Add  Menu Item" placement="right" onClose={onCloseAdd} visible={visibleAdd}>             
                    <AddMenuItem 
                        menuSubData={menuSubData} 
                        menuSubLoading={menuSubLoading} 
                        setMenuSubLoading={setMenuSubLoading}
                            setRefreshSearch={setRefreshSearch} 
                            setVisibleAdd={setVisibleAdd}
                        />        
            </Drawer>
    <section className='search'>
        <div className="container">
            <div className="row">
                <Typography.Title  level={3}>Search :
                <Button onClick={handleAdd}  className="addButton" icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button>
                </Typography.Title>
                
            </div>
            
            <div className="row">
                <div className="col">
                    <lable>submenu</lable>
                    {/* style={{ width: 120 }} */}
                    <Select defaultValue=""  style={{ width: "100%" }} 
                        loading={menuSubLoading}
                        onChange={(value)=>setMenu_sub_id(value)}
                    >
                        <Option value=""></Option>
                        {menuSubData? menuSubData?.rows?.map(item=>(
                            <Option key={item.id} value={item.id}>{item.title}</Option>
                        )):""}
                        
                    </Select>
                </div>
                <div className="col">
                    <label>menu item name :</label>
                    <Input onChange={({ target: { value } } )=>setItem_name(value)}/>
                </div>
                <div className="col">
                    <label>Search:</label>
                    <Button onClick={handleSearch}>Search :</Button>
                </div>
                
            </div>
        </div>

    </section>
    <section className='searchResult'>
        <div className="row">
        <Typography.Title  level={3}>Result</Typography.Title>
        </div>
        <div className="row">
            {data?.rows && <ShowMenuItemTable setRefreshSearch={setRefreshSearch} data={data.rows} menuSubData={menuSubData} menuSubLoading={menuSubLoading} setMenuSubLoading={setMenuSubLoading}/>}
        </div>
        <div className="row">
        {data?.pages>1?<PageLink pageSize={data.pageSize} searchpage={"menu_item"} pages={data.pages} numrows={data.numrows} page={data.page} />:<h1>chi co' 1 page</h1>}
        </div>
    </section>
    
    </>)
}