import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import JsSIP from "jssip";

import axios from "axios";
import { Routes, Route, useLocation } from "react-router-dom";
import {
  Layout,
  Menu,
  Typography,
  Button,
  Avatar,
  Drawer,
  message,
} from "antd";
import {
  HomeOutlined,
  LogoutOutlined,
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import loginSlice from "./redux/loginSlice";
import useAxios from "./CustomHooks/useAxios";

import "./App.css";
import "./Search.css";
import { checkLoginStatusInLocalStorage } from "./mylibs/list1";
import Home from "./components/Home/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import CallLog from "./components/CallLog/CallLog";
import Login from "./components/Login/Login";
import MyPhone from "./components/MyPhone/MyPhone";
import MyMenu from "./components/MyMenu/MyMenu";

import MenuSub from "./components/SettingsMenu/MenuSub";
import MenuItem from "./components/SettingsMenu/MenuItem";
import AuthorizationGroup from "./components/SettingsMenu/AuthorizationGroup";
import AuthorizationGroupItem from "./components/SettingsMenu/AuthorizationGroupItem";

import Itsps from "./components/SettingsTel/Itsps";
import Companies from "./components/SettingsTel/Companies";
import Tels from "./components/SettingsTel/Tels";
import Agents from "./components/SettingsTel/Agents";
import BlackList from "./components/SettingsTel/BlackList";

import SurveyPlan from "./components/SettingsTel/SurveyPlan";
import Department from "./components/SettingsTel/Department";
import CalloutRule from "./components/SettingsTel/CalloutRule";

import IVRPlanMain from "./components/SettingsIVR/IVRPlanMain";
import IVRPlanForDaily from "./components/SettingsIVR/IVRPlanForDaily";

import IVRPlanForKey from "./components/SettingsIVR/IVRPlanForKey";
import IVRPlanForDay from "./components/SettingsIVR/IVRPlanForDay";
import IVRPlanForWeekday from "./components/SettingsIVR/IVRPlanForWeekday";
import Contacts from "./components/Customers/Contacts";
import OutBoundData from "./components/Customers/OutBoundData";
import OutboundPlan from "./components/Customers/OutboundPlan";
import AgentOutboundData from "./components/Customers/AgentOutboundData";

import ZnsPlan from "./components/Zalo/ZnsPlan";
import ZnsData from "./components/Zalo/ZnsData";
import SendSMS from "./components/SMS/SendSMS";
import SMSThuongHieu from "./components/SMS/SMSThuongHieu";

import Tickets from "./components/Tickets/Tickets";

import AutoCall from "./components/AutoCall/AutoCall";
import SurveyData from "./components/AutoCall/SurveyData";
import SurveyCurrentCall from "./components/AutoCall/SurveyCurrentCall";

import AgentStatus from "./components/CallCenter/AgentStatus";
import AgentLogs from "./components/CallCenter/AgentLogs";

import Pause from "./Pause";
import Profile from "./Profile";
import {
  registerMakeCall,
  setRegisterStatus,
  addCallInfo_Ticket,
  delCallInfo_Ticket,
  setprovinceDataRedux,
} from "./redux/jsSIPSlice";
import QuickCall from "./components/QuickCall";
import CurrentCall from "./components/CurrentCall";
import CallIn from "./components/CallIn";
import CallOut from "./components/CallOut";
import TicketNote from "./components/TicketNote";
import TicketNoteIn from "./components/TicketNoteIn";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Title } = Typography;
function getFaviconEl() {
  return document.getElementById("favicon");
}
function App() {
  const { axiosi } = useAxios();
  const [hideItem, setHideItem] = useState("hideItem");
  const [userAvatar, setUserAvatar] = useState("userRegistered");
  const [collapsed, setCollapsed] = useState(false);
  let isLogined = useSelector((state) => state.loginReducer.isLogined);
  let webrtc = useSelector((state) => state.loginReducer.webrtc);
  const [refreshCallLog, setRefreshCallLog] = useState(false);
  const [displayRegisterStatus, setDisplayRegisterStatus] = useState("");
  const [pauseStatus, setPauseStatus] = useState(false);
  const pauseStatusRef = useRef(false);
  const [pauseReason, setPauseReason] = useState("");
  const [visiblePause, setVisiblePause] = useState(false);
  const [visibleProfile, setVisibleProfile] = useState(false);
  const user = useSelector((state) => state.loginReducer.user);
  const endpointid = useSelector((state) => state.loginReducer.endpointid);
  // const [sipUser, setSipUser] = useState("201");
  let isRegister = useSelector((state) => state.jsSIPReducer.isRegister);
  const [sipUser, setSipUser] = useState("6302");
  const [mySessionsId, setMySessionsId] = useState([]);
  const [mySessionsIdTicket, setMySessionsIdTicket] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectSubMenu, setSelectSubMenu] = useState("");
  let audioPlayers = useSelector((state) => state.jsSIPReducer.audioPlayers);
  const [provinceData, setProvinceData] = useState(null);
  // const handleGoogle = () => {
  //   const favicon = getFaviconEl(); // Accessing favicon element
  //   favicon.href = "https://www.google.com/favicon.ico";
  // };

  // const handleYoutube = () => {
  //   const favicon = getFaviconEl();
  //   favicon.href = " https://s.ytimg.com/yts/img/favicon-vfl8qSV2F.ico";
  // };
  const handleFaviIcon = (icon) => {
    return;
    // const favicon = getFaviconEl();
    // favicon.href = "./img/" + icon; //favicon_ring.ico";
  };
  useEffect(() => {
    // handleFaviIcon("favicon_ring.ico");
    const getProvince = async () => {
      try {
        const kq = await axiosi.get("province", { params: { pageSize: 100 } });
        console.log(
          "[App-useEffect-getProvince]:kq get province : ",
          kq.data.rows
        );
        setProvinceData(kq.data.rows);
        dispatch(setprovinceDataRedux(kq.data.rows));
      } catch (error) {
        console.log("[Effect-getProvince]:Error");
      }
    };
    if (isLogined) {
      getProvince();
    }
  }, [isLogined, dispatch]);
  useEffect(() => {
    dispatch(
      loginSlice.actions.setLogin({
        isLogined: checkLoginStatusInLocalStorage(),
      })
    );
  }, [dispatch]);

  useEffect(() => {
    async function getUser() {
      console.log("Will getUser() : api post users/getUser");
      const kq = await axiosi.post("users/getUser");
      console.log("Will getUser() : api post users/getUser : DOne");
      dispatch(loginSlice.actions.setUser({ user: kq.data }));
    }
    if (isLogined && !user.agents) {
      console.log("Will getUser()");
            getUser();
    }
  }, [isLogined, user, axiosi, dispatch]);

  useLayoutEffect(() => {
    if (user.agents) {
      let curpathname = location.pathname;
      const curpathnameArr = curpathname.split("/");
      curpathname = "/" + curpathnameArr[1];
      const menuitem = user.menu.menu_items;
      const item = menuitem.find((i) => i.item_key === curpathname);

      if (item) {
        console.log("[handleGetSubMenu]:Tim` thay item", curpathname);
        const menusubs = user.menu.menu_subs;
        const sub = menusubs.find((s) => s.id === item.menu_sub_id);
        console.log("sub=", sub);
        setSelectSubMenu(sub.menukey);
        console.log("sub.menukey=", sub.menukey);
      } else {
        console.log("[handleGetSubMenu]:Kg tim thay Item", curpathname);
      }
    } else {
      console.log("[handleGetSubMenu]:kg co' agents");
    }
  }, [
    selectSubMenu,
    location.pathname,
    user?.agents,
    user?.menu?.menu_items,
    user?.menu?.menu_subs,
  ]);
  const coolPhone = useRef(
    useSelector((state) => state.jsSIPReducer.coolPhone)
  );
  const mysessions = useRef({});
  const mysessionsInfo = useRef({});
  console.log("mysessions=", mysessions.current);
  console.log("mysessionsInfo=", mysessionsInfo.current);
  console.log("mySessionsId=", mySessionsId);
  // const mySessionsId = useRef([]);
  //makecall
  const jssipCall = useCallback(
    (to) => {
      to = to.trim();
      console.log(
        "[MAKECALL]:Object.keys(mysessionsInfo.current).length=",
        Object.keys(mysessionsInfo.current).length,
        { mysessionsInfo }
      );
      if (Object.keys(mysessionsInfo.current).length > 0) {
        return;
      }

      // Register callbacks to desired call events
      // to = 2000;
      var eventHandlers = {
        progress: function (e) {
          console.log("[eventHandlers]:call is in progress", this.id, e);
          // mysessions.current[this.id].status = "Call is in progress ...";
          // document.getElementById("txtSessionStatus").innerHTML =
          //   "call is in progress";
          // debugger;
        },
        failed: function (e) {
          // message.info(e.cause);
          console.log("[eventHandlers]:call failed with cause: ", e, this.id);
          delete mysessionsInfo.current[this.id];
          delete mysessions.current[this.id];
          // console.log(
          //   "[1-Cheking exist the id= ",
          //   this.id,
          //   " in mySessionsIdTicket=",
          //   mySessionsIdTicket
          // );
          // if (!mySessionsIdTicket.includes(this.id)) {
          //   dispatch(delCallInfo_Ticket({ id: this.id }));
          // }
          setMySessionsId((prev) => prev.filter((id) => id !== this.id));
          setRefreshCallLog((pre) => !pre);
          // document.getElementById("txtSessionStatus").innerHTML =
          //   "call failed with cause:";
          // debugger;
          console.log("failed [eventHandlers]:e.originator=" + e.originator);
          // message.info("failed_" + e.originator);
          if (e.originator === "remote") {
            console.log("[eventHandlers-failed_]:remote-> playEND");
            audioPlayers.End.play();
            setTimeout(() => {
              audioPlayers.End.pause();
            }, 1500);
          }
        },
        ended: function (e) {
          console.log("[eventHandlers]:call ended with cause: ", e, this.id);
          // document.getElementById("txtSessionStatus").innerHTML =
          //   "call ended with cause:";
          // debugger;

          delete mysessionsInfo.current[this.id];
          delete mysessions.current[this.id];
          // console.log(
          //   "[2-Cheking exist the id= ",
          //   this.id,
          //   " in mySessionsIdTicket=",
          //   mySessionsIdTicket
          // );
          // if (!mySessionsIdTicket.includes(this.id)) {
          //   dispatch(delCallInfo_Ticket({ id: this.id }));
          // }
          setMySessionsId((prev) => prev.filter((id) => id !== this.id));
          setRefreshCallLog((pre) => !pre);
          console.log("End [eventHandlers]:e.originator=" + e.originator);
          // message.info("End Call_" + e.originator);
          if (e.originator === "remote") {
            console.log("[eventHandlers]:remote-> playEND");
            audioPlayers.End.play();
            setTimeout(() => {
              audioPlayers.End.pause();
            }, 1500);
          }
        },
        confirmed: function (e) {
          console.log("[eventHandlers]:call confirmed", this.id, e);
          mysessions.current[this.id].callstatus = "confirmed";
          // document.getElementById("txtSessionStatus").innerHTML =
          //   "call confirmed";
          // debugger;
        },
        peerconnection: function (e) {
          console.log("[eventHandlers]:peerconnection", this.id, e);
          //   var peerconnection = e.peerconnection;
          //   console.log(peerconnection);
          //   peerconnection.onaddstream;
          //   debugger;
        },
      };

      var options = {
        eventHandlers: eventHandlers,
        mediaConstraints: { audio: true, video: false },
      };

      var session = coolPhone.current.call(
        `sip:${to}@${process.env.REACT_APP_DOMAIN_NAME}`,
        options
      );
    },
    [coolPhone, mySessionsId, mysessions]
  );

  useEffect(() => {
    console.log("[useEffect 1]: endpointid=", endpointid);
  }, [endpointid]);

  ///Register
  const handleRegister = useCallback(async () => {
    console.log("[handleRegister]:", { webrtc });

    const getEndpoint = await axiosi.post("users/getEndpoint");
    console.log({ getEndpoint });
    console.log("[handleRegister] : endpointid=", endpointid);
    //var socket = new JsSIP.WebSocketInterface("ws://192.168.1.99:8088/ws");
    var socket = new JsSIP.WebSocketInterface(
      `wss://${process.env.REACT_APP_DOMAIN_NAME}/ws`
    );

    const configuration = {
      sockets: [socket],
      //uri: `sip:${sipUser}@192.168.1.99`,
      //password: sipUser,

      // uri: `sip:${sipUser}@${process.env.REACT_APP_DOMAIN_NAME}`,
      // password: `${sipUser}1212`,
      uri: `sip:${endpointid}@${process.env.REACT_APP_DOMAIN_NAME}`,
      password: getEndpoint.data[0].password,
    };
    // console.log("[handleRegister]:configuration=", configuration);
    coolPhone.current = new JsSIP.UA(configuration);
    coolPhone.current.start();
    dispatch(registerMakeCall(jssipCall));

    coolPhone.current.on("connected", function (e) {
      /* Your code here */
      console.log("connected", e);
      // document.getElementById("txtConnectStatus").innerHTML = "connected";
      // elog(e);
      // debugger;
    });

    coolPhone.current.on("disconnected", function (e) {
      /* Your code here */
      console.log("disconnected", e);
      // document.getElementById("txtConnectStatus").innerHTML = "disconnected";
      // elog(e);
      // debugger;
    });

    coolPhone.current.on("registered", function (e) {
      /* Your code here */
      //handleFaviIcon("faviconGreenUser.ico");
      console.log("registered", e);
      dispatch(setRegisterStatus({ isRegister: true }));
      // document.getElementById(
      //   "txtRegisterStatus"
      // ).innerHTML = `[${e.response.from.uri.user}] : registered`;
      // console.log("registered", e);
      // debugger;
    });
    coolPhone.current.on("unregistered", function (e) {
      /* Your code here */
      // document.getElementById("txtRegisterStatus").innerHTML = "unregistered";
      // handleFaviIcon("faviconBlackUser.ico");
      console.log("unregistered", e);
      dispatch(setRegisterStatus({ isRegister: false }));
      // debugger;
    });
    coolPhone.current.on("registrationFailed", function (e) {
      /* Your code here */
      console.log("registrationFailed", e);
      // document.getElementById("txtRegisterStatus").innerHTML = "registrationFailed";
      // debugger;
    });

    function addRemoteVideo(id, stream) {
      console.log("addRemoteVideo :", id, stream);

      let remotevideo = document.getElementById("video" + id);
      console.log("remotevideo", remotevideo);
      debugger;
      if (!remotevideo) {
        remotevideo = window.document.createElement("video");
        remotevideo.id = "video" + id;
        const videoGrid = document.getElementById("video-grid");
        videoGrid.append(remotevideo);
      }
      remotevideo.srcObject = stream;
      remotevideo.addEventListener("loadedmetadata", () => {
        remotevideo.play();
      });
    }
    coolPhone.current.on("newRTCSession", function (e) {
      // console.log("[newRTCSession]:mySessionsId.length=",mySessionsId.length)
      // console.log("[newRTCSession]:mySessionsId=",mySessionsId)
      // console.log("[newRTCSession]:mysessionsInfo.current=",mysessionsInfo.current)
      console.log(
        "[newRTCSession]:Object.keys(mysessionsInfo)=",
        Object.keys(mysessionsInfo.current)
      );
      if (Object.keys(mysessionsInfo.current).length > 0) {
        e.session.terminate();
        console.log("Terminate be cause , there is another call");
        audioPlayers.Ring.pause();
        return;
      }
      // document.getElementById("txtSessionStatus").innerHTML = "newRTCSession";
      console.log("[newRTCSession]: Recordpath=", e.request.headers.Recordpath);
      console.log("[newRTCSession]:  pauseStatus=", pauseStatus);
      console.log(
        "[newRTCSession]:  pauseStatusRef.current=",
        pauseStatusRef.current
      );
      console.log(
        "[newRTCSession]: User-Agent =",
        e.request.headers["User-Agent"]
      );
      console.log("newRTCSession", e);
      mysessionsInfo.current[e.session.id] = {};
      mysessionsInfo.current[e.session.id].id = e.session.id;
      mysessionsInfo.current[e.session.id].display_name =
        e.request.from._display_name;
      mysessions.current[e.session.id] = e.session;
      // mysessions.current[e.session.id].status = "new session";
      console.log("[newRTCSession]: remote-Incoming call_Request=", e.request);
      console.log("[newRTCSession]: call_id=", e.request.call_id);
      console.log("[newRTCSession]: Recordpath=", e.request.headers.Recordpath);
      console.log(
        "[newRTCSession]: remote-Incoming call_Caller e.request.data=",
        e.request.data
      );
      console.log(
        "[newRTCSession]: remote-Incoming call_Caller e.request.from=",
        e.request.from
      );
      console.log(
        "[newRTCSession]: remote-Incoming call_Caller e.request.from._display_name=",
        e.request.from._display_name
      );
      console.log(
        "[newRTCSession]: remote-Incoming call_Caller=",
        e.request.from.uri.user
      );

      // mySessionsId.current.push(e.session.id);
      if (e.originator === "remote") {
        handleFaviIcon("favicon_ring.ico");
        const id = e.session.id;
        console.log("newRTCSession]: remote ");
        console.log(
          "[newRTCSession]: remote, pauseStatusRef.current=",
          pauseStatusRef.current
        );
        if (pauseStatusRef.current) {
          e.session.terminate();
          console.log("newRTCSession]: remote | Terminate be cause  paused");
          audioPlayers.Ring.pause();
          delete mysessionsInfo.current[e.session.id];
          delete mysessions.current[e.session.id];
          setMySessionsId((prev) => prev.filter((id) => id !== e.session.id));
          setRefreshCallLog((pre) => !pre);
          return;
        }

        mysessionsInfo.current[id].direction = "in";
        mysessionsInfo.current[id].tel = e.request.from.uri.user;
        mysessionsInfo.current[id].uniqueid = e.request.headers.Uniqueid;
        mysessionsInfo.current[id].recordpath = e.request.headers.Recordpath;
        mysessionsInfo.current[id].calltype = e.request.headers.Calltype;
        setMySessionsId((prev) => [...prev, e.session.id]);

        // mysessions.current[id].status = "remote session";
        let html = ` 
        <fieldset id="${e.session.id}">
        <legend>${e.session.id}</legend>
        <div>
        <button onclick="jssipCall_Hangup('${id}')">Hangup</button>
        <button onclick="jssipCall_SendDTMF('${id}')">SendDTMF</button>
        <button onclick="myAnswerCall('${id}')">AnswerCall</button>
        </div>
    
        <div>
        <button onclick="jssipMute('${id}')">Mute</button>
        <button onclick="jssipunMute('${id}')">unMute</button>
    
        <button onclick="jssipHold('${id}')">Hold</button>
        <button onclick="jssipunHold('${id}')">UnHolad</button>
        </div>
    
        <div>
        <button onclick="jssipBindTransfer('${id}')">BlindTransfer</button>
        <button onclick="jssipAttentTransfer('${id}')">AttendTransfer</button>
    
        <button onclick="jssipAcceptTransfer('${id}')">AcceptTransfer</button>
        <button onclick="jssipRejectTransfer('${id}')">RejectTransfer</button>
        </div>
        </fieldset>
        `;
        // document.getElementById("divCurrentSession").innerHTML += html;
        /////////////////////////

        // document.getElementById("icallto").value = e.request.from.uri.user;
        audioPlayers.Ring.currentTime = 0;
        audioPlayers.Ring.play();
        // mysessions.current[id].status = "Ringing...";
        mysessions.current[e.session.id].on("ended", (data) => {
          console.log(
            "[appjs-newRTCSession-remote]:The call is answered, and now is Endcall,data=",
            data
          );
          // document.getElementById("txtSessionStatus").innerHTML =
          //   "The call is answered, and now is Endcall";

          // document.getElementById(id).remove();
          // document.getElementById("video" + id).remove();
          audioPlayers.Ring.pause();
          delete mysessionsInfo.current[e.session.id];
          delete mysessions.current[e.session.id];
          // console.log(
          //   "[3-Cheking exist the id= ",
          //   e.session.id,
          //   " in mySessionsIdTicket=",
          //   mySessionsIdTicket
          // );

          // if (!mySessionsIdTicket.includes(e.session.id)) {
          //   dispatch(delCallInfo_Ticket({ id: e.session.id }));
          // }
          setMySessionsId((prev) => prev.filter((id) => id !== e.session.id));
          setRefreshCallLog((pre) => !pre);
          // debugger;
          if (data.originator === "remote") {
            console.log("[eventHandlers-failed_]:remote-> playEND");
            audioPlayers.End.play();
            setTimeout(() => {
              audioPlayers.End.pause();
            }, 1500);
          }
        });
        mysessions.current[e.session.id].on("failed", (data) => {
          console.log(
            "[appjs-newRTCSession-remote]:The call is end without answer,data=",
            data
          );
          // document.getElementById("txtSessionStatus").innerHTML =
          //   "The call is end without answer";
          // document.getElementById(id).remove();
          // document.getElementById("video" + id).remove();
          audioPlayers.Ring.pause();

          delete mysessionsInfo.current[e.session.id];
          delete mysessions.current[e.session.id];
          // console.log(
          //   "[4-Cheking exist the id= ",
          //   e.session.id,
          //   " in mySessionsIdTicket=",
          //   mySessionsIdTicket
          // );
          // if (!mySessionsIdTicket.includes(e.session.id)) {
          //   dispatch(delCallInfo_Ticket({ id: e.session.id }));
          // }
          setMySessionsId((prev) => prev.filter((id) => id !== e.session.id));
          console.log("Need Rerender");
          setRefreshCallLog((pre) => !pre);
          if (data.originator === "remote") {
            console.log("[eventHandlers-failed_]:remote-> playEND");
            audioPlayers.End.play();
            setTimeout(() => {
              audioPlayers.End.pause();
            }, 1500);
          }
          // debugger;
        });
      } else if (e.originator === "local") {
        const id = e.session.id;
        // mysessions.current[id].direction = "out";
        // mysessions.current[id].tel = e.request.from.uri.user;
        // mysessions.current[id].status = "local";

        mysessionsInfo.current[e.session.id].caller = e.request.from._uri._user;

        let telsdt = e.request.ruri._user;
        try {
          if (telsdt.length > 0) {
            if (telsdt[0] === "b") {
              telsdt = telsdt.substring(1);
            }
          }
        } catch (e) {}
        mysessionsInfo.current[e.session.id].callee = telsdt;
        mysessionsInfo.current[e.session.id].tel = telsdt;
        mysessionsInfo.current[id].direction = "out";
        mysessionsInfo.current[id].call_id = e.request.call_id;
        // dispatch(addCallInfo_Ticket({ id, call_id: e.request.call_id }));
        let html = ` 
        <fieldset id="${e.session.id}">
        <legend>${e.session.id}</legend>
        <div>
        <button onclick="jssipCall_Hangup('${id}')">Hangup</button>
        <button onclick="jssipCall_SendDTMF('${id}')">SendDTMF</button>
        <button onclick="myAnswerCall('${id}')">AnswerCall</button>
        </div>
    
        <div>
        <button onclick="jssipMute('${id}')">Mute</button>
        <button onclick="jssipunMute('${id}')">unMute</button>
    
        <button onclick="jssipHold('${id}')">Hold</button>
        <button onclick="jssipunHold('${id}')">UnHolad</button>
        </div>
    
        <div>
        <button onclick="jssipBindTransfer('${id}')">BlindTransfer</button>
        <button onclick="jssipAttentTransfer('${id}')">AttendTransfer</button>
    
        <button onclick="jssipAcceptTransfer('${id}')">AcceptTransfer</button>
        <button onclick="jssipRejectTransfer('${id}')">RejectTransfer</button>
        </div>
        </fieldset>
        `;
        // document.getElementById("divCurrentSession").innerHTML += html;

        setMySessionsId((prev) => [...prev, e.session.id]);
        mysessions.current[e.session.id].connection.addEventListener(
          "addstream",
          function (e1) {
            // Or addtrack
            console.log("[peerSession-addstream]:session e1", e1);
            mysessionsInfo.current[id].stream = e1.stream;
            // debugger;
            var remoteAudio = window.document.createElement("audio");
            window.document.body.appendChild(remoteAudio);
            remoteAudio.srcObject = e1.stream;
            remoteAudio.play();
            mysessions.current[id].callstatus = "addstream";
            // addRemoteVideo(id, e1.stream);
            // const remotevideo = window.document.createElement("video");
            // remotevideo.id = "video" + id;
            // // remotevideo.srcObject = window.URL.createObjectURL(e1.stream);
            // remotevideo.srcObject = e1.stream;
            // remotevideo.addEventListener("loadedmetadata", () => {
            //   remotevideo.play();
            // });
            // // window.document.body.appendChild(remotevideo);
            // const videoGrid = document.getElementById("video-grid");
            // videoGrid.append(remotevideo);

            // const remoteAudio = document.createElement("audio");
            // remoteAudio.srcObject = window.URL.createObjectURL(e1.stream);
            // remoteAudio.play();
          }
        );
        mysessions.current[e.session.id].on("failed", async (data) => {
          // message.info('[newRTPSession]:failed='+e.cause);
          audioPlayers.Ring.pause();
          console.log("[local-coolphone]:The call is end without answer", data);
          console.log("[local-coolphone]:[e.session.id]=", e.session.id);
          console.log("[local-coolphone]:[e.session]=", e.session);
          console.log(
            "[local-coolphone]:mysessions.current[e.session.id]",
            mysessions.current[e.session.id]
          );
          // document.getElementById(id).remove();
          // document.getElementById("video" + id).remove();
          //Busy
          if (data.cause != "Canceled") {
            // audioPlayers.End.play();
            // setTimeout(audioPlayers.End.pause, 1500);
            // setTimeout(stopEndSound, 1500);
            // document.getElementById("txtSessionStatus").innerHTML =
            //   `<button class="btnstopsound" id="stopsound_${id}" onclick="stopEndSound('${id}')"><i class="fa-solid fa-volume-xmark"></i></button> The call is end without answer : ` +
            //   e.cause; //User Denied Media Access
          }
          //  else {
          //   document.getElementById("txtSessionStatus").innerHTML =
          //     `The call is end without answer : ` + e.cause; //User Denied Media Access
          // }

          // document.getElementById("txtSessionStatus").innerHTML =
          //   "The call is end without answer : " + e.cause; //User Denied Media Access

          // debugger;
          const causefromphone =
            data?.message?.reason_phrase + " | " + data.cause;
          const call_id = data?.message?.call_id;
          message.info({
            content: `${causefromphone}`,
            className: "messageRight",
          });
          console.log("[updatephonecauseToCDR]:", { causefromphone, call_id });
          try {
            const kqupdatephonecauseToCDR = await axiosi.put(
              "calllogs/updatephonecauseToCDR",
              {
                causefromphone,
                call_id,
              }
            );
          } catch {}
        });

        mysessions.current[e.session.id].on("confirmed", (data) => {
          console.log(
            "[App.js - mysessions.current[e.session.id].on = confirmed]:data=",
            data
          );
        });
      }
      // debugger;
    });
  }, [
    coolPhone,
    pauseStatus,
    dispatch,
    jssipCall,
    audioPlayers.Ring,
    endpointid,
    axiosi,
  ]);

  const handleUnregister = () => {
    // coolPhone.current.unregister();
    if (coolPhone.current) {
      coolPhone.current.stop();
    }
  };
  const handleReregister = () => {
    // coolPhone.current.register();
    coolPhone.current.start();
  };
  // useEffect(() => {
  //   // if (isLogined) {
  //   //   handleRegister();
  //   // }

  //   console.log("[0 useEffect_getpassword]::::::::::::::::ALO");
  //   async function getpassword() {
  //     if (isLogined && endpointid !== "") {
  //       const kq = await axiosi.post("users/getEndpoint");
  //       // const kq = await getEndpoint();
  //       console.log("[useEffect_getpassword]", kq);

  //       handleRegister();
  //     }
  //   }
  //   console.log("[0 useEffect_getpassword]");
  //   getpassword();
  // }, [isLogined, handleRegister, axiosi, endpointid]);

  useEffect(() => {
    // if (user?.agents[0]["webrtc"] != "yes") {
    //   return;
    // }
    console.log("[---------useEffect-----------]:", {
      isLogined,
      endpointid,
      webrtc,
    });
    if (isLogined && endpointid !== "" && webrtc === "yes") {
      console.log("[---------useEffect-----------]:will Register");
      handleRegister();
    } else {
      console.log("[---------useEffect-----------]:WILL unRegister");
      handleUnregister();
    }

    // console.log("[0 useEffect_getpassword]::::::::::::::::ALO");
    // async function getpassword() {
    //   if (isLogined && endpointid !== "") {
    //     const kq = await axiosi.post("users/getEndpoint");
    //     // const kq = await getEndpoint();
    //     console.log("[useEffect_getpassword]", kq);

    //     handleRegister();
    //   }
    // }
    // console.log("[0 useEffect_getpassword]");
    // getpassword();
  }, [isLogined, endpointid, webrtc]);

  useEffect(() => {
    pauseStatusRef.current = pauseStatus;
  }, [pauseStatus]);
  useEffect(() => {
    const getPause = async () => {
      try {
        const kq = await axiosi.get(`queue_members/pause`, {
          params: {
            paused: 1,
            membername: user?.agents[0].endpoint_id,
            company_id: user?.agents[0].company_id,
          },
        });
        console.log("[getPause]:kq=", kq);
        if (kq.data != "") {
          if (kq.data.rows.length > 0) {
            setPauseStatus(true);
          } else {
            setPauseStatus(false);
          }
        } else {
          setPauseStatus(false);
        }
      } catch (error) {
        console.log("[getPause]:error");
      }
    };
    if (isLogined && user?.agents && user?.agents[0].endpoint_id !== "") {
      getPause();
    }
  }, [isLogined, user?.agents]);

  useEffect(() => {
    handleFaviIcon("faviconGreenUser.ico");
    if (!isRegister) {
      setDisplayRegisterStatus(" is not Registered");
      setUserAvatar("userUnRegistered");
      handleFaviIcon("faviconBlackUser.ico");
    } else if (pauseStatus) {
      setDisplayRegisterStatus(" is Registered and Paused");
      setUserAvatar("userPause");
    } else {
      setDisplayRegisterStatus(" is Registered");
      setUserAvatar("userRegistered");
    }
  }, [isRegister, pauseStatus]);

  useEffect(() => {
    if (!isLogined) {
      handleUnregister();
    }
  }, [isLogined]);

  if (!isLogined) {
    return <Login />;
  }
  const toggle = () => {
    setCollapsed((prev) => !prev);
  };
  const handleLogout = () => {
    setHideItem("hideItem");
    axios.post(
      `${process.env.REACT_APP_DOMAIN}/users/logout`,
      { user },
      {
        withCredentials: true,
      }
    );

    localStorage.removeItem(process.env.REACT_APP_LSTOKEN);
    dispatch(
      loginSlice.actions.setLogin({
        isLogined: checkLoginStatusInLocalStorage(),
      })
    );
    dispatch(loginSlice.actions.setWebrtc({ webrtc: "KKKKK" }));
  };

  const handleGetEndpoint = async () => {
    const kq = await axiosi.post("users/getEndpoint");
    console.log(kq.data);
  };

  const handleGetSubMenu = () => {
    if (user.agents) {
      let curpathname = location.pathname;
      const curpathnameArr = curpathname.split("/");
      curpathname = "/" + curpathnameArr[1];
      const menuitem = user.menu.menu_items;
      const item = menuitem.find((i) => i.item_key === curpathname);

      if (item) {
        console.log("[handleGetSubMenu]:Tim` thay item", curpathname);
        const menusubs = user.menu.menu_subs;
        const sub = menusubs.find((s) => s.id === item.menu_sub_id);
        console.log("sub=", sub);
        setSelectSubMenu(sub.menukey);
        console.log("sub.menukey=", sub.menukey);
      } else {
        console.log("[handleGetSubMenu]:Kg tim thay Item", curpathname);
      }
    } else {
      console.log("[handleGetSubMenu]:kg co' agents");
    }
  };

  const displayExt = (endpointid) => {
    const ext = endpointid.split("_");
    return ext[0];
  };
  // handleGetSubMenu();
  const checkRoute = (menuitem) => {
    if (user?.menu?.menu_items.find((item) => item.item_key === menuitem)) {
      return true;
    }
    return false;
  };
  //////////////
  const getUniqueid = async (id, call_id) => {
    try {
      const kq = await axiosi.get("callstatus/cdrout", { params: { call_id } });
      const kqdata = await kq.data;
      // console.log("[getCustomerInfoByTel]:kqdata=",kqdata);
      // console.log("[getCustomerInfoByTel]:kqdata.rows=",kqdata.rows);

      if (kqdata.rows.length > 0) {
        mysessionsInfo.current[id].uniqueid = kqdata.rows[0]["uniqueid"];
        mysessionsInfo.current[id].recordpath = kqdata.rows[0]["recordpath"];
      } else {
        mysessionsInfo.current[id].uniqueid = "";
        mysessionsInfo.current[id].recordpath = "";
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error.response.data=", error.response.data);
        console.log("error.response.status=", error.response.status);
        console.log("error.response.headers=", error.response.headers);
        // message.error(`Error: ${error.response.data.code}`);
        mysessionsInfo.current[id].uniqueid = "";
        mysessionsInfo.current[id].recordpath = "";
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
        mysessionsInfo.current[id].uniqueid = "";
        mysessionsInfo.current[id].recordpath = "";
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        mysessionsInfo.current[id].uniqueid = "";
        mysessionsInfo.current[id].recordpath = "";
      }
      console.log(error.config);
      mysessionsInfo.current[id].uniqueid = "";
      mysessionsInfo.current[id].recordpath = "";
    }
  };

  const handlePause = async () => {
    console.log("[handPause]");
    setHideItem("hideItem");
    setVisiblePause(true);
  };
  const handleunPause = async () => {
    console.log("[handleunPause]");
    setHideItem("hideItem");
    try {
      const kq = await axiosi.put(`queue_members/pause`, {
        reason: "",
        paused: 0,
        membername: user?.agents[0].endpoint_id,
        company_id: user?.agents[0].company_id,
      });
      setPauseStatus(false);
    } catch (error) {
      console.loge("[handleunPause]:error");
    }
  };
  const handleChangeProfile = async () => {
    setHideItem("hideItem");
    console.log("[handleChangeProfile]");
    setVisibleProfile(true);
  };

  const onClosePause = () => {
    setVisiblePause(false);
  };
  const onCloseProfile = () => {
    setVisibleProfile(false);
  };
  /////////////

  const showHideProfileMenu = () => {
    setHideItem((pre) => (pre === "" ? "hideItem" : ""));
  };
  return (
    <>
      {console.log("[App-Return]:user=", user)}
      {console.log("[App-Return]:webrtc=", webrtc)}
      <Drawer
        title="Pause"
        placement="right"
        onClose={onClosePause}
        visible={visiblePause}
      >
        <Pause
          setVisible={setVisiblePause}
          setPauseStatus={setPauseStatus}
          setPauseReason={setPauseReason}
        />
      </Drawer>
      <Drawer
        title="Profile"
        placement="right"
        onClose={onCloseProfile}
        visible={visibleProfile}
      >
        <Profile setVisible={setVisibleProfile} />
      </Drawer>
      <Layout>
        <Header theme="dark" className={"myHeader"}>
          <div className="myHeaderLeft">
            <Title theme="dark" level={3} style={{ color: "white" }}>
              <a className="linkGM" href="" target="_blank">
                <HomeOutlined /> <span>Call-Center</span>{" "}
              </a>
            </Title>
          </div>
          <div className="myHeaderRight">
            <Avatar
              className={userAvatar}
              style={{ cursor: "pointer" }}
              icon={<UserOutlined />}
              title="Click to show/hide Profile Menu !"
              onClick={showHideProfileMenu}
            />
            <span style={{ color: "white" }}>
              {user?.agents && user.agents[0].name}
            </span>
            <ul className={`userMenu ${hideItem}`}>
              {!pauseStatus && (
                <li className="userMenu_item" onClick={handlePause}>
                  Pause
                </li>
              )}
              {pauseStatus && (
                <li className="userMenu_item" onClick={handleunPause}>
                  unPause
                </li>
              )}
              <li className="userMenu_item" onClick={handleChangeProfile}>
                Change profile
              </li>
              <li className="userMenu_item" onClick={handleLogout}>
                Logout
              </li>
            </ul>

            {/* <Button type="link" size={"large"}>
              {user?.agents ? `Hi ${user.agents[0].name}` : ""}
              <span onClick={handleLogout}>[Pause]</span>
              <span onClick={handleLogout} title="Logout">
                <LogoutOutlined />
              </span>
            </Button> */}
          </div>
        </Header>
        {/* <Header theme="dark" className={"myHeader"}>
          <div className="myHeaderLeft">
            <Title theme="dark" level={3} style={{ color: "white" }}>
              Call Center
            </Title>
          </div>
          <div className="myHeaderRight">
            <Button type="link" size={"large"}>
              {user?.agents ? `Hi ${user.agents[0].name}` : ""}
              <br /> <span onClick={handleLogout}>[Logout]</span>
            </Button>
          </div>
        </Header> */}
        <Layout>
          <Sider
            collapsed={collapsed}
            width={200}
            className="site-layout-background"
            onCollapse={toggle}
            collapsible
          >
            {user?.agents ? <MyMenu /> : <span></span>}
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <div className="Phone">
              <div className="PhoneStatus">
                <label>
                  Register Status :{displayExt(endpointid)}
                  {displayRegisterStatus}
                  {/* {isRegister ? " is Registered" : " is not Registered"} */}
                </label>
              </div>
              <div className="PhoneQuickCall">
                <QuickCall />
              </div>
            </div>

            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Routes>
                <Route path="/" element={<Home />} />

                {/* <Route
                  path="/"
                  element={<CallLog refreshCallLog={refreshCallLog} />}
                >
                  <Route
                    path=":page"
                    element={<CallLog refreshCallLog={refreshCallLog} />}
                  />
                </Route> */}
                {checkRoute("/dashboard") && (
                  <Route path="/dashboard" element={<Dashboard />}></Route>
                )}
                {/* <Route path="/calllog" element={<CallLog />}></Route> */}

                {checkRoute("/calllog") && (
                  <Route
                    path="/calllog"
                    element={<CallLog refreshCallLog={refreshCallLog} />}
                  >
                    <Route
                      path=":page"
                      element={<CallLog refreshCallLog={refreshCallLog} />}
                    />
                  </Route>
                )}

                {/* <Route path="/myphone" element={<MyPhone />}></Route>
                <Route path="/callout" element={<CallOut />}></Route> */}

                {checkRoute("/menu_item") && (
                  <Route path="/menu_item" element={<MenuItem />}>
                    <Route path=":page" element={<MenuItem />} />
                  </Route>
                )}
                {checkRoute("/menu_sub") && (
                  <Route path="/menu_sub" element={<MenuSub />}>
                    <Route path=":page" element={<MenuSub />} />
                  </Route>
                )}
                {checkRoute("/authorization_group") && (
                  <Route
                    path="/authorization_group"
                    element={<AuthorizationGroup />}
                  >
                    <Route path=":page" element={<AuthorizationGroup />} />
                  </Route>
                )}
                {checkRoute("/authorization_group_item") && (
                  <Route
                    path="/authorization_group_item"
                    element={<AuthorizationGroupItem />}
                  >
                    <Route path=":page" element={<AuthorizationGroupItem />} />
                  </Route>
                )}

                {checkRoute("/itsps") && (
                  <Route path="/itsps" element={<Itsps />}>
                    <Route path=":page" element={<Itsps />} />
                  </Route>
                )}

                {checkRoute("/companies") && (
                  <Route path="/companies" element={<Companies />}>
                    <Route path=":page" element={<Companies />} />
                  </Route>
                )}

                {checkRoute("/tels") && (
                  <Route path="/tels" element={<Tels />}>
                    <Route path=":page" element={<Tels />} />
                  </Route>
                )}

                {checkRoute("/tels_callout_rule") && (
                  <Route path="/tels_callout_rule" element={<CalloutRule />}>
                    <Route path=":page" element={<CalloutRule />} />
                  </Route>
                )}

                {checkRoute("/agents") && (
                  <Route path="/agents" element={<Agents />}>
                    <Route path=":page" element={<Agents />} />
                  </Route>
                )}
                {checkRoute("/blacklist") && (
                  <Route path="/blacklist" element={<BlackList />}>
                    <Route path=":page" element={<BlackList />} />
                  </Route>
                )}
                {checkRoute("/surveyplan") && (
                  <Route path="/surveyplan" element={<SurveyPlan />}>
                    <Route path=":page" element={<SurveyPlan />} />
                  </Route>
                )}
                {checkRoute("/department") && (
                  <Route path="/department" element={<Department />}>
                    <Route path=":page" element={<Department />} />
                  </Route>
                )}

                {checkRoute("/ivrplanmain") && (
                  <Route path="/ivrplanmain" element={<IVRPlanMain />}>
                    <Route path=":page" element={<IVRPlanMain />} />
                  </Route>
                )}

                {checkRoute("/ivrplanfordaily") && (
                  <Route path="/ivrplanfordaily" element={<IVRPlanForDaily />}>
                    <Route path=":page" element={<IVRPlanForDaily />} />
                  </Route>
                )}

                {checkRoute("/ivrplanforkey") && (
                  <Route path="/ivrplanforkey" element={<IVRPlanForKey />}>
                    <Route path=":page" element={<IVRPlanForKey />} />
                  </Route>
                )}

                {checkRoute("/ivrplanforweekday") && (
                  <Route
                    path="/ivrplanforweekday"
                    element={<IVRPlanForWeekday />}
                  >
                    <Route path=":page" element={<IVRPlanForWeekday />} />
                  </Route>
                )}

                {checkRoute("/ivrplanforday") && (
                  <Route path="/ivrplanforday" element={<IVRPlanForDay />}>
                    <Route path=":page" element={<IVRPlanForDay />} />
                  </Route>
                )}

                {checkRoute("/comcontacts") && (
                  <Route path="/comcontacts" element={<Contacts />}>
                    <Route path=":page" element={<Contacts />} />
                  </Route>
                )}

                {checkRoute("/outboundplan") && (
                  <Route path="/outboundplan" element={<OutboundPlan />}>
                    <Route path=":page" element={<OutboundPlan />} />
                  </Route>
                )}

                {checkRoute("/outbounddata") && (
                  <Route path="/outbounddata" element={<OutBoundData />}>
                    <Route path=":page" element={<OutBoundData />} />
                  </Route>
                )}

                {checkRoute("/outboundagentdata") && (
                  <Route
                    path="/outboundagentdata"
                    element={<AgentOutboundData />}
                  >
                    <Route path=":page" element={<AgentOutboundData />} />
                  </Route>
                )}

                {checkRoute("/znsplan") && (
                  <Route path="/znsplan" element={<ZnsPlan />}>
                    <Route path=":page" element={<ZnsPlan />} />
                  </Route>
                )}
                {checkRoute("/znsdata") && (
                  <Route path="/znsdata" element={<ZnsData />}>
                    <Route path=":page" element={<ZnsData />} />
                  </Route>
                )}

                {checkRoute("/send_sms") && (
                  <Route path="/send_sms" element={<SendSMS />}>
                    <Route path=":page" element={<SendSMS />} />
                  </Route>
                )}

                {checkRoute("/sms_tnth") && (
                  <Route path="/sms_tnth" element={<SMSThuongHieu />}>
                    <Route path=":page" element={<SMSThuongHieu />} />
                  </Route>
                )}

                {checkRoute("/tickets") && (
                  <Route path="/tickets" element={<Tickets />}>
                    <Route path=":page" element={<Tickets />} />
                  </Route>
                )}

                {checkRoute("/autocall") && (
                  <Route path="/autocall" element={<AutoCall />}>
                    <Route path=":page" element={<AutoCall />} />
                  </Route>
                )}

                {checkRoute("/surveydata") && (
                  <Route path="/surveydata" element={<SurveyData />}>
                    <Route path=":page" element={<SurveyData />} />
                  </Route>
                )}

                {checkRoute("/surveycurrentcall") && (
                  <Route
                    path="/surveycurrentcall"
                    element={<SurveyCurrentCall />}
                  >
                    <Route path=":page" element={<SurveyCurrentCall />} />
                  </Route>
                )}

                {checkRoute("/agentstatus") && (
                  <Route path="/agentstatus" element={<AgentStatus />}>
                    <Route path=":page" element={<AgentStatus />} />
                  </Route>
                )}

                {checkRoute("/agenteventsum") && (
                  <Route path="/agenteventsum" element={<AgentLogs />}>
                    <Route path=":page" element={<AgentLogs />} />
                  </Route>
                )}
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
      <div className="App1">
        {/* <TicketNote provinceData={provinceData} /> */}
        {console.log("[RETURN-APP]:mySessionsIdTicket=", mySessionsIdTicket)}
        {mySessionsIdTicket &&
          mySessionsIdTicket.map((id) => (
            <TicketNote
              key={id}
              id={id}
              // mysession={mysessions.current[id]}
              provinceData={provinceData}
              mysessionInfo={mysessionsInfo.current[id]}
              setMySessionsIdTicket={setMySessionsIdTicket}
            />
          ))}

        {/* <CallIn /> */}
        {/* <CallOut /> */}
        {/* <div>
          <h1>Register</h1>
          <input value={sipUser} onChange={(e) => setSipUser(e.target.value)} />
          <button onClick={handleRegister}>Register</button>
        </div> */}
        {/* <MakeCall /> */}
        {/* {mySessionsId && console.log("mySessionId=", mySessionsId)} */}
        {mySessionsId &&
          mySessionsId.map((id) => {
            console.log(
              "mysessionsInfo.current[id]=",
              mysessionsInfo.current[id]
            );
            if (mysessionsInfo.current[id].direction === "in") {
              return (
                <CallIn
                  key={id}
                  id={id}
                  mysession={mysessions.current[id]}
                  mysessionInfo={mysessionsInfo.current[id]}
                  setMySessionsIdTicket={setMySessionsIdTicket}
                />
              );
            } else
              return (
                <CallOut
                  key={id}
                  id={id}
                  mysession={mysessions.current[id]}
                  mysessionInfo={mysessionsInfo.current[id]}
                  setMySessionsIdTicket={setMySessionsIdTicket}
                />
              );
          })}
      </div>
    </>
  );
}
export default App;
