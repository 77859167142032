import React,{useState,useEffect} from 'react';
import { useSelector} from "react-redux";
import {Button,Input,Form,Select,InputNumber} from 'antd';

import useAxios from './../../../CustomHooks/useAxios';
import DebounceSelect from './../../DebounceSelect'
const { Option } = Select;
export default function Add({setRefressSearch,setVisibleAdd}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const user = useSelector((state) => state.loginReducer.user);
  const {axiosi}=useAxios();
  
  
    const [companyid, setCompanyid] = React.useState({value:user.agents[0].company_id,label:user.agents[0].gmcomname});
    

    const [telsoutData,setTelsoutData]=useState(null);
    

    const [telout,setTelout]=useState("");
    const [mainDialPlanData,setMainDialPlanData]=useState(null);
    const [mainDialPlainSelectedid,setMainDialPlainSelectedid]=useState('');
    
    

    useEffect(()=>{
      const getteloutdata=async(value)=>{
        const kq= await axiosi.get('tels',{params:{companyid:value,pageSize:100}});
          console.log("[handleOnChangeCompanyName]:kq_get_tels=",kq);
          console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
          setTelsoutData(kq.data);
          setTelout("");
          
      }
        if(user?.agents[0].company_id){
          getteloutdata(user?.agents[0].company_id);
        } 
          
    },[user?.agents])

    useEffect(()=>{
      const getMainDialplan=async()=>{
        try {
          if (companyid){
        const kq=await axiosi.get('ivrplanmain',{params:{company_id:companyid.value,pageSize:100}});
        console.log("[useEffect-getMainDialplan]:kq=",kq)
        setMainDialPlanData(kq.data.rows);
          } else {
            setMainDialPlanData(null);
          }
        }
        catch (err){
          console.log("[useEffect-getMainDialplan]:err=",err);
        }

      }
      getMainDialplan();
    },[companyid])
   async function fetchUserList(company_name) {        
        console.log('fetching user', company_name);
        const body=await axiosi.get('companies',{params:{company_name,pageSize:100}} );
        if (body?.data?.rows) {
            return body?.data?.rows.map((user) => ({
                label: user.company_name,
                 value: user.id,
                //  value:{id: user.id,company_code:user.company_code},
                 company_code:user.company_code,
               }));
        }
        else {
            const a=[{label:"---no data---",value:""}]
            return a.map(i=> (i))
        }
       
    }

    
    
    const onFinishAdd=async (values)=>{
        
        const teloutSelected=telsoutData?.rows?.find((item)=>item.id===telout)
        let adddata={...values,company_id:companyid.value,telout,maindialplanid :mainDialPlainSelectedid  };
        
        console.log("[onFinishAdd]:adddata=",adddata);

        //  return;
        try {
            const kq=await axiosi.post("surveyplan",adddata);
            console.log("[onFinishAdd]:kq=",kq) 
            setVisibleAdd(false);
            setRefressSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error.response.data:",error.response.data);
                console.log("error.response.status:",error.response.status);
                console.log("error.response.headers:",error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request :",error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error error.message', error.message);
              }
              console.log("error.config:",error.config);
        }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }

    
    const handleOnChangeCompanyName=async(value)=>{
          setCompanyid(value);
          if(value){
          const kq= await axiosi.get('tels',{params:{companyid:value.value,pageSize:100}});
          console.log("[handleOnChangeCompanyName]:kq_get_tels=",kq);
          console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
          setTelsoutData(kq.data);
          setTelout("");
          } else {
            setTelsoutData(null);
            setTelout("");
          }
    }
    
    
    const handleOnChangeTelsout=async(value)=>{
      setTelout(value);
      console.log("[handleOnChangeTelsout]:value=",value);
      console.log("[handleOnChangeTelsout]:telsoutData=",telsoutData);
    }

    const handleOnChangeMainDialPlan=(value)=>{
      setMainDialPlainSelectedid(value);
      console.log("[handleOnChangeMainDialPlan]:value=",value)
    }
    
    function onSearch(val) {
      console.log('search:', val);
    }
   
    
  return (<>
   

  {/* {console.log("[return]:ivrVisible=",ivrVisible)} */}
        <Form
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      initialValues={{ordnum:0 ,
        numcurrentcall:5,
        allownumcall:2,
        minutebetweencall:300,
        allowdaycall:1,
        manualsurvey:0
        
      }}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
      
      
      <Form.Item  className="hideItem"
        label="ordnum :"
        name="ordnum"
        rules={[{ required: true, message: 'Please input an ordnum!' }]}
      >
        <Input  />        
      </Form.Item>

        <Form.Item className={hideItem}
        label="Company Name"
        // name="company_name"
        rules={[{ required: true, message: 'Please input a company_name!' }]}
      >
        <DebounceSelect
          showSearch={true}
          value={companyid}
          placeholder="Select users"
          fetchOptions={fetchUserList}
          onChange={handleOnChangeCompanyName}          
          style={{  width: '100%', }}
        />
      </Form.Item>
      <Form.Item
        label="Name :"
        name="name"
        rules={[{ required: true, message: 'Please input a survey name!' }]}
      >
        <Input  />        
      </Form.Item>
      <Form.Item
        label="Tel out :"
        // name="pbxtel"
        rules={[{ required: false, message: 'Please input a Tel out numbe!' }]}
      >
        {telsoutData?.rows && <>
            <Select style={{ width: '100%' }} 
                    onChange={handleOnChangeTelsout}
                    //(value)=>setMenu_item_id(value)}
                    // loading={menuItemDataLoading}
                    value={telout}                   
            >
                        <Option value=""></Option>
                        { telsoutData?.rows && telsoutData.rows.map(item=>(
                           <Option key={item.telin} value={item.telin}>{item.telin}</Option>
                       ))}                       
            </Select>
        
      </>}
      </Form.Item>

      


      
      <Form.Item
        label="numcurrentcall :"
        name="numcurrentcall"
        rules={[{ required: true, message: 'Please input a numcurrentcall!' }]}
      >
        <InputNumber min={1}   />  
        </Form.Item>
        <Form.Item
        label="allownumcall :"
        name="allownumcall"
        rules={[{ required: true, message: 'Please input a allownumcall!' }]}
      >
        <InputNumber min={1}   />  
        </Form.Item>
        <Form.Item
        label="minutebetweencall :"
        name="minutebetweencall"
        rules={[{ required: true, message: 'Please input a minutebetweencall!' }]}
      >
        <InputNumber min={1}   />  
        </Form.Item>
        <Form.Item
        label="allowdaycall :"
        name="allowdaycall"
        rules={[{ required: true, message: 'Please input a allowdaycall!' }]}
      >
        <InputNumber min={1}   />  
        </Form.Item>
        

      <Form.Item
      
        label="maindialplanid :"
        // name="maindialplanid"
        rules={[{ required: true, message: 'Please input a maindialplanid!' }]}
      >
        {mainDialPlanData && <>
            <Select style={{ width: '100%' }}  showSearch
                    onChange={handleOnChangeMainDialPlan}
                    //(value)=>setMenu_item_id(value)}
                    // loading={menuItemDataLoading}
                    value={mainDialPlainSelectedid}
                    optionFilterProp="children"
                    placeholder="Select a person"
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    
            >
                        <Option value=""></Option>
                        { mainDialPlanData && mainDialPlanData.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
            </Select>
        
      </>}     
      
      </Form.Item>
      

        
      <Form.Item
        label="manualsurvey :"
        name="manualsurvey"
        rules={[{ required: true, message: 'Please input a manualsurvey!' }]}
      >
        <Select style={{ width: '100%' }}>
            <Option value={0}>No</Option>
            <Option value={1}>Yes</Option>
          </Select>       
      </Form.Item>
      

     
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
  </>);
}
