import React,{useEffect,useState,useRef} from 'react'
import { useDispatch, useSelector} from "react-redux";
import { useParams ,Link} from "react-router-dom";
import moment from 'moment';
import { Drawer,Form,Button,Row, Col,Divider,Typography ,DatePicker,Select,Input } from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import useAxios from './../../CustomHooks/useAxios';

import ShowCompaniesTable from './ShowCompaniesTable';
import PageLink from './../PageLink/PageLink';
import AddCompanies from "./AddCompanies";

const { Text } = Typography;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
export default function Companies() {
    let { page } = useParams();
    const {axiosi}=useAxios();

    const [visibleAdd, setVisibleAdd] = useState(false);
    const [data,setData]=useState();
    const [refreshSearch,setRefreshSearch]=useState(true);

    const [company_name,setCompany_name]=useState('');
    const [contactname,setContactname]=useState('');
    const [contacttel,setContacttel]=useState('');

    const handleAdd=()=>{
        console.log("[handleAdd]:");
        setVisibleAdd(true);
    }
    const onCloseAdd=()=>{
        setVisibleAdd(false);
        
    }
    useEffect(()=>{
        doSearch();
    },[refreshSearch,page]);

    

    const handleSearch= ()=>{
        console.log("[handleSearch]");
        
        setRefreshSearch(pre=>!pre)
    }
    const doSearch= async()=>{
        console.log("[search]");
        const kq=await axiosi.get('companies',{params:{page,pageSize:20,company_name,contactname,contacttel}});
        console.log("[handleSearch]:kq.data=",kq.data);
        setData(kq.data);
    }
  return (<>
  
        <Drawer title="Add  Menu Item" placement="right" onClose={onCloseAdd} visible={visibleAdd}>             
                    <AddCompanies setVisibleAdd={setVisibleAdd} setRefreshSearch={setRefreshSearch} />        
            </Drawer>
        <section className='search'>
        <div className="container">
            <div className="row">
                <Typography.Title  level={3}>Search :
                <Button onClick={handleAdd}  className="addButton" icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button>
                </Typography.Title>
                
            </div>
            
            <div className="row">
            {/* <Input.Group compact>      
                <Input.Search addonBefore="ITSP Name: " enterButton 
                 onSearch={handleSearch} allowClear 
                 style={{ width: '70%' }} defaultValue="" />
            </Input.Group> */}
                <div className="col">
                    <label>Company name :</label>
                    <Input  onChange={({ target: { value } } )=>setCompany_name(value)}/>
                </div>
                <div className="col">
                    <label>Contact name :</label>
                    <Input  onChange={({ target: { value } } )=>setContactname(value)}/>
                </div>
                <div className="col">
                    <label>Contact Tel :</label>
                    <Input  onChange={({ target: { value } } )=>setContacttel(value)}/>
                </div>
                <div className="col">
                    <label>Search:</label>
                    <Button onClick={handleSearch}>Search :</Button>
                </div>
                
            </div>
        </div>

    </section>
    <section className='searchResult'>
        <div className="row">
        <Typography.Title  level={3}>Result</Typography.Title>
        </div>
        <div className="row">
            {data?.rows && <ShowCompaniesTable data={data.rows} setRefreshSearch={setRefreshSearch} />}
        </div>
        <div className="row">
        {data?.pages>1?<PageLink pageSize={data.pageSize} searchpage={"companies"} pages={data.pages} numrows={data.numrows} page={data.page} />:<h1>chi co' 1 page</h1>}
        </div>
        

    </section>
  </>);
}
