import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Button, Drawer, Input, Space, Form, Image, Anchor } from "antd";
import {
  PhoneOutlined,
  DownloadOutlined,
  PlusCircleFilled,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
// const { Link } = Anchor;
import moment from "moment";
// import Upload from "./Upload";
import Edit from "./Edit";
import Delete from "./Delete";
import MarkDelete from "./MarkDelete";
import CallTo from "./../../CallTo";

const dateFormat = "YYYY-MM-DD HH:mm:ss";
export default function ShowTable({ data, setRefressSearch, provinceData }) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  console.log("[outboundplan-ShowTable]:hideItem=", hideItem);
  const user = useSelector((state) => state.loginReducer.user);

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleRemove, setVisibleRemove] = useState(false);

  const [visibleTemplateParam, setVisibleTemplateParam] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);

  const makeCall = useSelector((state) => state.jsSIPReducer.makeCall);

  const handleEdit = (item) => {
    console.log("[handleEdit]:item=", item);
    setSelectedItem(item);
    setVisibleEdit(true);
  };
  const handleRemove = (item) => {
    console.log("[handleRemove]:item=", item);
    setVisibleRemove(true);
    setSelectedDeleteItem(item);
  };

  const handleShowTemplateParam = (item) => {
    console.log("[handleShowTemplateParam]:item=", item);
    setVisibleTemplateParam(true);
    setSelectedDeleteItem(item);
  };

  const onCloseEdit = () => {
    console.log("[onCloseEdit]");
    setSelectedItem(null);
    setVisibleEdit(false);
  };
  const onCloseRemove = () => {
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
  };
  const onCloseTemplateParam = () => {
    setVisibleTemplateParam(false);
    // setSelectedDeleteItem(null);
  };
  const handleMakeCall = (callto) => {
    makeCall(callto);
  };
  const getDepName = (depid) => {
    return user?.deps[depid];
  };
  const getEditInfo = (item) => {
    let agetntcode = item.editagentcode.split("_")[0];
    let kq = item.editagentname + " - " + agetntcode;

    return kq;
  };
  return (
    <>
      <Drawer
        width={640}
        title="Edit Item"
        placement="right"
        onClose={onCloseEdit}
        visible={visibleEdit}
      >
        {selectedItem && (
          <Edit
            provinceData={provinceData}
            item={selectedItem}
            setSelectedItem={setSelectedItem}
            setRefressSearch={setRefressSearch}
            setVisibleEdit={setVisibleEdit}
          />
        )}
      </Drawer>

      <Drawer
        title="Remove Item"
        placement="right"
        onClose={onCloseRemove}
        visible={visibleRemove}
      >
        {selectedDeleteItem && (
          <MarkDelete
            item={selectedDeleteItem}
            setRefressSearch={setRefressSearch}
            setVisibleRemove={setVisibleRemove}
          />
        )}
      </Drawer>

      <Drawer
        title="template_data_param"
        placement="right"
        onClose={onCloseTemplateParam}
        visible={visibleTemplateParam}
      >
        {selectedDeleteItem && (
          <Delete
            item={selectedDeleteItem}
            setRefressSearch={setRefressSearch}
            setVisibleTemplateParam={setVisibleTemplateParam}
          />
        )}
      </Drawer>
      <table className="table1">
        <thead>
          <tr>
            {/* <th>id</th> */}
            {/* <th>calldirection</th>
      <th>connected</th> */}
            {hideItem != "hideItem" ? (
              <th width="220px">GM's Customer</th>
            ) : (
              <th className={hideItem}></th>
            )}

            <th>Name</th>
            <th>Department</th>
            <th>ZNS Template</th>

            <th>Note</th>
            <th>Update</th>

            <th width="120px">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <>
              <tr key={item.id}>
                {/* <td>{item.id}</td> */}
                {/* <td>{item.calldirection}</td>
      <td>{item.connected}</td> */}

                {hideItem != "hideItem" ? (
                  <td>{item.company_name}</td>
                ) : (
                  <td className={hideItem}></td>
                )}

                <td>{item.name}</td>
                <td>{getDepName(item.department_id)}</td>
                <td>
                  <span className="stitle">template_id: </span>
                  <span className="svalue">{item.template_id}</span> <br />
                  <span className="stitle">template_name: </span>
                  <span className="svalue">{item.template_name}</span> <br />
                  <span className="stitle">template_image_preview: </span>
                  <span className="svalue">
                    <a
                      href={item.template_image_preview}
                      target="_blank"
                      title="Basic demo"
                    >
                      Click to view
                    </a>
                  </span>
                  {/* <span className="stitle">template_data_param</span>
                <span className="svalue">{item.template_data_param}</span>
                <br /> */}
                </td>
                <td>{item.note}</td>
                <td>
                  {getEditInfo(item)} <br />[{" "}
                  {moment(item.edittime).format(dateFormat)}]
                </td>

                <td>
                  {/* <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
                  <div className="rowFlex">
                    <Button
                      onClick={() => handleEdit(item)}
                      icon={<EditFilled style={{ color: "hotpink" }} />}
                    >
                      Edit
                    </Button>
                    <Button onClick={() => handleRemove(item)}>
                      <DeleteFilled style={{ color: "red" }} />
                      Remove
                    </Button>
                    {/*<Button
                      onClick={() => handleShowTemplateParam(item)}
                      icon={<EditFilled style={{ color: "hotpink" }} />}
                    >
                      Show Template Param
              </Button> */}
                    {/* <Upload/> */}
                  </div>
                  {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
    Download
  </Button> */}
                </td>
              </tr>
              <tr>
                <td colspan="100%">{item.template_data_param}</td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </>
  );
}
