import React,{useRef,useState} from 'react';
import { useSelector} from "react-redux";
import {Button,Drawer,Input,Space,Form} from 'antd';
import {StepForwardOutlined, DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
// import Upload from "./Upload";

import axios from 'axios';
import Edit from "./Edit";
import Delete from "./Delete";
import useAxios from './../../../CustomHooks/useAxios';

export default function ShowTable({data,setRefressSearch}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const {axiosi}=useAxios();
    const refAudio = useRef();
    const [displayAudio,setDisplayAudio]=useState("none");
    const handlePlayAudio=async(id,uniqueid)=>{
        // refAudio.current.pause();
        setDisplayAudio("block");
        const kq=await axiosi.post('users/getFileLink',{id,uniqueid});
        // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
        // refAudio.current.src=`https://gcall.com.vn:2943/ivrplanmain/fileLink?fileLink=${kq.data.fileLink}`
        refAudio.current.src=`${process.env.REACT_APP_DOMAIN}/ivrplanmain/fileLink?fileLink=${kq.data.fileLink}`
        refAudio.current.play();
    }
    const handleDownloadAudio=async(id,uniqueid,name)=>{
      // refAudio.current.pause();
      const kq=await axiosi.post('users/getFileLink',{id,uniqueid});
      // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
      // const linkfile=`https://gcall.com.vn:2943/ivrplanmain/fileLink?fileLink=${kq.data.fileLink}`
      const linkfile=`${process.env.REACT_APP_DOMAIN}/ivrplanmain/fileLink?fileLink=${kq.data.fileLink}`
      // window.open(linkfile);
      axios({
          url: linkfile, //your url
          method: 'GET',
          responseType: 'blob', // important
      }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${uniqueid}_${id}_${name}.wav`); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
      });
  }
  const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleRemove, setVisibleRemove] = useState(false);
    
    const [selectedItem,setSelectedItem]=useState(null);
    const [selectedDeleteItem,setSelectedDeleteItem]=useState(null);

    const handleEdit=(item)=>{
      console.log("[handleEdit]:item=",item);
      setSelectedItem(item);
      setVisibleEdit(true);
  }
  const handleRemove=(item)=>{
      console.log("[handleRemove]:item=",item);
      setVisibleRemove(true);
      setSelectedDeleteItem(item);
  }

  const onCloseEdit=()=>{
    console.log("[onCloseEdit]")
    setSelectedItem(null);
    setVisibleEdit(false);
}
  const onCloseRemove=()=>{
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
}
 const renderLang=(lang)=>{
   switch (lang) {
     case "vi":
       return "Việt Nam 1";
       case "hcm-diemmy":
       return "Việt Nam 2";
       case "en":
       return "English";
       default:
         return lang;
   }
 }
  const renderValue=(item)=>{
    let filename=`/mnt/data/records/${item.company_id}/ivrsounds/${item.id}`;
    switch (item.iaction){
      case "PlayTTS":        
        return (<>
                   
                  <div><span>TextToSpeech : </span><span>{item.txtToSpeech}</span></div>  
                  <div><span>TTS Voice : </span><span>{renderLang(item.lang)}</span></div>
                </>)
        


      case "Play":
        
        return (<>
        {/* <span>File:</span><span>{filename}</span> */}
       {item.fileExisted && (<Button type="link" onClick={()=>handlePlayAudio(item.id,item.company_id)}><StepForwardOutlined />Play</Button>)}
       {item.fileExisted && ( <Button type="link" onClick={()=>handleDownloadAudio(item.id,item.company_id,item.name)}><DownloadOutlined />Download</Button> )}
        </>)
        break;
      case "Read":        
        return (<>
                  {/* <div><span>File:</span><span>{filename}</span></div> */}
                  <div><span>maxdigits:</span><span>{item.maxdigits}</span></div>
                  <div><span>attempts:</span><span>{item.attempts}</span></div>
                  <div><span>timeout:</span><span>{item.timeout}</span></div>
                  {item.fileExisted && (<Button type="link" onClick={()=>handlePlayAudio(item.id,item.company_id)}><StepForwardOutlined />Play</Button>)}
       {item.fileExisted && ( <Button type="link" onClick={()=>handleDownloadAudio(item.id,item.company_id,item.name)}><DownloadOutlined />Download</Button> )}
                </>)
      
      case "ReadTTSKey":        
        return (<>
                  <div><span>TextToSpeech : </span><span>{item.txtToSpeech}</span></div>  
                  <div><span>TTS Voice : </span><span>{renderLang(item.lang)}</span></div>
                  <div><span>maxdigits:</span><span>{item.maxdigits}</span></div>
                  <div><span>attempts:</span><span>{item.attempts}</span></div>
                  <div><span>timeout:</span><span>{item.timeout}</span></div>
                 
                </>)
      
      case "ReadTTSVoice":        
        return (<>
                  <div><span>TextToSpeech : </span><span>{item.txtToSpeech}</span></div>  
                  <div><span>TTS Voice : </span><span>{renderLang(item.lang)}</span></div>
                  <div><span>attempts:</span><span>{item.attempts}</span></div>
                  <div><span>timeout:</span><span>{item.timeout}</span></div>
                  
                </>)

      case "Dial":        
        return (<>
                  <div><span>ito:</span><span>{item.ito}</span></div>                  
                  <div><span>timeout:</span><span>{item.timeout}</span></div>
                </>)
        

      case "Queue":        
        return (<>
                   
                  <div><span>ito:</span><span>{item.ito}{item.id}</span></div>  
                  <div><span>timeout:</span><span>{item.timeout}</span></div>
                </>)
        

      case "Forward":        
        return (<>
                   <div><span>ito:</span><span>{item.ito}</span></div> 
                  <div><span>forwardtelid:</span><span>{item.forwardtelid}</span></div>                  
                  <div><span>timeout:</span><span>{item.timeout}</span></div>
                </>)
      
      case "VM":        
      return (<>                
                <div><span>timeout:</span><span>{item.timeout}</span></div>
              </>)

      default:
        return (<><span>File:</span><span>chua lam</span></>)
          break;
    }
  }
return (
  <>
  
      <Drawer title="Edit  Menu Item" placement="right" onClose={onCloseEdit} visible={visibleEdit}>
          {selectedItem && <Edit item={selectedItem} setSelectedItem={setSelectedItem} setRefressSearch={setRefressSearch} setVisibleEdit={setVisibleEdit} />}        
      </Drawer>

      <Drawer title="Remove  Menu Item" placement="right" onClose={onCloseRemove} visible={visibleRemove}>
          {selectedDeleteItem && <Delete item={selectedDeleteItem} setRefressSearch={setRefressSearch} setVisibleRemove={setVisibleRemove}/>}        
      </Drawer>

      <table className='table1'>
  <thead>
  <tr>
      {/* <th>id</th> */}
      {/* <th>calldirection</th>
      <th>connected</th> */}
      
      <th width="220px" className={hideItem}>company_name</th>
      <th>name</th>
      <th>Action</th>
      <th>Value</th>
      <th>Next IVR Main Plan</th>
      
      
      
      <th width="120px">Action</th>
      

  </tr>
  </thead>
  <tbody>
{data.map(item=>(
  <tr key={item.id}>
      {/* <td>{item.id}</td> */}
      {/* <td>{item.calldirection}</td>
      <td>{item.connected}</td> */}
      
      
      <td className={hideItem}>{item.company_name}</td>
      <td>{item.name}</td>
      <td>{item.iaction}</td>
      <td>{renderValue(item)}</td>
      <td>{item.nextmaindialplanname}</td>
      
      
      
      <td> 
         {/* <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
         <div className='rowFlex'>
             <Button onClick={()=>handleEdit(item)} icon={<EditFilled  style={{ color: 'hotpink' }}  />}>Edit</Button>
              <Button onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Remove</Button>
              {/* <Upload/> */}
         </div>
         {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
    Download
  </Button> */}
      </td>
      
  </tr>
))}
</tbody>
</table>

        <div className='callLogAudio' style={{"display":displayAudio}} >
            <audio ref={refAudio} controls>
                <source src={""} type="audio/wav" />                
                Your browser does not support the audio element.
            </audio>
            <Button type="link" onClick={()=>setDisplayAudio("none")}>Close Audio</Button>
        </div>
  </>
)
}