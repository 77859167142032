import React from 'react';
import { useSelector} from "react-redux";
import {useState,useEffect} from 'react';
import {Button,Drawer,Input,Space,Form} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
// import Upload from "./Upload";
import Edit from "./Edit";
import Delete from "./Delete";

export default function ShowTable({data,setRefressSearch}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleRemove, setVisibleRemove] = useState(false);
    
    const [selectedItem,setSelectedItem]=useState(null);
    const [selectedDeleteItem,setSelectedDeleteItem]=useState(null);

    const handleEdit=(item)=>{
      console.log("[handleEdit]:item=",item);
      setSelectedItem(item);
      setVisibleEdit(true);
  }
  const handleRemove=(item)=>{
      console.log("[handleRemove]:item=",item);
      setVisibleRemove(true);
      setSelectedDeleteItem(item);
  }

  const onCloseEdit=()=>{
    console.log("[onCloseEdit]")
    setSelectedItem(null);
    setVisibleEdit(false);
}
  const onCloseRemove=()=>{
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
}

return (
  <>
  
      <Drawer title="Edit  Menu Item" placement="right" onClose={onCloseEdit} visible={visibleEdit}>
          {selectedItem && <Edit item={selectedItem} setRefressSearch={setRefressSearch} setVisibleEdit={setVisibleEdit} setSelectedItem={setSelectedItem} />}        
      </Drawer>

      <Drawer title="Remove  Menu Item" placement="right" onClose={onCloseRemove} visible={visibleRemove}>
          {selectedDeleteItem && <Delete item={selectedDeleteItem} setRefressSearch={setRefressSearch} setVisibleRemove={setVisibleRemove}/>}        
      </Drawer>

      <table className='table1'>
  <thead>
  <tr>
      {/* <th>id</th> */}
      {/* <th>calldirection</th>
      <th>connected</th> */}
      
      <th width="220px" className={hideItem}>company_name</th>
      <th>Main IVR Plan</th>
      <th>IVR Key</th>
      
      <th>Next IVR Main Plan</th>
      
      
      
      <th width="120px">Action</th>
      

  </tr>
  </thead>
  <tbody>
{data.map(item=>(
  <tr key={item.id}>
      {/* <td>{item.id}</td> */}
      {/* <td>{item.calldirection}</td>
      <td>{item.connected}</td> */}
      
      
      <td  className={hideItem}>{item.company_name}</td>
      <td>{item.maindialplanname}</td>
      <td>{item.ikey}</td>
      
      <td>{item.nextmaindialplanname}</td>
      
      
      
      <td> 
         {/* <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
         <div className='rowFlex'>
             <Button onClick={()=>handleEdit(item)} icon={<EditFilled  style={{ color: 'hotpink' }}  />}>Edit</Button>
              <Button onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Remove</Button>
              {/* <Upload/> */}
         </div>
         {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
    Download
  </Button> */}
      </td>
      
  </tr>
))}
</tbody>
</table>
  </>
)
}