import React, { useState, useEffect } from "react";

export default function DashboardDisplay({ callLogsSumALl }) {
  const [data, setData] = useState(callLogsSumALl);
  const [pickupSecond, setPickupSecond] = useState(process.env.REACT_APP_PICKUP_SECOND);
  //   console.log("[DashboardDisplay]:callLogsSumALl=", callLogsSumALl);
  const getStatistic = (key) => (data ? data[key] || 0 : 0);
  const formatTime = (second) => {
    // return("second");

    const b = 86400;
    // second += b*22;
    if (second >= b) {
      let giay = second % b;
      let ngay = (second - giay) / b;
      //new Date(3600*24 * 1000).toISOString().substr(11, 8)
      return (
        `${ngay}d.` + new Date(second * 1000).toISOString().substring(11, 19)
      );
      //${moment(giay).format("HH:mm:ss")}`;
      //+ new Date(giay * 1000).toISOString().substr(11, 8);
      //${moment(giay).format(dateFormat)}`;
    } else {
      return new Date(second * 1000).toISOString().substring(11, 19); //
      // return `${moment(12).format("HH:mm:ss")}`;
    }
    //return `${moment(second).format(dateFormat)}`;
    //new Date(giay * 1000).toISOString().substr(11, 8);//
  };

  useEffect(() => setData(callLogsSumALl), [callLogsSumALl]);
  return (
    <div className="DashboardDisplay">
      <h2 className="gachduoi">CallIn Summary</h2>
      {console.log("[DashboardDisplay]:data=", data)}
      <div className="dashBoard_flex-container">
        <div className="dashBoard_flex-item">
          <div className="itemLeft">
            <div className="boxChild-Lable">Call in</div>
            <div className="boxChild-Value">
              {getStatistic("number_incalls")}
            </div>
          </div>
          <div className="itemRight">
            <ul>
              <li>Answered :{getStatistic("answered_calls")}</li>
              <li>
                Missed :
                {getStatistic("hangup_before_calls") +
                  getStatistic("missed_inqueue_calls") +
                  getStatistic("missed_noagentpickup_calls")}
              </li>
              <li>Abandoned :{getStatistic("abandoned_calls")}</li>
              <li>
                Abandoned ratio :
                {data
                  ? (
                      (data.abandoned_calls * 100) /
                      data.number_incalls
                    ).toFixed(2)
                  : 0}{" "}
                %
              </li>
            </ul>
          </div>
        </div>
        <div className="dashBoard_flex-item">
          <div className="itemLeft">
            <div className="boxChild-Lable">Answered</div>
            <div className="boxChild-Value">
              {getStatistic("answered_calls")}
            </div>
          </div>
          <div className="itemRight">
            <ul>
              <li>
                Avg Talktime :{formatTime(getStatistic("answered_calls"))}
              </li>
              <li>Pickup [≤{pickupSecond}s] :{getStatistic("queue_pickup_less_eq7s")}</li>
              <li>
                Pickup [≤{pickupSecond}s] (%) :
                {(
                  (getStatistic("queue_pickup_less_eq7s") * 100) /
                  getStatistic("answered_calls")
                ).toFixed(2)}
              </li>
            </ul>
          </div>
        </div>
        <div className="dashBoard_flex-item">
          <div className="itemLeft">
            <div className="boxChild-Lable">Missed</div>
            <div className="boxChild-Value">
              {getStatistic("hangup_before_calls") +
                getStatistic("missed_inqueue_calls") +
                getStatistic("missed_noagentpickup_calls")}
            </div>
          </div>
          <div className="itemRight">
            <ul>
              <li>Hangup before :{getStatistic("hangup_before_calls")}</li>
              <li>InQueue:{getStatistic("missed_inqueue_calls")}</li>
              <li>
                NoAgent Pickup :{getStatistic("missed_noagentpickup_calls")}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <h2 className="gachduoi">CallOut Summary</h2>
      <div className="dashBoard_flex-container">
        <div className="dashBoard_flex-item">
          <div className="itemLeft">
            <div className="boxChild-Lable">Call out</div>
            <div className="boxChild-Value">
              {getStatistic("number_outcalls")}
            </div>
          </div>
        </div>
        <div className="dashBoard_flex-item">
          <div className="itemLeft">
            <div className="boxChild-Lable">Connected</div>
            <div className="boxChild-Value">
              {getStatistic("out_connected")}
            </div>
          </div>
        </div>
        <div className="dashBoard_flex-item">
          <div className="itemLeft">
            <div className="boxChild-Lable">Avg talktime</div>
            <div className="boxChild-Value">
              {formatTime(getStatistic("average_talktime_out"))}
            </div>
          </div>
        </div>
        <div className="dashBoard_flex-item">
          <div className="itemLeft">
            <div className="boxChild-Lable">NotConnected</div>
            <div className="boxChild-Value">
              {getStatistic("out_notconnected")}
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
