import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import moment from "moment";
import {
  Drawer,
  Button,
  Typography,
  Input,
  Switch,
  Checkbox,
  Select,
  DatePicker,
} from "antd";
import { PlusCircleFilled } from "@ant-design/icons";

import useAxios from "./../../../CustomHooks/useAxios";
import ShowTable from "./ShowTable";
// import ShowTableCount from './ShowTableCount';

import PageLink1 from "./../../PageLink/Page"; //PageLink';
import Add from "./Add";
// import Upload from "./Upload";
// import UploadAntd from "./UploadAntd";
import DebounceSelect from "./../../DebounceSelect";

const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";
const dateFormat1 = "YYYY-MM-DD HH:mm";
export default function AgentLogs() {
  //   let { page } = useParams();
  const [page, setPage] = useState();
  let navigate = useNavigate();
  const { axiosi } = useAxios();

  const [sFrom, setSFrom] = useState(
    moment(moment().format(dateFormat), dateFormat1)
  );
  const [sTo, setSTo] = useState(
    moment(moment().add(1, "days").format(dateFormat), dateFormat1)
  );
  const user = useSelector((state) => state.loginReducer.user);

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [data, setData] = useState();

  // const [companyid, setCompanyid] = React.useState();
  const [companyid, setCompanyid] = React.useState({
    value: user.agents[0].company_id,
    label: user.agents[0].gmcomname,
  });
  const [tel, setTel] = useState("");
  const [name, setName] = useState("");

  const [surveyplan_id, setSurveyplan_id] = React.useState();

  const [enableAutoRefresh, setEnableAutoRefresh] = useState(true);
  const [loaddingData, setLoaddingData] = useState(false);
  const [refressSearch, setRefressSearch] = useState(true);
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const [enableSearch, setEnableSearch] = useState(true);
  const [agentStatus, setAgentStatus] = useState("");

  const [departmentData, setDepartmentData] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [exten, setExten] = useState(null);
  const [extenValue, setExtenValue] = useState("");
  const [refreshOptionExten, setRefreshOptionExten] = useState(true);
  const handleOnChangeIto = async (value) => {
    // console.log("[handleOnChangeIto]:value=",value);
    // console.log("[handleOnChangeIto]:extenValue=",extenValue);
    setExten(value);
    setExtenValue(displayExt(value.value));
  };
  const handleAgentOnClear = () => {
    setExten(null);
    setExtenValue("");
  };
  useEffect(() => {
    const getDepartmentData = async () => {
      try {
        const kq = await axiosi.get("department", {
          params: { company_id: companyid.value, pageSize: 100 },
        });
        console.log(kq.data.rows);
        setDepartmentData(kq.data.rows);
        setDepartmentSelected("");
      } catch (err) {
        setDepartmentData(null);
        console.log("[useEffect]:getDepartmentData has an Error !");
      }
    };
    getDepartmentData();
  }, [companyid?.value]);

  useEffect(() => {
    // setExtens(null);
    setExten(null);

    setRefreshOptionExten((pre) => !pre);
  }, [companyid?.value]);

  useEffect(() => {
    doSearch();

    // return()=> {
    //     console.log('[useEffect]:set setEnableSearch=false');
    //     // setEnableSearch(false);
    // }
  }, [refressSearch, page, extenValue]);

  async function fetchUserList(company_name) {
    console.log("fetching user", company_name);
    const body = await axiosi.get("companies", {
      params: { company_name, pageSize: 100 },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
    // return axiosi.get('companies',{params:{company_name,pageSize:100}} ) // fetch('https://randomuser.me/api/?results=5')
    //   .then((body) =>
    //     body?.data?.rows.map((user) => ({
    //      label: user.company_name,
    //       value: user.id,
    //     })),
    //   );
  }
  async function fetchSurveyPlan(surveyplan_name) {
    console.log("fetchSurveyPlan", surveyplan_name);
    const body = await axiosi.get("surveyplan", {
      params: { company_id: companyid, name: surveyplan_name, pageSize: 100 },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.name,
        value: user.id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
    // return axiosi.get('companies',{params:{company_name,pageSize:100}} ) // fetch('https://randomuser.me/api/?results=5')
    //   .then((body) =>
    //     body?.data?.rows.map((user) => ({
    //      label: user.company_name,
    //       value: user.id,
    //     })),
    //   );
  }
  const handleAdd = () => {
    console.log("[handleAdd]:");
    setVisibleAdd(true);
  };
  const onCloseAdd = () => {
    setVisibleAdd(false);
  };
  const handleSearch = async () => {
    console.log("[handleSearch]");
    // setRefressSearch(pre=>!pre)
    if (page === 1) {
      console.log("[btnSearchOnClick page=1] : set setRefressSearch:");
      setRefressSearch((pre) => !pre);
    } else {
      console.log("[btnSearchOnClick page!=1] : set setpage=1:");
      setPage(1);
    }
  };
  const doSearch = async () => {
    try {
      setLoaddingData(true);
      let timeFrom = "";
      if (typeof sFrom === "object") {
        timeFrom = sFrom.format(dateFormat1);
        console.log("sFrom=", sFrom.format(dateFormat1));
      } else {
        console.log("sFrom STRING=", sFrom);
        if (sFrom) {
          timeFrom = sFrom;
          console.log("CÓ sFROM");
        } else {
          console.log("KG CÓ SFORM");
        }
      }

      let timeTo = "";
      if (typeof sTo === "object") {
        timeTo = sTo.format(dateFormat1);
        console.log("sTo:=", sTo.format(dateFormat1));
      } else {
        timeTo = sTo;
        console.log("sTo STRING:=", sTo);
      }

      //   console.log({exten,extenValue});
      const kq = await axiosi.get("agenteventsum1", {
        params: {
          page,
          pageSize: 20,
          timeFrom,
          timeTo,
          company_id: companyid?.value,
          department_id: departmentSelected,
          sexten: exten?.value,
        },
      });
      // console.log("[handleSearch]:kq=",kq);
      if (!enableSearch) return;
      setLoaddingData(false);
      setData(kq.data);
    } catch (err) {
      console.log("ERROR");
      setLoaddingData(false);
    }
  };
  async function onChangeEnableRefresh(checked) {
    console.log(`switch to :`, checked.target.checked);
    setEnableAutoRefresh(checked.target.checked);
    if (!loaddingData && checked.target.checked) {
      setTimeout(() => {
        setRefressSearch((pre) => !pre);
      }, 3000);
    }
  }
  const handleOnChangeDepartment = (value) => {
    console.log("[handleOnChangeDepartment]:value=", value);
    setDepartmentSelected(value);
    setExten(null);
  };
  const handleOnChangeAgentStatus = (value) => {
    console.log("[handleOnChangeAgentStatus]:value=", value);
    setAgentStatus(value);
  };

  async function fetchExtenList(exten) {
    console.log("[fetchExtenList]:exten=", exten);
    const body = await axiosi.get("agents/searchExten", {
      params: {
        company_id: companyid?.value,
        department_id: departmentSelected,
        exten,
        pageSize: 200,
      },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.name,
        value: user.endpoint_id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
  }
  const displayExt = (endpointid) => {
    // console.log("[displayExt]:endpointid=",endpointid);
    if (endpointid) {
      const ext = endpointid.split("_");
      return ext[0];
    } else {
      return "";
    }
  };
  function onChangeSFrom(date, dateString) {
    // console.log("[onChangeSFrom]:",date, dateString);
    // if (!date) {
    //   console.log("[onChangeSFrom]:setSFrom('2022-05-01 00:00')");
    //   setSFrom('2022-01-01 00:00');
    // return;
    // };
    setSFrom(dateString);
  }
  function onChangeSTo(date, dateString) {
    // console.log(date, dateString);
    setSTo(dateString);
  }
  function checkLogDep(item) {
    if (user.agents[0].company_id === 1) return true;
    const logdeps = user.agents[0].logdepartments;

    return logdeps.includes(item.id);
  }
  return (
    <>
      {/* <Upload/>
  <UploadAntd/> */}
      <Drawer
        title="Add  Menu Item"
        placement="right"
        onClose={onCloseAdd}
        visible={visibleAdd}
      >
        <Add
          setRefressSearch={setRefressSearch}
          setVisibleAdd={setVisibleAdd}
        />
        {/* <Switch  size="small" checkedChildren="Running" unCheckedChildren="Stopped" checked={enableAutoRefresh} onChange={onChangeEnableRefresh} />        */}
      </Drawer>
      <section className="search">
        <div className="container">
          <div className="row">
            <Typography.Title level={3}>
              Search :
              <Checkbox
                className="addButton1"
                checked={enableAutoRefresh}
                onChange={onChangeEnableRefresh}
              >
                Auto Refresh
              </Checkbox>
              {/* <Switch   className="addButton1" checkedChildren="Auto Refresh" unCheckedChildren="Un-Refresh" checked={enableAutoRefresh} onChange={onChangeEnableRefresh} />        */}
              {/* <Button onClick={handleAdd}  className="addButton" icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
            </Typography.Title>
          </div>

          <div className="row gap1">
            <div className={hideItem}>
              <label>Company :</label>
              <DebounceSelect
                showSearch={true}
                value={companyid}
                placeholder="Select a company !"
                fetchOptions={fetchUserList}
                onChange={(newValue) => {
                  setCompanyid(newValue);
                }}
                style={{ width: "100%" }}
              />
            </div>

            {/* <div className="col">
                    <label>Survey Plan :</label>
                    <DebounceSelect
                        showSearch={true}
                        value={surveyplan_id}
                        placeholder="Select a survey plan !"
                        fetchOptions={fetchSurveyPlan}
                        onChange={(newValue) => {setSurveyplan_id(newValue);  }}
                        style={{  width: '100%',   }}
                    />
                </div>
                <div className="col">
                    <label>Name :</label>
                    <Input  onChange={({ target: { value } } )=>setName(value)}/>
                </div>
                <div className="col">
                    <label>Tel :</label>
                    <Input  onChange={({ target: { value } } )=>setTel(value)}/>
                </div> */}

            <div>
              <label>From:</label>
              <DatePicker
                showTime
                onChange={onChangeSFrom}
                // onSelect={onSelectSFrom}
                // onClear={onClearSFrom}
                // value={moment(sFrom,dateFormat1)}
                defaultValue={sFrom}
                format={dateFormat1}
              />
            </div>
            <div>
              <label>To :</label>
              <DatePicker
                style={{ width: "100%" }}
                className={"minWidth2"}
                showTime
                onChange={onChangeSTo}
                defaultValue={sTo}
                format={dateFormat1}
              />
            </div>
            <div>
              <label>Department:</label>
              <Select
                style={{ width: "100%" }}
                onChange={handleOnChangeDepartment}
                value={departmentSelected}
              >
                <Option value=""></Option>
                {departmentData &&
                  departmentData.map((item) => {
                    if (checkLogDep(item)) {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    }
                  })}
              </Select>
              {/* <Select style={{ width: '100%' }} className={"minWidth2"}
                            onChange={handleOnChangeDepartment}                            
                            value={departmentSelected}                    
                    >
                        <Option value=""></Option>
                        { departmentData &&  departmentData.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
                    </Select> */}
            </div>
            <div>
              <label>Agent :</label>
              <DebounceSelect
                className={"minWidth2"}
                selectMode={""}
                refreshOption={refreshOptionExten}
                showSearch={true}
                value={exten}
                placeholder=""
                fetchOptions={fetchExtenList}
                onChange={handleOnChangeIto}
                onClear={handleAgentOnClear}
                style={{ width: "100%" }}
              />
            </div>

            <div className="col">
              <label>Search:</label>
              <Button onClick={handleSearch}>Search :</Button>
            </div>
          </div>
        </div>
      </section>
      <section className="searchResult">
        <div className="row">
          <Typography.Title level={3}>Result</Typography.Title>
        </div>
        {/* <div className="row">
            {data?.rows && <ShowTableCount departmentSelected={departmentSelected} agentStatus={agentStatus} data={data?.rows.countAll} setRefressSearch={setRefressSearch} />}
        </div> */}
        <div className="row">
          {data?.rows && (
            <ShowTable
              departmentSelected={departmentSelected}
              exten={extenValue}
              agentStatus={agentStatus}
              data={data?.rows}
              setRefressSearch={setRefressSearch}
            />
          )}
        </div>
        <div className="row">
          {data?.pages > 1 ? (
            <PageLink1
              pageSize={data.pageSize}
              searchpage={"agenteventsum"}
              pages={data.pages}
              numrows={data.numrows}
              page={page}
              setPage={setPage}
            />
          ) : (
            <h1></h1>
          )}
        </div>
      </section>
    </>
  );
}
