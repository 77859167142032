import React,{useState,useRef} from 'react';
import { useSelector} from "react-redux";
import {Button,Drawer} from 'antd';

import axios from 'axios';

import moment from 'moment';
import {StepForwardOutlined, DownloadOutlined,RollbackOutlined,EditFilled ,DeleteFilled} from '@ant-design/icons';


import Edit from "./Edit";
import Delete from "./Delete";
import useAxios from './../../../CustomHooks/useAxios';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';
export default function ShowTable({data,setRefressSearch}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const {axiosi}=useAxios();
  const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleRemove, setVisibleRemove] = useState(false);
    
    const [selectedItem,setSelectedItem]=useState(null);
    const [selectedDeleteItem,setSelectedDeleteItem]=useState(null);
    const refAudio = useRef();
    const [displayAudio,setDisplayAudio]=useState("none");
    const handlePlayAudio=async(id,uniqueid)=>{
        // refAudio.current.pause();
        setDisplayAudio("block");
        const kq=await axiosi.post('users/getFileLink',{id,uniqueid});
        // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
        refAudio.current.src=`https://gcall.com.vn/gcalldev/surveydata/fileLink?fileLink=${kq.data.fileLink}`
        refAudio.current.play();
    }
    const handleDownloadAudio=async(id,uniqueid,name)=>{
      // refAudio.current.pause();
      const kq=await axiosi.post('users/getFileLink',{id,uniqueid});
      // refAudio.current.src=`https://gcall.com.vn:2943/calllogs/recordfile?id=${id}&uniqueid=${uniqueid}`
      const linkfile=`https://gcall.com.vn/gcalldev/surveydata/fileLink?fileLink=${kq.data.fileLink}`
      // window.open(linkfile);
      axios({
          url: linkfile, //your url
          method: 'GET',
          responseType: 'blob', // important
      }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${uniqueid}_${id}_${name}.wav`); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
      });
  }
    const handleEdit=(item)=>{
      console.log("[handleEdit]:item=",item);
      setSelectedItem(item);
      setVisibleEdit(true);
  }
  const handleRemove=(item)=>{
      console.log("[handleRemove]:item=",item);
      setVisibleRemove(true);
      setSelectedDeleteItem(item);
  }

  const onCloseEdit=()=>{
    console.log("[onCloseEdit]")
    setSelectedItem(null);
    setVisibleEdit(false);
}
  const onCloseRemove=()=>{
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
}
  const handleReset=async(item)=>{
    console.log("[handleReset]:item=",item);
    try {
      const kq=await axiosi.put(`surveydata/${item.id}/reset`,{a:1});
      console.log("[handleReset]:kq=",kq) ;
      
      
      setRefressSearch(pre=>!pre);
  } catch (error){
    console.log("[handleReset]:error=",error);
  }

  }
  const rdCallResult=(item)=>{
    if (item.callresult===1) {
      return "Success";
    } else if (item.numcalls>=item.allownumcall && item.callstatus===0 ){
      return "Failed";
    } else {
      return "";
    }

  }
  const rdCallStatus=(item)=>{
    if (item.callstatus===1) {
      return "Processing";
 
    } else {
      return "";
    }

  }
  function formatMoneyVND(money){
    // let x = document.getElementById("tbl_pmv").value.replace(/ /g,"");
    // let pmv = document.getElementById("pmv");
    let x=(''+money).trim();          
       
    let regdigit = /^\d+$/;
    if (x=="") {
        // pmv.innerText='';
            return '';
    }
    if (!regdigit.test(x)){
        // pmv.innerText='NaN ₫';
            return 'NaN ₫';
    }
    // if (x!='')
    //      if ((z=="NaN ₫") || !regdigit.test(x)) {
    //         pmv.innerText='';
    //         return;
    //    }


   let z=formatter.format(x);      
  //  pmv.innerText=z;
   return z;
            
}





var formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
return (
  <>
  
      <Drawer title="Edit  Menu Item" placement="right" onClose={onCloseEdit} visible={visibleEdit}>
          {selectedItem && <Edit item={selectedItem} setSelectedItem={setSelectedItem} setRefressSearch={setRefressSearch} setVisibleEdit={setVisibleEdit} />}        
      </Drawer>

      <Drawer title="Remove  Menu Item" placement="right" onClose={onCloseRemove} visible={visibleRemove}>
          {selectedDeleteItem && <Delete item={selectedDeleteItem} setRefressSearch={setRefressSearch} setVisibleRemove={setVisibleRemove}/>}        
      </Drawer>

      <table className='table1'>
  <thead>
  <tr>            
      <th width="220px" className={hideItem}>company_name</th>
      <th>SurveyPlan</th>
      <th width="220px">Customer</th>
      
      <th>Audio File</th>
      <th width="220px">Call info </th>
      <th>Call Result</th>
      <th width="120px">Action</th>    
  </tr>
  </thead>
  <tbody>
{data.map(item=>(
  <tr key={item.id}>
      
      <td className={hideItem}>{item.company_name}</td>
      <td>{item.surveyplanname }</td>
      
      <td>
          <span className='stitle'>Name  :</span><span className='svalue'>{item.name}</span><br/>
          <span className='stitle'>Tel   :</span><span className='svalue'>{item.tel}</span><br/>
          <span className='stitle'>Gender:</span><span className='svalue'>{item.gender}</span><br/>
          <span className='stitle'>Money :</span><span className='svalue'>{formatMoneyVND(item.money)}</span><br/>
        
        
      </td>
      
      
      <td>
        
        { item.fileExisted && (<Button type="link" onClick={()=>handlePlayAudio(item.id,item.company_id)}><StepForwardOutlined />Play</Button>)}
        { item.fileExisted && ( <Button type="link" onClick={()=>handleDownloadAudio(item.id,item.company_id,item.name)}><DownloadOutlined />Download</Button> )}

        </td>
        <td>
          <span className='stitle'>Numcalls:</span><span className='svalue'>{item.numcalls}/{item.allownumcall}</span><br/>
          <span className='stitle'>Call status:</span><span className='svalue'>{rdCallStatus(item)}</span><br/>
          <span className='stitle'>Last calltime:</span><br/><span className='svalue'>{item.calltime?moment(item.calltime).format(dateFormat):''}</span><br/>
          
        </td>
      <td>{rdCallResult(item) }</td>
      <td>          
          
         <div className='rowFlex'>
            <Button type="link" onClick={()=>handleEdit(item)} icon={<EditFilled  style={{ color: 'hotpink' }}  />}>Edit</Button>
            {/* <Button type="link" onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Remove</Button>   */}
                        
         </div>    
         <div className='rowFlex'>
            {/* <Button onClick={()=>handleEdit(item)} icon={<EditFilled  style={{ color: 'hotpink' }}  />}>Create Audio File</Button>
            <Button onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Run</Button>               */}
            <Button type="link" onClick={()=>handleReset(item)}><RollbackOutlined style={{ color: 'green' }} />Reset Data</Button>              
         </div>    
         <div className='rowFlex'>
            {/* <Button type="link" onClick={()=>handleEdit(item)} icon={<EditFilled  style={{ color: 'hotpink' }}  />}>Edit</Button> */}
            <Button type="link" onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Remove</Button>  
                        
         </div>  
      </td>      
  </tr>
))}
</tbody>
</table>
<div className='callLogAudio' style={{"display":displayAudio}} >
            <audio ref={refAudio} controls>
                <source src={""} type="audio/wav" />                
                Your browser does not support the audio element.
            </audio>
            <Button type="link" onClick={()=>setDisplayAudio("none")}>Close Audio</Button>
        </div>
        
  </>
)
}