import React,{useState,useEffect} from 'react';
import { useSelector} from "react-redux";
import {Button,Drawer,Input,Space,Form,Select,Spin} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import debounce from 'lodash/debounce';
import useAxios from './../../../CustomHooks/useAxios';
import DebounceSelect from './../../DebounceSelect'
const { Option } = Select;
const { TextArea } = Input;
const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export default function Add({setRefressSearch,setVisibleAdd}) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const user = useSelector((state) => state.loginReducer.user);
  const {axiosi}=useAxios();
  
  
  const [money,setMoney]=useState(0);
    const [moneyTxt,setMoneyTxt]=useState('');
    const [companyid, setCompanyid] = React.useState({value:user.agents[0].company_id,label:user.agents[0].gmcomname});
    const [mainDialPlanData,setMainDialPlanData]=useState(null);
    const [mainDialPlainSelectedid,setMainDialPlainSelectedid]=useState('');

    const [surveyPlanData,setSurveyPlanData]=useState(null);
    const [surveyPlanDataSelectedid,setSurveyPlanDataSelectedid]=useState('');

    const [exten,setExten]=useState([]);
    
    const [forwardTo,setForwardTo]=useState('');

    const [telsoutData,setTelsoutData]=useState(null);
    

    const [telout,setTelout]=useState("");

    
    const [nextMainDialPlainSelectedid,setNextMainDialPlainSelectedid]=useState('');

    


    useEffect(()=>{
      const getSurveyPlan=async()=>{
        try {
          if (companyid){
        const kq=await axiosi.get('surveyplan',{params:{company_id:companyid.value,pageSize:100}});
        console.log("[useEffect-surveyplan]:kq=",kq)
        setSurveyPlanData(kq.data.rows);
          } else {
            setSurveyPlanData(null);
          }
        }
        catch (err){
          console.log("[useEffect-surveyplan]:err=",err);
        }

      }
      getSurveyPlan();
    },[companyid])
    const handleOnChangeSurveyPlan=(value)=>{
      setSurveyPlanDataSelectedid(value);
      console.log("[handleOnChangeSurveyPlan]:value=",value)
    }

    useEffect(()=>{
      const getMainDialplan=async()=>{
        try {
          if (companyid){
        const kq=await axiosi.get('ivrplanmain',{params:{company_id:companyid.value,pageSize:100}});
        console.log("[useEffect-getMainDialplan]:kq=",kq)
        setMainDialPlanData(kq.data.rows);
          } else {
            setMainDialPlanData(null);
          }
        }
        catch (err){
          console.log("[useEffect-getMainDialplan]:err=",err);
        }

      }
      getMainDialplan();
    },[companyid])
    const handleOnChangeMainDialPlan=(value)=>{
      setMainDialPlainSelectedid(value);
      console.log("[handleOnChangeMainDialPlan]:value=",value)
    }
   async function fetchUserList(company_name) {        
        console.log('fetching user', company_name);
        const body=await axiosi.get('companies',{params:{company_name,pageSize:100}} );
        if (body?.data?.rows) {
            return body?.data?.rows.map((user) => ({
                label: user.company_name,
                 value: user.id,
                //  value:{id: user.id,company_code:user.company_code},
                 company_code:user.company_code,
               }));
        }
        else {
            const a=[{label:"---no data---",value:""}]
            return a.map(i=> (i))
        }
       
    }

   
    
    const onFinishAdd=async (values)=>{
      let tbl_pmv=(''+money).trim();           
      let z=formatter.format(tbl_pmv);
      let regdigit = /^\d+$/;
      if (tbl_pmv!='')
        if ((z=="NaN ₫") || !regdigit.test(tbl_pmv)) {
           console.log("\"Số tiền \" phải là số !");
           return;
      }
        let adddata={...values,money,company_id:companyid.value , surveyplan_id:surveyPlanDataSelectedid  };
        try {
            const kq=await axiosi.post("surveydata",adddata);
            console.log("[onFinishAdd]:kq=",kq) 
            setVisibleAdd(false);
            setRefressSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("error.response.data:",error.response.data);
                console.log("error.response.status:",error.response.status);
                console.log("error.response.headers:",error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log("error.request :",error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error error.message', error.message);
              }
              console.log("error.config:",error.config);
        }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }

    
    const handleOnChangeCompanyName=async(value)=>{
          setCompanyid(value);
          const kq= await axiosi.get('tels',{params:{companyid:value.value,pageSize:100}});
          console.log("[handleOnChangeCompanyName]:kq_get_tels=",kq);
          console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
          setTelsoutData(kq.data);
          setTelout("");
    }
  

  
    function onSearch(val) {
      console.log('search:', val);
    }
    const handleOnChangeMoney=(value)=>{
      setMoney(value);
      setMoneyTxt(formatMoneyVND(value));
    }
    function formatMoneyVND(money){
      // let x = document.getElementById("tbl_pmv").value.replace(/ /g,"");
      // let pmv = document.getElementById("pmv");
      let x=(''+money).trim();          
         
      let regdigit = /^\d+$/;
      if (x=="") {
          // pmv.innerText='';
              return '';
      }
      if (!regdigit.test(x)){
          // pmv.innerText='NaN ₫';
              return 'NaN ₫';
      }
      // if (x!='')
      //      if ((z=="NaN ₫") || !regdigit.test(x)) {
      //         pmv.innerText='';
      //         return;
      //    }
  
  
     let z=formatter.format(x);      
    //  pmv.innerText=z;
     return z;
              
  }
  return (<>
  
        <Form
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      initialValues={{ordnum:0 }}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
      

        <Form.Item className={hideItem}
        label="Company Name"
        // name="company_name"
        rules={[{ required: (hideItem!=="hideItem"), message: 'Please input a company_name!' }]}
      >
        <DebounceSelect
          showSearch={true}
          value={companyid}
          placeholder="Select users"
          fetchOptions={fetchUserList}
          onChange={handleOnChangeCompanyName}          
          style={{  width: '100%', }}
        />
      </Form.Item>

      <Form.Item
        label="Tel :"
        name="tel"
        rules={[{ required: true, message: 'Please input a tel!' }]}
      >
        <Input  />        
      </Form.Item>
      <Form.Item
        label="Name :"
        name="name"
        rules={[{ required: true, message: 'Please input a tel!' }]}
      >
        <Input  />        
      </Form.Item>
      

      <Form.Item
        label="Gender"
        name="gender"
        rules={[{ required: false, message: 'Please input a gender!' }]}
        >
          <Select>
            <Option value=""></Option>
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
            <Option value="others">Others</Option>
          </Select>
        </Form.Item>
        <Form.Item
        label="Money :"
        // name="money"
        rules={[{ required: false, message: 'Please input a money!' }]}
      >
        <Input type="number" value={money} onChange={(e)=>handleOnChangeMoney(e.target.value)}  /> 
        <span>{moneyTxt}</span>   
        </Form.Item> 
      <Form.Item
      
        label="SurveyPlanId :"
        // name="maindialplanid"
        rules={[{ required: true, message: 'Please input a Survey plan!' }]}
      >
        {surveyPlanData && <>
            <Select style={{ width: '100%' }}  showSearch
                    onChange={handleOnChangeSurveyPlan}
                    //(value)=>setMenu_item_id(value)}
                    // loading={menuItemDataLoading}
                    value={surveyPlanDataSelectedid}
                    optionFilterProp="children"
                    placeholder="Select a person"
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    
            >
                        <Option value=""></Option>
                        { surveyPlanData && surveyPlanData.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
            </Select>
        
      </>}     
      
      </Form.Item>

      {/* <Form.Item
      
        label="maindialplanid :"
        // name="maindialplanid"
        rules={[{ required: true, message: 'Please input a maindialplanid!' }]}
      >
        {mainDialPlanData && <>
            <Select style={{ width: '100%' }}  showSearch
                    onChange={handleOnChangeMainDialPlan}
                    //(value)=>setMenu_item_id(value)}
                    // loading={menuItemDataLoading}
                    value={mainDialPlainSelectedid}
                    optionFilterProp="children"
                    placeholder="Select a person"
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    
            >
                        <Option value=""></Option>
                        { mainDialPlanData && mainDialPlanData.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
            </Select>
        
      </>}     
      
      </Form.Item> */}
      

      {/* <Form.Item
        label="note :"
        name="note"
        rules={[{ required: false, message: 'Please input an note!' }]}
        
      >
        <TextArea />
      
      </Form.Item> */}

      

  
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
  </>);
}
