import React,{useState,useEffect} from 'react';
import { useSelector} from "react-redux";
import {Row,Col,DatePicker,Button,Drawer,Input,Space,Form,Select,Spin,TimePicker} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import moment from 'moment';
import debounce from 'lodash/debounce';
import useAxios from './../../../CustomHooks/useAxios';
import DebounceSelect from './../../DebounceSelect'
const { Option } = Select;
export default function Edit({item,setSelectedItem,setRefressSearch,setVisibleEdit,uniqueid,tkid}) {
  const {axiosi}=useAxios();




  
  let provinceData = useSelector((state) => state.jsSIPReducer.provinceData);
  const [addrprovinceSelected,setAddrprovinceSelected]=useState(item.addr_province);


  function onChangeAddrProvince(val){    
    setAddrprovinceSelected(val);
    getDistrict(val,"addr");
  }

  ///////////Begin AddrDistrict////////////////
  const [addrDistrictData,setAddrDistrictData]=useState(null);
  const [addrDistrictSelected,setAddrDistrictSelected]=useState(item.addr_district); 

  const getDistrict=async(_province_id,type)=>{
    try {
     
      const kq= await axiosi.get('district',{params:{_province_id,pageSize:200}});
      if (type==="addr") {
         setAddrDistrictData(kq.data.rows);
         setAddrDistrictSelected('');
      }
      else {
            return;        
      }
    } catch (err){
      console.log("[getDistrict]:Error")
    }
    }

function onChangeAddrDistrict(val){
    setAddrDistrictSelected(val);
    getWard(addrprovinceSelected,val,"addr");
    getStreet(addrprovinceSelected,val,"addr");
    console.log("[onChangeAddrDistrict]:val=",val)
  } 
    // useEffect(()=>{     
    //   getDistrict(addrprovinceSelected,"addr");
    // },[addrprovinceSelected])

    useEffect(()=>{     

      const getDistrict=async(_province_id,type)=>{
        try {
         
          const kq= await axiosi.get('district',{params:{_province_id,pageSize:200}});
          if (type==="addr") {
             setAddrDistrictData(kq.data.rows);
             setAddrDistrictSelected(item.addr_district);
          }
          else {
                return;        
          }
        } catch (err){
          console.log("[getDistrict]:Error")
        }
        }

      getDistrict(addrprovinceSelected,"addr");
      // setAddrDistrictSelected(item.addr_district);
    },[item?.addr_province,item?.addr_district])


    

    

      ///////////END AddrDistrict////////////////

      ///////////Begin AddrWard////////////////
    const [addrWardData,setAddrWardData]=useState(null);
    const [addrWardSelected,setAddrWardSelected]=useState(item.addr_ward);
    
    function onChangeAddrWard(val){
        setAddrWardSelected(val);
        console.log("[onChangeAddrWard]:val=",val)
      }
    const getWard=async(_province_id,_district_id,type)=>{
        try {
         
          const kq= await axiosi.get('ward',{params:{_province_id,_district_id,pageSize:200}});
          if (type==="addr") {
             setAddrWardData(kq.data.rows);
             setAddrWardSelected(item.addr_ward);
          }
          else {
                return;        
          }
        } catch (err){
                console.log("[getDistrict]:Error")
            }
    } 
      

        // useEffect(()=>{     
         
        //     getWard(addrprovinceSelected,addrDistrictSelected,"addr");
        //   },[addrprovinceSelected,addrDistrictSelected])
      
          useEffect(()=>{    
            const getWard=async(_province_id,_district_id,type)=>{
              try {
               
                const kq= await axiosi.get('ward',{params:{_province_id,_district_id,pageSize:200}});
                if (type==="addr") {
                   setAddrWardData(kq.data.rows);
                   setAddrWardSelected(item.addr_ward);
                }
                else {
                      return;        
                }
              } catch (err){
                      console.log("[getDistrict]:Error")
                  }
          } 
            getWard(addrprovinceSelected,addrDistrictSelected,"addr");
          },[item?.addr_province,item?.addr_district,item?.addr_ward])
      
      
        ///////////End AddrWard////////////////

    ///////////Begin AddrStreet////////////////
    const [addrStreetData,setAddrStreetData]=useState(null);
    const [addrStreetSelected,setAddrStreetSelected]=useState('');
    
    function onChangeAddrStreet(val){
        setAddrStreetSelected(val);
        console.log("[onChangeAddrWard]:val=",val)
      }
    const getStreet=async(_province_id,_district_id,type)=>{
        try {
         
          const kq= await axiosi.get('street',{params:{_province_id,_district_id,pageSize:200}});
          if (type==="addr") {
             setAddrStreetData(kq.data.rows);
             setAddrStreetSelected('');
          }
          else {
                return;        
          }
        } catch (err){
                console.log("[getDistrict]:Error")
            }
    } 
      

        // useEffect(()=>{     
        //     getStreet(addrprovinceSelected,addrDistrictSelected,"addr");
        //   },[addrprovinceSelected,addrDistrictSelected])

          useEffect(()=>{     
            const getStreet=async(_province_id,_district_id,type)=>{
              try {
               
                const kq= await axiosi.get('street',{params:{_province_id,_district_id,pageSize:200}});
                if (type==="addr") {
                   setAddrStreetData(kq.data.rows);
                   setAddrStreetSelected(item.addr_street);
                }
                else {
                      return;        
                }
              } catch (err){
                      console.log("[getDistrict]:Error")
                  }
          }
            getStreet(item?.addr_province,item?.addr_district,"addr");
          },[item?.addr_province,item?.addr_district,item?.addr_street])
      
        ///////////End AddrStreet////////////////

        const [dobStr,setDobStr]=useState()
        function onChangeDoB(date, dateString) {
            console.log("[onChangeDoB]:",date, dateString);
            setDobStr(dateString);
          }
    const onFinishAdd=async (values)=>{
        
        let adddata={...values,dob:dobStr,
          addr_province:addrprovinceSelected,
          addr_district:addrDistrictSelected,
          addr_ward:addrWardSelected,
          addr_street:addrStreetSelected
        };
        console.log("[onFinishAdd]:values=",adddata);
        try {
          const kq=await axiosi.put(`comcontacts/${item.id}`,adddata);
          console.log("[onFinishAdd]:kq=",kq) 
          setVisibleEdit(false);
          setSelectedItem(null);
          

          if (tkid) {
            try {
              console.log("[ticketnote/update/updateCustomerToTicket]:tkid=",tkid)
            const kq=await axiosi.put(`ticketnote/update/updateCustomerToTicket`,{
              ccomname:values.ccomname,
              name:values.name,
              tel:values.tel,
              email:values.email,
              comcontacts_id:item.id,

              id:tkid,
            });
          } catch (error){
            console.log("ticketnote/updateCustomerToTicket: error")
          }
          } else if (uniqueid){
            try {
            const kq=await axiosi.put("calllogs/updateCustomerToCDR",{
              customer_company:values.ccomname,
              customer_name:values.name,
              customer_id:item.id,
              uniqueid,
            });
            // setRefressSearchCallLog(pre=>!pre);
          }
          catch {
            console.log("[handleEdit]:calllogs/updateCustomerToCDR : errr");
          }
          }

          setRefressSearch(pre=>!pre);

      } catch (error){
          if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log("error.response.data:",error.response.data);
              console.log("error.response.status:",error.response.status);
              console.log("error.response.headers:",error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log("error.request :",error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error error.message', error.message);
            }
            console.log("error.config:",error.config);
      }
       return;
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }

    
    
   
   
 
    
  return (<>
  
        <Form
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      initialValues={{...item,
        dob:moment(moment(item.dob).format('YYYY-MM-DD') , 'YYYY-MM-DD'),}}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
      <Row>
          <Col span={12}><Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please input a Name!' }]}
        >
          <Input />
        </Form.Item></Col>
          <Col span={12}><Form.Item
        label="Tel"
        name="tel"
        rules={[{ required: true, message: 'Please input a tel!' }]}
        >
          <Input />
        </Form.Item></Col>
          <Col span={12}>        <Form.Item
        label="Company Name"
        name="ccomname"
        rules={[{ required: false, message: 'Please input a Company Name!' }]}
        >
          <Input />
        </Form.Item></Col>
        <Col span={12}><Form.Item
        label="Birthday"
        name="dob"
        rules={[{ required: false, message: 'Please input a tel!' }]}
        >
          <DatePicker style={{width:"100%"}} onChange={onChangeDoB} />
        </Form.Item></Col>
        <Col span={12}>
        <Form.Item
        label="Gender"
        name="gender"
        rules={[{ required: false, message: 'Please input a tel!' }]}
        >
          <Select>
            <Option value=""></Option>
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
            <Option value="others">Others</Option>
          </Select>
        </Form.Item>
        </Col>

        <Col span={12}><Form.Item
        label="Email"
        name="email"
        rules={[{ required: false, message: 'Please input a Email!' }]}
        >
          <Input />
        </Form.Item></Col>
        <Col span={12}><Form.Item
        label="Tax"
        name="tax"
        rules={[{ required: false, message: 'Please input a tax!' }]}
        >
          <Input />
        </Form.Item></Col>
        <Col span={12}><Form.Item
        label="priority_level"
        name="priority_level"
        rules={[{ required: true, message: 'Please input a priority_level!' }]}
        >
          <Input type="number" min={0} max={5} />
        </Form.Item></Col>
      
        <Col span={12}> <Form.Item
        label="addr_province"
        name="addr_province"
        rules={[{ required: false, message: 'Please input a priority_level!' }]}
        >
          
         {provinceData && <Select style={{width:"100%"}} showSearch
              onChange={onChangeAddrProvince} 
               optionFilterProp="children"
               filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
          >
            <Option value=""></Option>
            {provinceData && provinceData.map((item)=>(<Option key={item.id} value={item.id}>{item._name}</Option>))}                
          </Select>
        }
          
        </Form.Item> </Col>
        <Col span={12}>
        <Form.Item label="addr_district"
        //name="addr_district1"
        >
            {addrDistrictData &&       <Select style={{width:"100%"}} showSearch
              value={addrDistrictSelected}
              onChange={onChangeAddrDistrict}         
              
              optionFilterProp="children"
              filterOption={(input, option) =>
               option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
             }
          >
            <Option value=""></Option>           
            {addrDistrictData && addrDistrictData?.map((item)=>(<Option key={item.id} value={item.id}>{item.name1}</Option>))}          
          </Select>
          }
        </Form.Item> </Col>
        <Col span={12}>
        <Form.Item label="addr_ward"
        //name="addr_district1"
        >
            {addrWardData &&       <Select style={{width:"100%"}} showSearch
              value={addrWardSelected}
              onChange={onChangeAddrWard}         
              
              optionFilterProp="children"
              filterOption={(input, option) =>
               option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
             }
          >
            <Option value=""></Option>           
            {addrWardData && addrWardData?.map((item)=>(<Option key={item.id} value={item.id}>{item.name1}</Option>))}          
          </Select>
          }
        </Form.Item> </Col>

        <Col span={12}>
        <Form.Item label="addr_street"
        //name="addr_district1"
        >
            {addrStreetData &&       <Select style={{width:"100%"}} showSearch
              value={addrStreetSelected}
              onChange={onChangeAddrStreet}         
              
              optionFilterProp="children"
              filterOption={(input, option) =>
               option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
             }
          >
            <Option value=""></Option>           
            {addrStreetData && addrStreetData?.map((item)=>(<Option key={item.id} value={item.id}>{item.name1}</Option>))}          
          </Select>
          }
        </Form.Item> </Col>
        <Col span={12}>
        <Form.Item
        label="addr_strnum"
        name="addr_strnum"
        rules={[{ required: false, message: 'Please input a priority_level!' }]}
        >
          <Input />
        </Form.Item> </Col>
        </Row>
        <Row>
        <Col span={24}>
        <Form.Item
        label="note"
        name="note"
        rules={[{ required: false, message: 'Please input a priority_level!' }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item> </Col>
      
        </Row>
        
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
  </>);
}
