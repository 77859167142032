import React from 'react'
import {  Form, Select } from 'antd';
const { Option } = Select;
export default function ToPhoneList({lableName, showTelList, telsoutData, setToPhone ,phoneValues}) {
    return (
        <>
            <Form.Item
                label={lableName}

                hidden={showTelList}
            >
                {telsoutData?.rows && <>
                    <Select style={{ width: '100%' }}
                        mode="multiple"
                        onChange={value => setToPhone(value)}
                        value={phoneValues}
                    >

                        {telsoutData?.rows && telsoutData.rows.map(item => (
                            <Option key={item.id} value={item.id}>{item.telout}</Option>
                        ))}
                    </Select>

                </>}

            </Form.Item>
        </>
    )
}
