import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Input, notification, Select, Form } from "antd";
import {
  StepForwardOutlined,
  DownloadOutlined,
  PhoneOutlined,
  UserOutlined,
  HomeOutlined,
  FlagOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MailOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import useAxios from "./../../../CustomHooks/useAxios";

export default function UploadFileStatus({ upload_id }) {
  const { axiosi } = useAxios();
  const [enableSearch, setEnableSearch] = useState(true);
  const [loaddingData, setLoaddingData] = useState(false);
  const [data, setData] = useState();
  const [refressSearch, setRefressSearch] = useState(true);
  const [enableAutoRefresh, setEnableAutoRefresh] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (
    type,
    message,
    description,
    duration,
    placement
  ) => {
    api[type]({ message, description, duration, placement });
  };
  useEffect(() => {
    doSearch();
  }, [upload_id, refressSearch]);

  const doSearch = async () => {
    if (!upload_id) return;
    if (!enableSearch) return;
    try {
      setLoaddingData(true);
      const kq = await axiosi.get("outbounddata/getUploadStatusById", {
        params: {
          upload_id,
        },
      });
      // console.log("[handleSearch]:kq=",kq);
      if (!enableSearch) return;
      setLoaddingData(false);
      setData(kq.data);
      if (kq.data.rows[0].status == "done") {
        openNotificationWithIcon(
          "success",
          "Successfully",
          kq.data.rows[0].note,
          2,
          "bottomRight"
        );
        return;
      }
      if (enableAutoRefresh) {
        if (!enableSearch) return;
        setTimeout(() => {
          setRefressSearch((pre) => !pre);
        }, 3000);
      }
    } catch (err) {
      console.log("ERROR");
      setLoaddingData(false);
    }
  };
  const handleDownloadData = async (item, type) => {
    console.log("[handleExport1]");
    try {
      const kq = await axiosi.get("outbounddata/DownloadData", {
        params: {
          id: item.id,
          type,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([kq.data]));
      const link = document.createElement("a");
      link.href = url;
      switch (type) {
        case "Data":
          link.setAttribute("download", `${item.id}_data.xlsx`); //or any other extension
          break;
        case "Error":
          link.setAttribute("download", `${item.id}_dataError.xlsx`); //or any other extension
          break;
        default:
          break;
      }

      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.log("ERROR");
    }
  };
  const displayFile = (item) => {
    console.log("[displayFile]:", { processedinfo: item.processedinfo });
    const processedinfo = JSON.parse(item.processedinfo);
    let displayDownloadFile = <></>;
    let displayDownloadFileError = <></>;
    if (processedinfo.data > 0) {
      displayDownloadFile = (
        <>
          <h3>Summary:</h3>
          <ul>
            <li>Data : {processedinfo.data}</li>
            <li>dataProcessed : {processedinfo.processed}</li>
            <li>dataSuccess : {processedinfo.dataSuccess}</li>
            <li>dataError : {processedinfo.dataError}</li>
          </ul>
          <div>
            <Button
              type="link"
              onClick={() => {
                handleDownloadData(item, "Data");
              }}
            >
              <DownloadOutlined />
              Download_Data
            </Button>
          </div>
        </>
      );
    }
    if (processedinfo.dataError > 0) {
      displayDownloadFileError = (
        <div>
          <Button
            type="link"
            onClick={() => {
              handleDownloadData(item, "Error");
            }}
          >
            <DownloadOutlined />
            Download_Data_Error
          </Button>
        </div>
      );
    }
    let kq = (
      <>
        {displayDownloadFile}
        {displayDownloadFileError}
      </>
    );
    switch (item.note) {
      case "File does't not have sheet : 'data'!":
        kq = <></>;
        break;

      default:
        break;
    }
    return kq;
  };
  return (
    <>
      {contextHolder}
      <hr />

      <div className="col">
        {data?.rows &&
          data.rows.map((item) => {
            return (
              <div key={item.id}>
                <div>Status={item.status}</div>
                <div>Note={item.note}</div>

                {displayFile(item)}
                {/* <div
                  onClick={() => {
                    handleDownloadData(item, "Data");
                  }}
                >
                  Download_Data
                </div>
                <div
                  onClick={() => {
                    handleDownloadData(item, "Error");
                  }}
                >
                  Download_Data_Error
                </div> */}
              </div>
            );
          })}
      </div>
      <div>UploadFileStatus id: {upload_id}</div>
      <Button onClick={() => doSearch()}>Refresh Upload Status</Button>
    </>
  );
}
