import {useState,useEffect} from 'react';
import {Button,Drawer,Input,Space,Form} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';
import EditCompanies from "./EditCompanies";
import DeleteCompanies from "./DeleteCompanies";

export default function ShowCompaniesTable({data,setRefreshSearch}) {
  const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleRemove, setVisibleRemove] = useState(false);
    
    const [selectedItem,setSelectedItem]=useState(null);
    const [selectedDeleteItem,setSelectedDeleteItem]=useState(null);

    const handleEdit=(item)=>{
      console.log("[handleEdit]:item=",item);
      setSelectedItem(item);
      setVisibleEdit(true);
  }
  const handleRemove=(item)=>{
      console.log("[handleRemove]:item=",item);
      setVisibleRemove(true);
      setSelectedDeleteItem(item);
  }

  const onCloseEdit=()=>{
    console.log("[onCloseEdit]")
    setSelectedItem(null);
    setVisibleEdit(false);
}
  const onCloseRemove=()=>{
    setVisibleRemove(false);
    setSelectedDeleteItem(null);
}
return (
  <>
      <Drawer title="Edit  Menu Item" placement="right" onClose={onCloseEdit} visible={visibleEdit}>
          {selectedItem && <EditCompanies item={selectedItem} 
                setRefreshSearch={setRefreshSearch}
                setVisibleEdit={setVisibleEdit}
                setSelectedItem={setSelectedItem}
            />}        
      </Drawer>

      <Drawer title="Remove  Menu Item" placement="right" onClose={onCloseRemove} visible={visibleRemove}>
          {selectedDeleteItem && <DeleteCompanies item={selectedDeleteItem} setRefreshSearch={setRefreshSearch}
                setVisibleRemove={setVisibleRemove} />}        
      </Drawer>

      <table className='table1'>
  <thead>
  <tr>
      {/* <th>id</th> */}
      {/* <th>calldirection</th>
      <th>connected</th> */}
      
      <th width="220px">company_name</th>
      <th>contactname</th>
      <th>contacttel</th>
      <th>contactemail</th>
      {/* <th>dcontext</th> */}
      <th>tax</th>
      <th>address</th>
      <th>Max Agents</th>
      
      
      <th width="120px">Action</th>
      

  </tr>
  </thead>
  <tbody>
{data.map(item=>(
  <tr key={item.id}>
      {/* <td>{item.id}</td> */}
      {/* <td>{item.calldirection}</td>
      <td>{item.connected}</td> */}
      
      
      <td>{item.company_name}</td>
      <td>{item.contactname}</td>
      <td>{item.contacttel}</td>
      <td>{item.contactemail}</td>
      {/* <td>{item.dcontext}</td> */}
      <td>{item.tax}</td>
      <td>{item.address}</td>
      <td>{item.numagents}</td>
      
      
      <td> 
         {/* <Button  onClick={handleAdd} icon={<PlusCircleFilled  style={{ color: '#1890ff' }} />}>Add</Button> */}
         <div className='rowFlex'>
             <Button onClick={()=>handleEdit(item)} icon={<EditFilled  style={{ color: 'hotpink' }}  />}>Edit</Button>
              <Button onClick={()=>handleRemove(item)}><DeleteFilled style={{ color: 'red' }} />Remove</Button>
         </div>
         {/* <Button type="primary" icon={<DownloadOutlined />} size={"small" }>
    Download
  </Button> */}
      </td>
      
  </tr>
))}
</tbody>
</table>
  </>
)
}
