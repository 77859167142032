import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Row,
  Col,
  Typography,
  DatePicker,
  Drawer,
  TimePicker,
  Select,
  Input,
  Space,
  Divider,
  notification,
} from "antd";
import {
  StepForwardOutlined,
  DownloadOutlined,
  PhoneOutlined,
  UserOutlined,
  HomeOutlined,
  FlagOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MailOutlined,
  InfoCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import useAxios from "./../../../CustomHooks/useAxios";
import DebounceSelect from "./../../DebounceSelect";
import ShowCallLogTable from "./ShowCallLogTable";
import SendSMS from "./SendSMS";
import SMSThuongHieuSetting from "./SMSThuongHieuSetting";
const Context = React.createContext({
  name: "Default",
});
const { Text } = Typography;
// const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:mm";
const dateFormat1 = "YYYY-MM-DD HH:mm";
export default function Index() {
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement) => {
    api.info({
      message: `Notification ${placement}`,
      description: (
        <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>
      ),
      placement,
    });
  };
  const contextValue = useMemo(
    () => ({
      name: "Ant Design",
    }),
    []
  );
  const { axiosi } = useAxios();
  const [scalltype, setScalltype] = useState("");
  const [scallStatus, setScallStatus] = useState("");
  const [stel, setStel] = useState("");
  const user = useSelector((state) => state.loginReducer.user);
  const hideItem = useSelector((state) => state.loginReducer.hideItem);

  const [companyid, setCompanyid] = React.useState({
    value: user?.agents[0].company_id,
    label: user?.agents[0].gmcomname,
  });
  const [sFrom, setSFrom] = useState(
    moment(moment().format(dateFormat), dateFormat1)
  );
  const [sTo, setSTo] = useState(
    moment(moment().add(1, "days").format(dateFormat), dateFormat1)
  );

  const [sFromTime, setSFromTime] = useState(null);
  const [sToTime, setSToTime] = useState(null);
  const [departmentData, setDepartmentData] = useState(null);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [extens, setExtens] = useState();
  const [exten, setExten] = useState(null);
  const [refreshOptionExten, setRefreshOptionExten] = useState(true);
  const [itoSelectMode, setItoSelectMode] = useState("");
  const [page, setPage] = useState();
  const [refressSearch, setRefressSearch] = useState(false);
  const [data, setData] = useState();
  const [visibleSendSMS, setVisibleSendSMS] = useState(false);
  const [visibleSMSThuongHieuSetting, setVisibleSMSThuongHieuSetting] =
    useState(false);
  const style = {
    //  background: 'rgb(178, 227, 156) none repeat scroll 0% 0%',
    display: "flex",
    flexDirection: "column",
    padding: "8px 0",
  };
  function onChangeSFrom(date, dateString) {
    setSFrom(dateString);
  }
  function onChangeSTo(date, dateString) {
    setSTo(dateString);
  }
  const handleChangesTel = (e) => {
    setStel(e.target.value);
  };
  async function fetchCompanyList(company_name) {
    const body = await axiosi.get("companies", {
      params: { company_name, pageSize: 100 },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
  }
  //Time From Time
  function onChangeSFromTime(time, timeString) {
    setSFromTime(time);
  }
  function onSelectSFromTime(time) {
    setSFromTime(time);
  }
  //Time To Time
  function onChangeSToTime(time, timeString) {
    console.log("[onChangeSToTime]:", { time, timeString });
    setSToTime(time);
  }
  function onSelectSToTime(time) {
    console.log("[onSelectSToTime]:", time?._d);
    console.log("[onSelectSToTime]:", { time, timeString: time?._i });
    setSToTime(time);
  }
  const handleOnChangeDepartment = (value) => {
    console.log("[handleOnChangeDepartment]:value=", value);
    setDepartmentSelected(value);
    setExten(null);
    setRefreshOptionExten((pre) => !pre);
  };
  function checkLogDep(item) {
    if (user.agents[0].company_id === 1) return true;
    const logdeps = user.agents[0].logdepartments;

    return logdeps.includes(item.id);
  }
  async function fetchExtenList(exten) {
    console.log("[fetchExtenList]:exten=", exten);
    const body = await axiosi.get("agents/searchExten", {
      params: {
        company_id: companyid?.value,
        department_id: departmentSelected,
        exten,
        pageSize: 200,
      },
    });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.name,
        value: user.endpoint_id,
      }));
    } else {
      const a = [{ label: "---no data---", value: "" }];
      return a.map((i) => i);
    }
  }
  const handleOnChangeIto = async (value) => {
    console.log("[handleOnChangeIto]:value=", value);
    setExten(value);
  };
  const btnSearchOnClick = () => {
    console.log("[btnSearchOnClick]:page=", page);
    if (page === 1) {
      console.log("[btnSearchOnClick page=1] : set setRefressSearch:");
      setRefressSearch((pre) => !pre);
    } else {
      console.log("[btnSearchOnClick page!=1] : set setpage=1:");
      setPage(1);
    }
  };
  useEffect(() => {
    handleSearchCallLog();
  }, [page, refressSearch]);
  const handleSearchCallLog = async () => {
    try {
      let timeFrom = "";
      if (typeof sFrom === "object") {
        timeFrom = sFrom.format(dateFormat1);
        console.log("sFrom=", sFrom.format(dateFormat1));
      } else {
        console.log("sFrom STRING=", sFrom);
        if (sFrom) {
          timeFrom = sFrom;
          console.log("CÓ sFROM");
        } else {
          console.log("KG CÓ SFORM");
        }
      }

      let timeTo = "";
      if (typeof sTo === "object") {
        timeTo = sTo.format(dateFormat1);
        console.log("sTo:=", sTo.format(dateFormat1));
      } else {
        timeTo = sTo;
        console.log("sTo STRING:=", sTo);
      }

      console.log("[Calllog Search]: exten=", exten);
      // let searchall='[]';

      // if ((user.agents[0].company_id!==1)&&(departmentData.length!==user.agents[0].logdepartments.length)){
      //   searchall=JSON.stringify(user.agents[0].logdepartments)
      // }

      // const sdata={page,timeFrom,timeTo,scallStatus,scalltype,
      //   sexten:exten?.value,company_id:companyid?.value,
      //   department_id:(departmentSelected==='')?searchall:`[${departmentSelected}]`,
      //   stel,
      //   timeFromTime:sFromTime===null?'':sFromTime.format(timeFormat),
      //   timeToTime:sToTime===null?'':sToTime.format(timeFormat),
      //   logdepartments:JSON.stringify(user.agents[0].logdepartments)
      // }

      console.log("before sdata");
      const sdata = {
        page,
        timeFrom,
        timeTo,
        scallStatus,
        scalltype,
        sexten: exten?.value,
        company_id: companyid?.value,
        department_id: departmentSelected,
        stel,
        timeFromTime: sFromTime === null ? "" : sFromTime.format(timeFormat),
        timeToTime: sToTime === null ? "" : sToTime.format(timeFormat),
        deplength: Object.keys(user?.deps).length,
      };
      console.log(sdata);
      const kq = await axiosi.get("sms", { params: sdata });
      const kqdata = await kq.data;
      if (kq.status !== 204) {
        setData(kq.data);
        // setDataSum(null);
        console.log("[CallLog-extens]:", kqdata);
      } else {
        console.log("[No Content] kq.data=", kq.data);
        setData(null);
        // setDataSum(null);
      }
    } catch (error) {
      console.log("[handleSearchCallLog]:", error);
      setData(null);
      // setDataSum(null);
    }
    // console.log(sFrom,sTo);
    // console.log("::::::::::::",typeof(sFrom),typeof(sTo));
  };

  useEffect(() => {
    const getDepartmentData = async () => {
      try {
        const kq = await axiosi.get("department", {
          params: { company_id: companyid?.value, pageSize: 100 },
        });
        console.log("[getDepartmentData]:kq.data.rows=", kq.data.rows);
        setDepartmentData(kq.data.rows);
        setDepartmentSelected("");

        // console.log("[getDepartmentData]:kq.data.rows.length=",kq.data.rows.length);
        // console.log("[getDepartmentData]:user.agents[0].logdepartments.length=",user.agents[0].logdepartments.length);
      } catch (err) {
        setDepartmentData(null);
        console.log("[useEffect]:getDepartmentData has an Error !");
      }
    };
    getDepartmentData();
  }, [companyid?.value]);
  useEffect(() => {
    const getDepartmentData = async () => {
      try {
        const kq = await axiosi.get("department", {
          params: { company_id: companyid?.value, pageSize: 100 },
        });
        console.log("[getDepartmentData]:kq.data.rows=", kq.data.rows);
        setDepartmentData(kq.data.rows);
        setDepartmentSelected("");

        // console.log("[getDepartmentData]:kq.data.rows.length=",kq.data.rows.length);
        // console.log("[getDepartmentData]:user.agents[0].logdepartments.length=",user.agents[0].logdepartments.length);
      } catch (err) {
        setDepartmentData(null);
        console.log("[useEffect]:getDepartmentData has an Error !");
      }
    };
    getDepartmentData();
  }, [companyid?.value]);
  const onCloseSMSThuongHieuSetting = () => {
    setVisibleSMSThuongHieuSetting(false);
  };
  const handleShowSMSThuongHieuSetting = () => {
    setVisibleSMSThuongHieuSetting(true);
  };

  const onCloseSendSMS = () => {
    setVisibleSendSMS(false);
  };
  const handleShowSendSMS = () => {
    setVisibleSendSMS(true);
  };
  const showSMSResult = (smsResultMessage, type) => {
    notification.open({
      message: "SMS Result",
      description: smsResultMessage,
      type: type, //"success", // success, info, warning, error
      duration: 5, // thời gian hiển thị (giây)
      placement: "bottmRight", //"topRight", // vị trí hiển thị
    });
  };
  return (
    <Context.Provider value={contextValue}>
      <>
        {contextHolder}
        <Drawer
          title="Send SMS"
          placement="right"
          onClose={onCloseSendSMS}
          visible={visibleSendSMS}
        >
          <SendSMS
            setRefressSearch={setRefressSearch}
            setVisibleSendSMS={setVisibleSendSMS}
            showSMSResult={showSMSResult}
          />
        </Drawer>

        <Drawer
          title="SMS Thuong Hieu"
          placement="right"
          onClose={onCloseSMSThuongHieuSetting}
          visible={visibleSMSThuongHieuSetting}
        >
          <SMSThuongHieuSetting
            setRefressSearch={setRefressSearch}
            setVisibleSendSMS={setVisibleSMSThuongHieuSetting}
            showSMSResult={showSMSResult}
          />
        </Drawer>
        <div>SMS TNTH</div>
        <Typography.Title level={3}>Search :</Typography.Title>
        <span
          className="stitle"
          title="Send a SMS message!"
          onClick={handleShowSendSMS}
          // onClick={() => showNoti()}
        >
          <MailOutlined className={"icon"} /> Send SMS
        </span>
        <span
          className="stitle"
          title="Send a SMS message!"
          onClick={handleShowSMSThuongHieuSetting}
          // onClick={() => openNotification("bottomRight")}
        >
          <SettingOutlined className={"icon"} /> Config SMS
        </span>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={4}>
            <div style={style}>
              <Text>From :</Text>
              <DatePicker
                onChange={onChangeSFrom}
                defaultValue={sFrom}
                format={dateFormat}
              />
            </div>
          </Col>

          <Col className="gutter-row" span={4}>
            <div style={style}>
              <Text>To :</Text>
              <DatePicker
                // showTime
                onChange={onChangeSTo}
                defaultValue={sTo}
                format={dateFormat}
              />
            </div>
          </Col>

          <Col className="gutter-row" span={4}>
            <div style={style}>
              <Text>SMS status :</Text>
              <Select
                defaultValue=""
                style={{ width: "100%" }}
                onChange={(value) => setScalltype(value)}
              >
                <Option value="">All</Option>
                <Option value="0">0 - Not sent</Option>
                <Option value="1">1 - Sent</Option>
              </Select>
            </div>
          </Col>

          <Col className="gutter-row" span={4}>
            <div style={style}>
              <Text>Tel :</Text>
              <Input placeholder="tel" onChange={handleChangesTel} />
            </div>
          </Col>
          <Col className={`gutter-row ${hideItem} `} span={4}>
            <div style={style}>
              <Text>Company :</Text>
              <DebounceSelect
                showSearch={true}
                value={companyid}
                placeholder=""
                fetchOptions={fetchCompanyList}
                onChange={(newValue) => {
                  setCompanyid(newValue);
                }}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={4}>
            <div style={style}>
              <Text>Department :</Text>
              <Select
                style={{ width: "100%" }}
                onChange={handleOnChangeDepartment}
                value={departmentSelected}
              >
                <Option value=""></Option>
                {departmentData &&
                  departmentData.map((item) => {
                    if (checkLogDep(item)) {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    }
                  })}
              </Select>
            </div>
          </Col>
          <Col className="gutter-row" span={8}>
            <div style={style}>
              <Text>Agent :</Text>
              <DebounceSelect
                selectMode={itoSelectMode}
                refreshOption={refreshOptionExten}
                showSearch={true}
                value={exten}
                placeholder=""
                fetchOptions={fetchExtenList}
                onChange={handleOnChangeIto}
                style={{ width: "100%" }}
              />
            </div>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={4}>
            <div style={style}>
              <Text> &nbsp;</Text>
              <Button onClick={btnSearchOnClick}>Search</Button>
            </div>
          </Col>
          {/* <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>&nbsp;</Text>
            <Button onClick={handleExportCallLog}>Export</Button>
          </div>
        </Col> */}
          {/* <Col className="gutter-row" span={4}>
          <div style={style}>
            <Text>&nbsp;</Text>
            <Button onClick={btnSummaryOnClick}>Summary</Button>
          </div>
        </Col> */}
          {/* <Col className="gutter-row" span={8}>
          <div style={style}>
            <Text>&nbsp;</Text>
            <Button onClick={btnExportSummaryOnClick}>Export Summary</Button>
          </div>
        </Col> */}
        </Row>
        <Row>
          <Col span={24}>
            {data ? (
              <ShowCallLogTable
                data={data.rows}
                setRefressSearch={setRefressSearch}
              />
            ) : (
              <span></span>
            )}
          </Col>
        </Row>
      </>
    </Context.Provider>
  );
}
