import {useState,useEffect} from 'react';
import {Button,Drawer,Input,Space,Form,Select,message} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import useAxios from './../../CustomHooks/useAxios';
import SelectMenuItem from './SelectMenuItem';
const { Option } = Select;
export default function AddAuthorizationGroupItem({setVisibleAdd,setRefreshSearch,authorization_groups,authorization_groupsLoading,menuSubData,menuSubLoading}){
    const [form] = Form.useForm();
    const {axiosi}=useAxios();
    const [menuItemData,setMenuItemData]=useState(null);
    const [menuItemDataLoading,setMenuItemDataLoading]=useState(false);



    const [menu_sub_id,setMenu_sub_id] = useState("");
    const [menu_item_id,setMenu_item_id] = useState("");


    const handleChangeSubMenu=async(value)=>{
      // form.setFieldsValue({menu_item_id:""});
        console.log("[handleChangeSubMenu]:menu_sub_id=",menu_sub_id)
        console.log("[handleChangeSubMenu]:value=",value)
        setMenu_sub_id(value);
        const kq=await axiosi.get('menu_item',{params:{menu_sub_id:value,item_name:"",pageSize:200}});
        setMenuItemData(kq.data);
        setMenuItemDataLoading(true);
        // form.setFieldsValue({menu_item_id:1});
        setMenu_item_id("")
        
    }
    const onFinishAdd=async (values)=>{
        console.log("[onFinishAdd]:values=",values);
        try {
            const kq=await axiosi.post("authorization_group_items",values);
            console.log("[onFinishAdd]:kq=",kq) ;
            
            setVisibleAdd(false);
            setRefreshSearch(pre=>!pre);

        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                message.error(`Error: ${error.response.data.code}`);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
        }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }
    const rdSelectMenuTitem=()=>{
      return (<>
            <Select style={{ width: '100%' }} 
                    onChange={(value)=>setMenu_item_id(value)}
                    // loading={menuItemDataLoading}
                    value={menu_item_id}
                    
            >
                        <Option value=""></Option>
                        { menuItemData?.rows && menuItemData.rows.map(item=>(
                           <Option key={item.id} value={item.id}>{item.item_name}</Option>
                       ))}                       
            </Select>
        
      </>)
    }

    const handleOnChangeMenuItem=(value)=>{
      setMenu_item_id(value);
      form.setFieldsValue({menu_item_id:value});
    }
    return(<>
        {/* <SelectMenuItem menu_sub_id={menu_sub_id}/> */}
        <Form form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{menu_item_id:"" }}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
        <Form.Item 
        name="authorization_group_id"
        label="Author_Group" 
        rules={[{ required: true, message: 'Please input a Author_Group!' }]}
      >
            <Select style={{ width: '100%' }} 
                    // onChange={(value)=>setAuthorization_group_id(value)}
                    loading={authorization_groupsLoading}
                    
            >
                        <Option value=""></Option>
                       { authorization_groups?.rows && authorization_groups.rows.map(item=>(
                           <Option key={item.id} value={item.id}>{item.name}</Option>
                       ))}                       
            </Select>
        </Form.Item>

        <Form.Item
        label="menu_sub_id"
        name="menu_sub_id"
        rules={[{ required: true, message: 'Please input a menu_sub_id!' }]}
      >
            <Select style={{ width: '100%' }} 
                    onChange={(value)=>handleChangeSubMenu(value)}
                    loading={menuSubLoading}
                    
            >
                        <Option value=""></Option>
                        { menuSubData?.rows && menuSubData.rows.map(item=>(
                           <Option key={item.id} value={item.id}>{item.title}</Option>
                       ))}                       
            </Select>
        </Form.Item>

        <Form.Item
        label="menu_item_id"
        name="menu_item_id"
        rules={[{ required: true, message: 'Please input a menu_item_id!' }]}
      >
        {menuItemData?.rows && <>
            <Select style={{ width: '100%' }} 
                    onChange={handleOnChangeMenuItem}
                    //(value)=>setMenu_item_id(value)}
                    // loading={menuItemDataLoading}
                    value={menu_item_id}
                    
            >
                        <Option value=""></Option>
                        { menuItemData?.rows && menuItemData.rows.map(item=>(
                           <Option key={item.id} value={item.id}>{item.item_name}</Option>
                       ))}                       
            </Select>
        
      </>}
          {/* { menuItemData?.rows? rdSelectMenuTitem() : (<Select style={{ width: '100%' }}  ><Option value=""></Option></Select>)}     */}
        </Form.Item>
        
      

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
    </>)
}