import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {Input,Button
    // ,message
} from 'antd';
import {PhoneFilled}  from '@ant-design/icons';
export default function QuickCall() {
    
    // const [callTo, setCallTo] = useState('203')
    const [callTo, setCallTo] = useState('')
    const user = useSelector((state) => state.loginReducer.user);
    const makeCall = useSelector(state => state.jsSIPReducer.makeCall);
    // const showMessage=(m)=>{
    //     message.info({
    //         content:m,
    //         className: 'messageRight',
    //         duration:1000,
    //     });
    // }
    return (
        <>
            
            
        <div style={{margin:"auto",marginRight:"0.3rem"}}><label>Quick Call : </label></div>
        <div><Input placeholder={"Enter a tel number to make a quick call !"} 
        style={{ width: 290 }}
        value={callTo} onChange={(e) => setCallTo(e.target.value.trim())} /></div>
        <div style={{display:"flex"}}>
            <Button title="by using Main telephone number" onClick={() => makeCall(callTo)}><PhoneFilled />Call</Button>
          {user?.agents && (user?.agents[0]['company_id']===7) &&  ( <Button title="by using Backup telephone number" onClick={() => makeCall(`b${callTo}`)}><PhoneFilled />Call2</Button>) }
            {/* <Button title="by using Backup telephone number" onClick={() => showMessage(`b${callTo}`)}><PhoneFilled />showMessage</Button> */}
        </div>
        
      
        </>
    )
}
