import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import loginSlice from "../../src/redux/loginSlice";

const checkLoginStatusInLocalStorage = () => {
  if (localStorage.getItem(process.env.REACT_APP_LSTOKEN)) {
    return true;
  } else {
    return false;
  }
};

export default function useAxios() {
  const user = useSelector((state) => state.loginReducer.user);
  const dispatch = useDispatch();

  const instance = axios.create({
    baseURL: process.env.REACT_APP_DOMAIN,
    withCredentials: true,
  });
  const doRefreshLogin = async (config) => {
    // console.log("begin doing doRefreshLogin  ");
    config.url = `${process.env.REACT_APP_DOMAIN}/users/refreshLogin`;
    let ketqua = true;
    try {
      const kq = await axios.post(config.url, {}, { withCredentials: true });
      //console.log("kq doRefreshLogin ::::", kq.data);
      if (!user.agents) {
        dispatch(loginSlice.actions.setUser({ user: kq.data }));
      }
      if (kq.data.error) {
        ketqua = false;
      }
    } catch {
      ketqua = false;
    }
    //console.log("doRefreshLogin ketqua=", ketqua);
    return ketqua;
  };

  instance.interceptors.response.use(
    async function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      //console.log("[interceptors]response:::", response);
      let needrelogin = false;
      if (response.data.type === "application/json") {
        //console.log("ERROR ");
        const kqtext = JSON.parse(await response.data.text());
        //console.log(
        //   "[instance.interceptors.response]:JSON.parse(kq.data.text())=",
        //   kqtext
        // );
        response.data = kqtext;
      }

      if (response.data.error) {
        if (response.data.error.name) {
          if (
            response.data.error.name === "No token" ||
            response.data.error.name === "TokenExpiredError"
          ) {
            const config = response.config;
            if (config.url === "users/refreshLogin") {
              localStorage.removeItem(process.env.REACT_APP_LSTOKEN);
              dispatch(
                loginSlice.actions.setLogin({
                  isLogined: checkLoginStatusInLocalStorage(),
                })
              );
              //console.log("refreshLogin,config=", config);
              return response;
            }
            //console.log("Here0...............................");
            const kq = await doRefreshLogin({ ...config });
            //console.log("kqdoRefreshLogin= ", kq);
            if (kq) {
              //console.log("Here1...............................");
              //console.log("After doRefreshLogin :config= ", config);
              config.withCredentials = true;
              return instance(config);
            } else {
              //console.log("Here2...............................");
              localStorage.removeItem(process.env.REACT_APP_LSTOKEN);
              dispatch(
                loginSlice.actions.setLogin({
                  isLogined: checkLoginStatusInLocalStorage(),
                })
              );
              //console.log("Here3...............................");
              // return Promise.reject(response);
              return response;
            }
          }
        }
      }
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      //console.log("error:::", error);
      // console.log("error:::response=", response);
      return Promise.reject(error);
    }
  );

  return { axiosi: instance };
}
