import React, {
    useCallback,
    useRef,
    useState,
    useEffect,
    useLayoutEffect,
  } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { Routes, Route, Link, useLocation, useMatch } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Typography, Button } from "antd";
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
function MyMenu(){
    const user = useSelector((state) => state.loginReducer.user);
    const location = useLocation();
    const [selectSubMenu, setSelectSubMenu] = useState(()=>{
        if (user.agents) {
            let curpathname = location.pathname;
            const curpathnameArr = curpathname.split("/");
            curpathname = "/" + curpathnameArr[1];
            const menuitem = user.menu.menu_items;
            const item = menuitem.find((i) => i.item_key === curpathname);
      
            if (item) {
              console.log("[handleGetSubMenu]:Tim` thay item", curpathname);
              const menusubs = user.menu.menu_subs;
              const sub = menusubs.find((s) => s.id === item.menu_sub_id);
              console.log("sub=", sub);
            //   setSelectSubMenu(sub.menukey);
              console.log("sub.menukey=", sub.menukey);
              
              return [sub.menukey];
            } else {
              console.log("[handleGetSubMenu]:Kg tim thay Item", curpathname);
              return ["callcenter", "crm", "acb"];
            }
          } else {
            console.log("[handleGetSubMenu]:kg co' agents");
            return ["callcenter", "crm", "acb"];
          }
    });
    if (!user.agents) return (<></>)
return (
    <Menu
              theme={"dark"}
              mode="inline"
              defaultSelectedKeys={[location.pathname]}
              defaultOpenKeys={selectSubMenu}
              style={{ minHeight: "100vh", borderRight: 0 }}
            >
              
              <>
                {user.menu?.menu_subs
                  ? user.menu?.menu_subs.map((subs) => {
                   

                      if (subs.menukey)
                        return (
                          <Menu.SubMenu
                            key={subs.menukey}
                            icon={<UserOutlined />}
                            title={subs.title}
                          >
                            {user.menu?.menu_items
                              ? user.menu.menu_items.map((item) => {
                                  if ((item.menu_sub_id === subs.id) && (item.item_name!=="Home"))
                                    return (
                                      <Menu.Item key={item.item_key}>
                                        <Link to={item.item_to}>
                                          {item.item_name}
                                        </Link>
                                      </Menu.Item>
                                    );
                                  else return false;
                                })
                              : ""}
                          </Menu.SubMenu>
                        );
                      else {
                        console.log("UUUUUUUUUUUUUUUUUUU");
                        return false;
                      }
                    })
                  : console.log("MySubMenu")}
              </>
            </Menu>
)
}
export default MyMenu;