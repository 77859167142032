import {useState,useEffect} from 'react';
import {Button,Drawer,Input,Space,Form,message} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import useAxios from './../../CustomHooks/useAxios';
export default function AddMenuSubItem({setRefreshSearch,setVisibleAdd}){
    const {axiosi}=useAxios();
    const onFinishAdd=async (values)=>{
        console.log("[onFinishAdd]:values=",values);
        try {
        const kq=await axiosi.post("menu_sub",values);
        console.log("[onFinishAdd]:kq=",kq) 
        setVisibleAdd(false);
        setRefreshSearch(pre=>!pre);
    } catch (error){
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("error.response.data:",error.response.data);
            console.log("error.response.status:",error.response.status);
            console.log("error.response.headers:",error.response.headers);
            message.error(`Error: ${error.response.data.code}`);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
    }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }
    return(
        <>
        <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{icon:"LaptopOutlined" }}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
        <Form.Item
        label="menukey"
        name="menukey"
        rules={[{ required: true, message: 'Please input a menukey!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="icon"
        name="icon"
        rules={[{ required: true, message: 'Please input a icon!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="title"
        name="title"
        rules={[{ required: true, message: 'Please input a title!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
        </Form>
        </>
    )
}