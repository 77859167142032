import React,{useState,useEffect} from 'react';
import {  useSelector} from "react-redux";
import {Button,Drawer,Input,Space,Form,Select,Spin,message} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import debounce from 'lodash/debounce';
import useAxios from './../../CustomHooks/useAxios';
const { Option } = Select;


function DebounceSelect({ fetchOptions, debounceTimeout = 350, ...props }) {
        const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    

    const debounceFetcher = React.useMemo(() => {
      const loadOptions = (value) => {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        fetchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
  
          setOptions(newOptions);
          setFetching(false);
        });
      };
  
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
      <Select
      allowClear
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    );
  } // Usage of DebounceSelect
  
  
  async function fetchUserList_O(username) {
    console.log('fetching user', username);
    return fetch('https://randomuser.me/api/?results=5')
      .then((response) => response.json())
      .then((body) =>
        body.results.map((user) => ({
          label: `${user.name.first} ${user.name.last}`,
          value: user.login.username,
        })),
      );
  }
  
export default function AddTels({setRefreshSearch,setVisibleAdd}) {
    const {axiosi}=useAxios();
    const [itspsData,setItspData]=useState(null)
    const [companyid, setCompanyid] = React.useState('GM');
    const user = useSelector((state) => state.loginReducer.user);
    const [hideItem,setHideItem]=useState('');

    useEffect(()=>{
      if (user?.agents) {
        if(user?.agents[0].company_id!==1) {          
          setHideItem('hideItem');          
        } else {          
          setHideItem('col');
        }      
      }
  },[user?.agents])

    useEffect(()=>{
        const getItsps=async()=>{
            const kq=await axiosi.get("itsps",{params:{pageSize:200}});
            console.log("[AddTels-UseEffect]:kq=",kq)
           if (kq?.data?.rows) setItspData(kq.data.rows);
        }
        getItsps();
    },[])
    async function fetchUserList(company_name) {
        
        console.log('fetching user', company_name);
        const body=await axiosi.get('companies',{params:{company_name,pageSize:100}} );
        if (body?.data?.rows) {
            return body?.data?.rows.map((user) => ({
                label: user.company_name,
                 value: user.id,
               }));
        }
        else {
            const a=[{label:"---no data---",value:""}]
            return a.map(i=> (i))
        }
        // return axiosi.get('companies',{params:{company_name,pageSize:100}} ) // fetch('https://randomuser.me/api/?results=5')
        //   .then((body) =>
        //     body?.data?.rows.map((user) => ({
        //      label: user.company_name,
        //       value: user.id,
        //     })),
        //   );
      }
      async function fetchUserList_o(company_name) {
        
        console.log('fetching user', company_name);
        return axiosi.get('companies',{params:{company_name,pageSize:100}} ) // fetch('https://randomuser.me/api/?results=5')
          .then((body) =>
            body?.data?.rows.map((user) => ({
             label: user.company_name,
              value: user.id,
            })),
          );
      }
    const onFinishAdd=async (values)=>{
        console.log("[onFinishAdd]:values=",values);
        console.log("[onFinishAdd]:companyid=",companyid);
        try {
            const kq=await axiosi.post("tels",{...values,companyid:companyid?.value});
            console.log("[onFinishAdd]:kq=",kq) ;
            setVisibleAdd(false);
            setRefreshSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                message.error(`Error: ${error.response.data.code}`);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
        }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }
  return (<>
        <Form
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      initialValues={{ivrtype:0 }}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
        <Form.Item className={hideItem}
        label="companyName"
        // name="company_name"
        rules={[{ required: (hideItem!=="hideItem"), message: 'Please input a company_name!' }]}
      >
        <DebounceSelect
    showSearch={true}
      value={companyid}
      placeholder="Select users"
      fetchOptions={fetchUserList}
      onChange={(newValue) => {
        setCompanyid(newValue);
      }}
      style={{
        width: '100%',
      }}
    />
      </Form.Item>

      {/* <Form.Item
        label="companyName"
        name="company_name1"
        rules={[{ required: true, message: 'Please input a company_name!' }]}
      >
        <Input  />
      </Form.Item> */}
      <Form.Item
        label="telin"
        name="telin"
        rules={[{ required: true, message: 'Please input a telin!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="telout"
        name="telout"
        rules={[{ required: true, message: 'Please input a telout!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="itspid"
        name="itspid"
        rules={[{ required: true, message: 'Please input a itspid!' }]}
      >
        <Select>
            <Option value=""></Option>
            {itspsData && itspsData.map(i=>(
                <Option key={i.id} value={i.id}>{i.name}</Option>
            ))}
        </Select>
      </Form.Item>
      
      <Form.Item
      label="ivrtype"
      name="ivrtype"
      rules={[{ required: true, message: 'Please input a ivrtype!' }]}
    >
      <Select>
          
          
              <Option key={0} value={0} >Company</Option>
              <Option key={1} value={1}>Telin</Option>
          
      </Select>
    </Form.Item>
      
    <Form.Item
      label="Call to ITSP"
      name="foritsp"
      rules={[{ required: false, message: 'Please input a foritsp!' }]}
    >
      <Select>       
              <Option key={0} value={""}>Not Set</Option>       
              <Option key={1} value={"Gmobile"}>Gmobile</Option>
              <Option key={2} value={"Mobifone"}>Mobifone</Option>
              <Option key={3} value={"Sfone"}>Sfone</Option>
              <Option key={4} value={"Vietnamobile"}>Vietnamobile</Option>
              <Option key={5} value={"Viettel"}>Viettel</Option>
              <Option key={6} value={"Vinafone"}>Vinafone</Option>
              <Option key={7} value={"codinh"}>Fixed phone</Option>
              <Option key={8} value={"others"}>Others</Option>          
      </Select>
    </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
  </>);
}
