import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Button, Input, Form, Select, InputNumber } from 'antd';

import useAxios from './../../../CustomHooks/useAxios';
import DebounceSelect from './../../DebounceSelect'
import ToPhoneList from './ToPhoneList';
const { Option } = Select;
const { TextArea } = Input;
export default function Add({ setRefressSearch, setVisibleAdd }) {
  const hideItem = useSelector((state) => state.loginReducer.hideItem);
  const user = useSelector((state) => state.loginReducer.user);
  const { axiosi } = useAxios();


  const [companyid, setCompanyid] = React.useState({ value: user.agents[0].company_id, label: user.agents[0].gmcomname });

  const [teloutrule, setTeloutrule] = useState("random");
  const [telsoutData, setTelsoutData] = useState(null);

  const [telout, setTelout] = useState("");

  const [telList, setTelList] = useState("random");

  const [showTelList, setShowTelList] = useState(true);
  const [toRandomPhone, setToRandomPhone] = useState([]);
  const [toMobileFone, setToMobileFone] = useState([]);
  const [toVinaPhone, setToVinaPhone] = useState([]);
  const [toViettel, setToViettel] = useState([]);
  const [toFixedPhone, setToFixedPhone] = useState([]);
  const [toOthers, setToOthers] = useState([]);
  function handleOnChangeTelRule(value) {
    setTeloutrule(value);
    setShowTelList(value === "random");
  }

  async function fetchUserList(company_name) {
    console.log('fetching user', company_name);
    const body = await axiosi.get('companies', { params: { company_name, pageSize: 100 } });
    if (body?.data?.rows) {
      return body?.data?.rows.map((user) => ({
        label: user.company_name,
        value: user.id,
        //  value:{id: user.id,company_code:user.company_code},
        company_code: user.company_code,
      }));
    }
    else {
      const a = [{ label: "---no data---", value: "" }]
      return a.map(i => (i))
    }

  }



  const onFinishAdd = async (values) => {


    let tel_list = { toRandomPhone, toMobileFone, toVinaPhone, toViettel, toFixedPhone, toOthers };
    let adddata = {
      ...values
      , companyid: companyid.value
      , rule: teloutrule
      , tel_list: JSON.stringify(tel_list)
    };

    console.log("[onFinishAdd]:adddata=", adddata);

    // return;
    try {
      const kq = await axiosi.post("tels_callout_rule", adddata);
      console.log("[onFinishAdd]:kq=", kq)
      setVisibleAdd(false);
      setRefressSearch(pre => !pre);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log("error.response.data:", error.response.data);
        console.log("error.response.status:", error.response.status);
        console.log("error.response.headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("error.request :", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error error.message', error.message);
      }
      console.log("error.config:", error.config);
    }
  }
  const onFinishFailedAdd = (error) => {
    console.log("[onFinishFailedAdd]:error=", error);
  }


  const handleOnChangeCompanyName = async (value) => {
    setCompanyid(value);

  }


  useEffect(() => {
    const getTelout = async () => {
      if (companyid?.value === '') return;
      const kq = await axiosi.get('tels', { params: { companyid: companyid?.value, pageSize: 100 } });
      console.log("[handleOnChangeCompanyName]:kq_get_tels=", kq);
      // console.log("[handleOnChangeCompanyName]:selectedvalue=",value);
      setTelsoutData(kq.data);
      setTelout("");
    }
    getTelout();
  }, [companyid?.value])


  return (<>


    {/* {console.log("[return]:ivrVisible=",ivrVisible)} */}
    <Form
      name="basic"
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      initialValues={{
        ordnum: 0,
        numcurrentcall: 5,
        allownumcall: 2,
        minutebetweencall: 300,
        allowdaycall: 1,
        manualsurvey: 0

      }}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >


      <Form.Item className="hideItem"
        label="ordnum :"
        name="ordnum"
        rules={[{ required: true, message: 'Please input an ordnum!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item className={hideItem}
        label="Company Name"
        // name="company_name"
        rules={[{ required: true, message: 'Please input a company_name!' }]}
      >
        <DebounceSelect
          showSearch={true}
          value={companyid}
          placeholder="Select users"
          fetchOptions={fetchUserList}
          onChange={handleOnChangeCompanyName}
          style={{ width: '100%', }}
        />
      </Form.Item>
      <Form.Item
        label="Name :"
        name="name"
        rules={[{ required: true, message: 'Please input a department name!' }]}
      >
        <Input />
      </Form.Item>


      <Form.Item className={hideItem}
        label="Callout Rule :"
        // name="dep"
        rules={[{ required: false, message: 'Please input an department!' }]}
      >
        <Select onChange={(value) => handleOnChangeTelRule(value)} value={teloutrule}>

          <Option value="random">Random</Option>
          <Option value="lowestcode" >Lowest cost</Option>
        </Select>
      </Form.Item>

      
      
      <ToPhoneList lableName="TelList" showTelList={!showTelList} telsoutData={telsoutData} setToPhone={setToRandomPhone} />
      <ToPhoneList lableName="to MobiFone" showTelList={showTelList} telsoutData={telsoutData} setToPhone={setToMobileFone} />
      <ToPhoneList lableName="to VinaPhone" showTelList={showTelList} telsoutData={telsoutData} setToPhone={setToVinaPhone} />
      <ToPhoneList lableName="to Viettel" showTelList={showTelList} telsoutData={telsoutData} setToPhone={setToViettel} />
      <ToPhoneList lableName="to FixedPhone" showTelList={showTelList} telsoutData={telsoutData} setToPhone={setToFixedPhone} />
      <ToPhoneList lableName="to OthersPhone" showTelList={showTelList} telsoutData={telsoutData} setToPhone={setToOthers} />



      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  </>);
}
