import {useState,useEffect} from 'react';
import {Button,Drawer,Input,Space,Form,Select,message} from 'antd';
import { DownloadOutlined,PlusCircleFilled,EditFilled ,DeleteFilled} from '@ant-design/icons';

import useAxios from './../../CustomHooks/useAxios';
const { Option } = Select;

export default function AddItsps({setVisibleAdd,setRefreshSearch}) {
    const {axiosi}=useAxios();
    const onFinishAdd=async (values)=>{
        console.log("[onFinishAdd]:values=",values);
        try {
            const kq=await axiosi.post("itsps",values);
            console.log("[onFinishAdd]:kq=",kq) ;
            setVisibleAdd(false);
            setRefreshSearch(pre=>!pre);
        } catch (error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                message.error(`Error: ${error.response.data.code}`);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
        }
    }
    const onFinishFailedAdd=(error)=>{
        console.log("[onFinishFailedAdd]:error=",error);
    }
  return (<>
        <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    //   initialValues={{icon:"LaptopOutlined" }}
      onFinish={onFinishAdd}
      onFinishFailed={onFinishFailedAdd}
      autoComplete="off"
    >
        <Form.Item
        label="ITSP Name"
        name="name"
        rules={[{ required: true, message: 'Please input a ITSP Name!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="peername"
        name="peername"
        rules={[{ required: true, message: 'Please input a peername!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="ip1"
        name="ip1"
        rules={[{ required: true, message: 'Please input a ip1!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="ip2"
        name="ip2"
        // rules={[{ required: true, message: 'Please input a ip2!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="supporttel"
        name="supporttel"
        // rules={[{ required: true, message: 'Please input a supporttel!' }]}
      >
        <Input  />
      </Form.Item>

      <Form.Item
        label="supportemail"
        name="supportemail"
        // rules={[{ required: true, message: 'Please input a supportemail!' }]}
      >
        <Input  />
      </Form.Item>
      

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
        </Form>
  </>);
}
