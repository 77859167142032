import {useState} from 'react'
import { Button,Card,message ,Row ,Col} from 'antd';
import {MenuOutlined, PhoneOutlined ,PauseOutlined,SwapOutlined,CaretRightOutlined,AudioMutedOutlined ,AudioOutlined} from '@ant-design/icons';
import TransferTo from './TransferTo';
export default function Numpad({mysession,handleHangup,callStatus}) {
  const [mute,setmute]=useState(false);
  const [hold,sethold]=useState(false);
  const [useUpdate,setUseUpdate]=useState(true);
  const [hideItem,setHideItem]=useState('hideItem');
  const handleMute=()=>{
    if(!mute){
      mysession.mute();
      setmute(pre=>!pre);
    } else {
      mysession.unmute();
      setmute(pre=>!pre);
    }
  }
  const handleHold=()=>{
    if(!hold){
      mysession.hold({useUpdate});
      console.log("sethold");
      sethold(pre=>!pre);
    } else {
      mysession.unhold({useUpdate});
      mysession.renegotiate({useUpdate});
      console.log("setunhold");
      sethold(pre=>!pre);
    }
  }
  function doSendDTMF(digit){
    console.log("[doSendDTMF]:digit=",digit);
    var options = {
        'transportType': 'RFC2833'
      };          
    mysession.sendDTMF(digit, options);        
}
  const handleShowHide=()=>{
    setHideItem(pre=>(pre===''?'hideItem':''));
  }
  return (
    <>
    
    <div className="numpad">
    <MenuOutlined className="showHideKeypad" onClick={handleShowHide} title="Show/hide Keypad" />
    {/* <TransferTo  /> */}
    <div className={`numpad_header ${hideItem}`}>Keypad </div>
    <Row className={hideItem}>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF(1)}} shape="circle" type="primary" >1</Button></Col>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF(2)}} shape="circle" type="primary" >2</Button></Col>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF(3)}} shape="circle" type="primary" >3</Button></Col>
     </Row >
     <Row className={hideItem}>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF(4)}} shape="circle" type="primary" >4</Button></Col>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF(5)}} shape="circle" type="primary" >5</Button></Col>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF(6)}} shape="circle" type="primary" >6</Button></Col>
     </Row >
     <Row className={hideItem}>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF(7)}} shape="circle" type="primary" >7</Button></Col>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF(8)}} shape="circle" type="primary" >8</Button></Col>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF(9)}} shape="circle" type="primary" >9</Button></Col>
     </Row>
     <Row className={hideItem}>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF('*')}} shape="circle" type="primary" >*</Button></Col>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF(0)}} shape="circle" type="primary" >0</Button></Col>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF('#')}} shape="circle" type="primary" >#</Button> </Col>
     </Row>
     <hr className={hideItem} />
     <Row className="rowhangup">
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={handleMute} shape="circle" type="primary" >{mute?<AudioMutedOutlined  />:<AudioOutlined/>}</Button></Col>
     
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{handleHangup()}} shape="circle" type="primary" danger ghost><PhoneOutlined/></Button></Col>
     {/* <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={()=>{doSendDTMF('#')}} shape="circle" type="primary" ><SwapOutlined /></Button> </Col> */}
     </Row>
     {/* <hr />
     <hr /> */}
     {/* <Row>
     <Col className="numpad_col_digit"><Button className="numpad_digit" onClick={handleHold} shape="circle" type="primary" >{hold?<AudioMutedOutlined  />:<AudioOutlined/>}</Button></Col>
     
     
     </Row> */}
     
     
     <div className="duration">{callStatus}</div>
     </div>
     
    
    </>
  )
}
